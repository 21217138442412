<template>
  <div id="app" :class="{'customHtml': validaPainel() }">
    <keep-alive>
      <router-view
        :key="$route.path"
        @requestIdleTimeoutDisable="disableIdleTimer"
        @requestIdleTimeoutEnable="setIdleTimer"
      />
    </keep-alive>
    <v-dialog />
  </div>
</template>

<script>
import Vue from "vue"
import util from "@/libs/util"

import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"
import VModal from "vue-js-modal"

import { mapState, mapGetters } from "vuex"

Vue.use(VModal, { dialog: true })
const GO_HOME_TIMEOUT = 60000 // 1 minuto de inatividade para voltar a tela de pesquisa

export default {
  name: "App",
  created(){
    this.updateGlobalCSSVars();
    document.addEventListener('click', this.resetTimeout, {passive: true});
    document.addEventListener('touchmove', this.resetTimeout, {passive: true});
    document.addEventListener('touchstart', this.resetTimeout, {passive: true});
    document.addEventListener('keypress', this.resetTimeout, {passive: true});
    document.addEventListener('keydown', this.resetTimeout, {passive: true});
    document.addEventListener('keyup', this.resetTimeout, {passive: true});
    window.oncontextmenu = function(event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
    document.title = "MyQuest";
  },
  data() {
    return {
      idleTimeoutID: null
    }
  },
  computed: {
    ...mapState({
      customizationData: ({config}) => config.customizationData
    }),
    ...mapGetters(["textColor"]),
  },
  methods: {
    updateGlobalCSSVars() {
      const root = document.documentElement
      const primaryColor =
        this?.customizationData?.primaryColor ?? "(240, 240, 240)"
      root.style.setProperty("--primary-color", `rgb${primaryColor}`)
      root.style.setProperty("--text-color", this.textColor)
    },
    disableIdleTimer() {
      clearTimeout(this.idleTimeoutID)
    },
    resetTimeout() {
      this.setIdleTimer()
    },
    noneInputIsFocused(){
      return document.activeElement?.tagName !== "INPUT";
    },
    setIdleTimer() {
      clearTimeout(this.idleTimeoutID);
    
      const excludedPaths = [
        "/customizedHtml",
        "/login",
        "/config",
        "/selfService",
        "/reservationManagement",
        "/menuSearch",
        "/"
      ];
    
      if (!excludedPaths.includes(this.$route.path)) {
        this.idleTimeoutID = setTimeout(() => {
          //if (process.env.NODE_ENV !== "production") {
            if (this.noneInputIsFocused()) {
              this.$router.push("/menuSearch");
              this.$modal.hideAll();
            } else {
              this.$router.go();
            }
          //}
          this.setIdleTimer();
        }, GO_HOME_TIMEOUT);
      }
    },
    
    validaPainel() {
      return util.getLocalVar("classApp") == true;
    }
  },
  watch: {
    customizationData(){
      this.updateGlobalCSSVars()
    }
  }
}
</script>

<style lang='less'>
body {
  margin: 0;
  overflow-y: hidden !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: calc(100vh - 60px);
  padding: 30px;
  overflow: hidden;
  &.customHtml {
    padding: 0;
    height: auto;
    text-align: inherit;
  }
  &.customHtml::before {
    content: ""; /* Conteúdo vazio para o pseudo-elemento */
    position: absolute; /* Posiciona o pseudo-elemento em relação ao elemento pai */
    top: 0;
    left: 0;
    width: 100%; /* Tamanho igual ao elemento pai */
    height: 100%; /* Tamanho igual ao elemento pai */
    background-image: url('./assets/img/icons/fundo-tecfood-teknisa.jpg');
    background-size: cover; /* Cobrir toda a área disponível */
    background-position: center; /* Centralizar a imagem */
    opacity: 0.15; /* Opacidade da imagem de fundo */
    z-index: -1;
  }
}

html{
  overflow: hidden;
}

.modal-details {
  padding: 30px;
  height: calc(100vh - 60px);
  .vm--modal {
    overflow-y: auto;
    top: 0!important;
    height: calc(100vh - 60px)!important;
    width: calc(100vw - 60px)!important;
    left: 0!important;
    .vm--modal {
      top: 0 !important;
      left: 0px !important;
      width: 100vw; // voltar com !important aqui caso quebrar alguma outra modal
      height: 100vh; // voltar com !important aqui caso quebrar alguma outra modal
    }
  }
}

.confirm-modal, .register-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  .vm--modal {
    width: 55vw !important;
    max-width: 100vw !important;
    height: auto !important;
    max-height: 100vh !important;
  }
}

.register-modal{
  .vm--modal {
    width: 80vw !important;
    max-width: 100vw !important;
    height: auto !important;
    max-height: 100vh !important;
  }
}

.register-success-message > .vm--modal {
  width: 100% !important;
  height:100% !important;
}

@media screen and (orientation: landscape) {
  .register-modal .vm--modal {
    width: 60vw !important;
  }
  .confirm-modal .vm--modal {
    width: 55vw !important;
  }

}

@media screen and (orientation: portrait) {
  .register-modal .vm--modal {
    width: 80vw !important;
  }
  .confirm-modal .vm--modal {
    width: 75vw !important;
  }

}

html {
  /* background-color: #e5e5e5; */
}
</style>
