import ReservationManagementProvider from "@/providers/ReservationManagementProvider"


export default {
  getReservations(params) {
    return ReservationManagementProvider.getReservations(params);
  },
  getReservationData(reservationParams) {
    return ReservationManagementProvider.getReservationData(reservationParams);
  },
  reservationCheckout(reservationData) {
    return ReservationManagementProvider.reservationCheckout(reservationData);
  }
}