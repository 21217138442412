<template>
  <div class="single-choice-question-root">
    <div class="title">
      <span>{{ questionTitle }}</span>
    </div>
    <div class="options-container">
      <div
        class="option"
        v-for="(option, i) in options"
        :key="i"
        @click="answerQuestion(option)"
      >
        <span class="answer-description">{{ option.DSRESPOSTA }}</span>
      </div>
    </div>
    <button @click="goBack" v-if="!hideBackButton">Voltar</button>
  </div>
</template>

<script>
export default {
  name: "single-choice-question",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    hideBackButton: {
      type: Boolean,
      default: false
    },
    questionTitle: {
      type: String
    }
  },
  methods: {
    goBack() {
      this.$emit("goBack")
    },
    answerQuestion(answer) {
      this.$emit("questionAnswered", {
        ...answer,
        TXRESPOSTAPESQ: answer.DSRESPOSTA
      })
    }
  }
}
</script>

<style lang="less" scoped>
.single-choice-question-root {
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  height: 100%;

  .title {
    background: white;
    position: sticky;
    top: 0;
    width: 100%;
    span {
      position: relative;
      display: block;
      font-weight: bold;
      font-size: 4.5rem;
      padding: 15px 5px;
    }
  }

  .options-container {
    overflow-y: scroll;
    padding: 10px;

    .option {
      background: rgba(211, 211, 211, 0.2);
      font-size: 1.1rem;
      height: 70px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      cursor: pointer;
      margin-top: 35px;
      box-shadow: 1px 1px 2px 0px grey;
      &:first-of-type{
        margin-top: 0;
      }
      .answer-description {
        font-size: 2.5rem;
        text-align: center;
        // font-weight: bold;
        flex-grow: 1;
      }

    }
  }
}

// .single-choice-question-root {
//   .option {
//     height: 70px;
//     display: flex;
//     align-items: center;
//     border-radius: 6px;
//     cursor: pointer;
//     margin-top: 10px;
//     box-shadow: 1px 1px 2px 0px grey;
//     .answer-description {
//       font-size: 1.5rem;
//       text-align: center;
//       flex-grow: 1;
//     }
//   }
// }
</style>
