import moment from "moment"

export default {
  state: () => ({
    selectedDate: moment().format("YYYY-MM-DD"),
    serviceData: {}
  }),
  mutations: {
    selectDate(state, newDate) {
      state.selectedDate = newDate
    },
    selectService(state, newServiceData){
      state.serviceData = newServiceData
    }
  },
  getters: {
    formattedSelectedDate({selectedDate}) {
      if (selectedDate) {
        return moment(selectedDate).format('DD/MM/YYYY')
      } else {
        return null
      }
    },
    canReservePlates({serviceData}){
      return serviceData?.RESERVA_PRATO === "S"
    },
    isServiceReservable({serviceData}){
      return serviceData?.IDRESERVASERVMYMENU === "S"
    }
  }
}
