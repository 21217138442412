<template>
  <div class="free-text-question-root">
    <div class="question-title">
      <span>{{ questionTitle }}</span>
    </div>
    <component v-if="layout !== 'TEXT' && layout !== undefined"
      :is="currentFreeText"
      :layout="layout"
      @changeValueAnswer = "onChangeValueAnswer"
    />
    <div class="required-question-text" v-if="!isAnswerValid && hasTriedToSend">
      <span> A resposta é obrigatória </span>
    </div>
    <div class="button-container">
      <button @click="goBack" v-if="!hideBackButton">Voltar</button>
      <button @click="answerQuestion">Enviar</button>
    </div>
    <textarea v-if="layout === 'TEXT' && layout !== undefined"
      :class="{ invalid: !isAnswerValid && hasTriedToSend }"
      v-model="answer"
    ></textarea>
  </div>
</template>

<script>
import IconsText from "@/components/survey/questions/IconsText"
import RangeText from "@/components/survey/questions/RangeText"

export default {
  name: "free-text",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    hideBackButton: {
      type: Boolean,
      default: false
    },
    questionTitle: {
      type: String
    },
    isAnswerRequired: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String
    }
  },
  components: {
    "icons-text": IconsText,
    "range-text": RangeText
  },
  computed: {
    isAnswerValid() {
      let answerLength 
      if (this.layout !== 'TEXT') {
        answerLength = this?.answer
      } else {
        answerLength = this?.answer?.length ?? 0
      }
      
      return answerLength > 0
    },
    currentFreeText() {
      let textType
      if (this.layout === "EMOJI" || this.layout === "STAR") {
        textType = "icons-text"
      } else {
        textType = "range-text"
      }
      
      return textType
    }
  },
  data() {
    return {
      answer: null,
      hasTriedToSend: false
    }
  },
  methods: {
    goBack() {
      this.$emit("goBack")
    },
    answerQuestion() {
      this.hasTriedToSend = true
      if (!this.isAnswerRequired || this.isAnswerValid) {
        this.$emit("questionAnswered", { TXRESPOSTAPESQ: this.answer })
        this.answer = null;
        this.hasTriedToSend = false;
      }
    },
    onChangeValueAnswer(value) {
      this.answer = value;
    }
  }
}
</script>

<style lang="less" scoped>
.free-text-question-root {
  display: flex;
  position: relative;
  flex-direction: column;
 // justify-content: center;
  height: 100%;
}

.question-title {
  background: white;
  position: sticky;
  top: 0;
  width: 100%;
  span {
    position: relative;
    display: block;
    font-weight: bold;
    font-size: 4rem;
    padding: 15px 0 15px 0;
  }
}

.required-question-text {
  font-size: 2rem;
  color: rgb(253, 69, 69);
}

textarea {
  max-height: 500px;
  font-size: 1.1rem;
  margin-bottom: 10px;
  padding: 7px 4px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  resize: none;
  font-family: inherit;
  height: 100%;
  white-space: normal;

  &.invalid{
    border: 1px solid red;
  }
}
.button-container {
  margin-bottom: 10px;
}

button {
  height: 70px;
  background: var(--primary-color);
  color: var(--text-color);
  border-radius: 5px;
  padding: 0 10px;
  border: none;
  font-size: 1.4rem;
  text-transform: uppercase;
  width: 300px;
  font-weight: bold;
}
</style>
