<template>
  <div class="service-datils-root">
    <myquest-header />
    <!-- <span class="page-title">Serviços</span> -->
    <div class="service-container-wrapper">
      <div class="service-details-view">
        <div class="service-details-info">
          <div class="image-container">
            <img
              :src="getServiceImageUrl(serviceData.IMGSERVICO)"
              @error="$event.target.src = placeHolderImage"
              :alt="serviceData.NMSERVICO"
            />
          </div>
          <div class="service-data">
            <span><b>{{ translateView('Nome') }}: </b> {{ serviceData.NMSERVICO }}</span>
            <span
              ><b>{{ translateView('Horário') }}: </b>
              {{ serviceData.HRINICIO || '00:00' + " - " + serviceData.HRFINAL || '23:59' }}</span
            >
          </div>
        </div>

        <div class="button-container">
          <!--<button
            @click="showServiceReservationForm"
            v-if="isReserveServiceButtonVisible"
          >
            <span>Reservar Serviço</span>
          </button>-->
          <button @click="goToPlatesView">
            <span>{{ translateView('Fazer Avaliação') }}</span>
          </button>
        </div>
        <rating-component :ratingSettings="ratingSettings" />
      </div>
    </div>
    <modal name="reservation-form" :adaptive="true" class="modal-details">
      <reservation-form
        @confirmed="reserveService"
        @canceled="closeServiceReservationForm"
        :minTime="serviceData.HRINICIO"
        :maxTime="serviceData.HRFINAL"
        :initialDate="filteredDate"
        :startTimePrep="serviceData.HRINICIOPREPARO"
      />
    </modal>
    <modal
      name="reservation-success-message"
      :height="'100%'"
      :width="'100%'"
      :transition="'none'"
    >
      <div style="font-size: 40px; padding-top: 25%;">
        {{ translateView('Reserva confirmada com sucesso') }}.
      </div>
    </modal>
  </div>
</template>

<script>
import util from "@/libs/util"

import ReservationService from "@/service/ReservationService"
import moment from "moment"

import ReservationForm from "@/components/shared/reservationForm"
import RatingComponent from "@/components/shared/ratingComponent"
import Header from "@/components/shared/Header"
import placeHolderImage from "@/assets/placeholder-image.svg"
import { mapState, mapGetters } from "vuex"

export default {
  name: "service-detail",
  activated() {
    this.origin = this.$route?.params?.origin ?? this.origin
  },
  data() {
    return {
      placeHolderImage,
      origin: null
    }
  },
  components: {
    "reservation-form": ReservationForm,
    "rating-component": RatingComponent,
    "myquest-header": Header
  },
  computed: {
    ...mapState({
      serviceData: ({ reservation }) => reservation.serviceData,
      filteredDate: ({ reservation }) => reservation.selectedDate,
      userNRORG: ({ userData }) => userData.NRORG,
      selectedBranchCode: ({ config }) => config.branchData.CDFILIAL,
      selectedSectionCode: ({ config }) =>
        config.sectionData?.NRLOCALPESQ ?? null
    }),
    ...mapGetters(["canReservePlates", "isServiceReservable"]),
    goToPlatesLabel() {
      return this.canReservePlates ? "Reservar Pratos" : "Ver Pratos"
    },
    isReserveServiceButtonVisible() {
      return this.canReservePlates ? false : this.isServiceReservable
    },
    ratingSettings() {
      let userData = JSON.parse(localStorage.getItem("userData"))

      return {
        filter: [
          { name: "CDFILIAL", value: this.selectedBranchCode, operator: "=" },
          { name: "NRORG", value: userData?.NRORG, operator: "=" },
          {
            name: "CDSERVFILI",
            value: this.serviceData.CDSERVFILI,
            operator: "="
          },
          {
            name: "DTCARDAPIO",
            value: moment(this.filteredDate).format("DD/MM/YYYY"),
            operator: "="
          }
        ],
        dataSourceUrl: "/crud/avaliacao_servico",
        dataSourceName: "avaliacao_servico",
        rating: {
          rateValueField: "VRAVALIACAO",
          commentDescField: "",
          rateId: "NRAVALIACAOSERVICO",
          userNameField: "NMPRATO"
        },
        onRate: this.onRate,
        onlyRead: true,
        readOnlyRate: this.serviceData.AVG_SERVICO
      }
    }
  },
  methods: {
    getServiceImageUrl(rawUrl) {
      if (!util.isEmpty(rawUrl)) {
        return `${util.getLocalVar('fileServiceFilesPath')?.url}/${rawUrl}`
      } else {
        return placeHolderImage
      }
    },
    goToPlatesView() {
      this.$router.push({ 
        name: 'plates',
        params: {origin: this.origin}
      })
    },
    showServiceReservationForm() {
      this.$modal.show("reservation-form")
    },
    closeServiceReservationForm() {
      this.$modal.hide("reservation-form")
    },

    showDialog(title) {
      this.$modal.show("dialog", {
        title,
        buttons: [
          {
            title: "Fechar",
            handler: () => {
              this.$modal.hide("dialog")
            }
          }
        ]
      })
    },
    showSuccessReservationMessage() {
      this.$modal.show("reservation-success-message")
      setTimeout(() => this.$modal.hide("reservation-success-message"), 3000)
    },
    async reserveService({
      reservationDate,
      reservationTime,
      name,
      department
    }) {
      const serviceData = {
        ...this.serviceData,
        NRORG: this.userNRORG,
        CDFILIAL: this.selectedBranchCode,
        NRLOCALPESQ: this.selectedSectionCode
      }
      const reservationData = {
        reservationDate,
        reservationTime,
        name,
        department
      }

      try {
        await ReservationService.reserveService(serviceData, reservationData)
        this.closeServiceReservationForm()
        this.showSuccessReservationMessage()
        setTimeout(() => this.$router.push("/menuSearch"), 3000)
      } catch (reservationPeriodError) {
        this.showDialog(reservationPeriodError)
      }
    },

    onRate(rateValue) {
      let userData = JSON.parse(localStorage.getItem("userData"))

      ReservationService.rateService({
        rateValue,
        DTCARDAPIO: moment(this.filteredDate).format("DD/MM/YYYY"),
        ...this.serviceData,
        NRORG: this.userNRORG,
        CDFILIAL: this.selectedBranchCode,
        NRLOCALPESQ: this.selectedSectionCode,
        ...userData
      })
    },
    translateView: function(word) {
      return util.translate(word);
    }
  }
}
</script>

<style lang="less" scoped>
.service-datils-root {
  width: 100%;
  height: 100%;

  .page-title {
    width: 80%;
    height: 80px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .service-container-wrapper {
    width: 80%;
    height: calc(100vh - 75px);
    margin: 5px auto;
    //border: 1px solid gray;
    border-radius: 10px;
    overflow-y: scroll;
    display: flex;

    .service-details-view {
      padding: 10px;
      width: 100%;

      .service-details-info {
        display: flex;
        height: 160px;

        .image-container {
          img {
            height: 160px;
            width: 160px;
            object-fit: cover;
            border-radius: 10px;
          }
        }

        .service-data {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 1.4rem;
          span {
            display: inline-flex;
            b {
              display: inline-block;
              padding-right: 5px;
            }
          }
        }
      }

      .button-container {
        display: flex;
        height: 100px;
        flex-flow: column;
        justify-content: space-around;
        align-items: center;
        margin: 20px 0;

        button {
          width: 30%;
          height: 70px;
          background: var(--primary-color);
          border: none;
          border-radius: 5px;
          color: white;
          font-size: 1.40rem;
          span {
            color: var(--text-color);
          }
        }
      }
    }
  }
}
</style>

<style>
.vue-dialog-content-title {
  font-size: 1.3rem;
}
</style>