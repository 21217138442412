<template>
  <div class="multiple-choice-question-root">
    <div class="question-title">
      <span>{{ questionTitle }}</span>
    </div>
    <div class="required-question-text" v-if="!hasSelectedOptions && hasTriedToSend">
      <span> Selecione pelo menos uma opção </span>
    </div>
    <div class="options-container">
      <div
        class="option"
        :class="{'invalid': !hasSelectedOptions && hasTriedToSend}"
        v-for="(option, i) in options"
        :key="i"
        @click="flipOptionState(option)"
      >
        <input
          class="checkbox"
          type="checkbox"
          :checked="selectedOptions[option.NRRESPOSTA]"
        />
        <span class="answer-description">{{ option.DSRESPOSTA }}</span>
      </div>
    </div>
    <button class="send-answer-button" @click="answerQuestion">Enviar Resposta</button>
  </div>
</template>

<script>
import Vue from "vue"

export default {
  name: "multiple-choice-question",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    questionTitle: {
      type: String
    },
    isAnswerRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasSelectedOptions(){
      return Object.keys(this.selectedOptions).length > 0
    }
  },
  data() {
    return {
      selectedOptions: {},
      hasTriedToSend: false
    }
  },
  methods: {
    flipOptionState(option) {
      const { NRRESPOSTA } = option
      const answerKey = parseInt(NRRESPOSTA)
      if (answerKey in this.selectedOptions) {
        Vue.delete(this.selectedOptions, answerKey)
      } else {
        Vue.set(this.selectedOptions, answerKey, option)
      }
    },
    answerQuestion() {
      this.hasTriedToSend = true
      if(!this.isAnswerRequired || this.hasSelectedOptions){
        this.$emit("questionAnswered", {
          selectedOptions: { ...this.selectedOptions }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.multiple-choice-question-root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;

  .question-title {
    background: white;
    position: sticky;
    top: 0;
    width: 100%;
    span {
      position: relative;
      display: block;
      font-weight: bold;
      font-size: 5rem;
      padding: 15px 0 15px 0;
    }
  }

  .required-question-text{
    font-size: 1.7rem;
    color: rgb(253, 69, 69);
  }

  .options-container {
    overflow-y: scroll;
    width: 100%;
    padding: 0 10px 10px 10px;

    .option {
      user-select: none;
      height: 70px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      margin-top: 35px;
      background: rgba(211, 211, 211, 0.2);
      box-shadow: 1px 1px 2px 0px grey;
      
      &.invalid{
        border: 1px solid red;
      }

      .checkbox {
        height: 50px;
        width: 50px;
        cursor: pointer;
      }

      .answer-description{
        font-size: 2.5rem;
      }
    }
  }

  .send-answer-button {
    margin-top: 10px;
    min-height: 45px;
    background: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    text-transform: uppercase;
    width: 300px;
    padding: 0 10px;
    font-weight: bold;
  }
}
</style>
