import requestModule from '@/libs/requestModule'

export default {

  getSurveyData({ NRORG, CDFILIAL, NRLOCALPESQ }) {
    return requestModule.postRequest('getSurvey', { NRORG, CDFILIAL, NRLOCALPESQ }).then((r) => {
      const surveyData = r?.dataset?.pesquisa ?? []

      const questionMap = surveyData.reduce((acc, current) => {
        const currentQuestion = acc[current.NRQUESTAO] ?? {
          NRPROGRAMACAOPESQ: current.NRPROGRAMACAOPESQ,
          NRQUESTIONARIO: current.NRQUESTIONARIO,
          IDTIPOQUESTAO: current.IDTIPOQUESTAO,
          IDOPCAORESPOSTA: current.IDOPCAORESPOSTA,
          TXQUESTAO: current.TXQUESTAO,
          IDQUESTAOOBRIGA: current.IDQUESTAOOBRIGA,
          NRORDEMQUESTAO: current.NRORDEMQUESTAO,
          LAYOUT: current.IDLAYOUTRESP
        }

        const currentAnswers = currentQuestion.answers ?? []
        
        currentQuestion.answers = [...currentAnswers, {
          DSRESPOSTA: current.DSRESPOSTA,
          NRQUESTAO: current.NRQUESTAO,
          NRPROXIMAQUESTAO: current.NRPROXIMAQUESTAO,
          NRRESPOSTA: current.NRRESPOSTA
        }]

        acc[current.NRQUESTAO] = currentQuestion
        return acc
      }, {})

      const questions = Object.entries(questionMap).map(
        ([NRQUESTAO, questionData]) => ({ NRQUESTAO, ...questionData })
      )
      return questions
    })
  },

  getNextQuestion({NRPROXIMAQUESTAO}) {
    return requestModule.postRequest('getQuestion', { NRPROXIMAQUESTAO }).then((r) => {
      const questionData = r?.dataset?.questao ?? [{}]
      
      const question = {
        NRQUESTAO: questionData[0].NRQUESTAO,
        IDTIPOQUESTAO: questionData[0].IDTIPOQUESTAO,
        IDOPCAORESPOSTA: questionData[0].IDOPCAORESPOSTA,
        TXQUESTAO: questionData[0].TXQUESTAO,
        IDQUESTAOOBRIGA: questionData[0].IDQUESTAOOBRIGA,
        LAYOUT: questionData[0].IDLAYOUTRESP
      }

      question.answers = questionData.map(({NRRESPOSTA, DSRESPOSTA, NRPROXIMAQUESTAO }) => ({
        NRRESPOSTA, 
        DSRESPOSTA, 
        NRPROXIMAQUESTAO
      }))

      return question
    })
  },

  insertSurvey({answers, filteredData, surveyData}) {
    return requestModule.postRequest('insertSurvey', { answers, filteredData, surveyData })//.then((r) => {})
  }
}