<template>
  <header class="header-root">
    <slot name="left-action">
      <div class="back-button" @click="goBack">
        <i class="fas fa-arrow-left"></i>
      </div>
    </slot>
    <div class="header-myquest-logo-container"
      @mousedown="start"
      @mouseleave="stop"
      @mouseup="stop"
      @touchstart="start"
      @touchend="stop"
      @touchcancel="stop"
    >
      <img
        v-if="fileserverCustomerLogoUrl"
        :src="fileserverCustomerLogoUrl"
        alt="Logo MyQuest"
        class="header-myquest-logo-image"
      />
    </div>
    <img
      class="right-header-element"
      style="display: none"
      v-if="MyQuestLogoUrl"
      :src="MyQuestLogoUrl"
    />
  </header>
</template>

<script>
import MyQuestLogoUrl from "@/assets/logo.svg"
import util from "@/libs/util"
import { stopHeartbeat } from  "@/libs/heartbeat"
import { mapState } from "vuex"

export default {
  name: "myquest-header",
  computed: {
    ...mapState({
      fileserverCustomerLogoUrl({config}) {
        const {logoUrl} = config.customizationData
        if (!util.isEmpty(logoUrl)) {
          util.setLocalVar('logoUrl', `${util.getLocalVar('fileServiceFilesPath')?.url}/${logoUrl}`)
          return `${util.getLocalVar('fileServiceFilesPath')?.url}/${logoUrl}`
        } else {
          return this.MyQuestLogoUrl
        }
      }
    })
  },
  data() {
    return {
      MyQuestLogoUrl,
      customerLogoUrl: null,
      intervalLogin: false,
      countInterval: 0
    }
  },
  props: {
    logoUrl: {
      type: String,
      default: ""
    }
  },
  methods: {
    goBack() {
      this.stop()
      this.$router.back()
    },
    start() {
      if (!this.intervalLogin) {
        this.intervalLogin = setInterval(() => {
          this.stop();
          this.$router.push("/login");
          this.$modal.hideAll();
        }, 5000)
      }
    },
    stop() {
      clearInterval(this.intervalLogin)
      stopHeartbeat();
      this.intervalLogin = false
    }
  }
}
</script>

<style lang="less">
.back-button {
  padding: 15px;
  cursor: pointer;
  color: var(--text-color);
  font-size: 2rem;
}

.header-root {
  //display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 90px;
  background-color: var(--primary-color);
  position: relative;

  display: grid;
  grid-template-columns: 10% 70% 20%;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.right-header-element {
  max-height: 90px;
  max-width: 80%;
  margin-right: 10px;
  margin: auto;
}

.header-myquest-logo-container {
  //position: absolute;
  //left: 50%;
  text-align: center;
  .header-myquest-logo-image {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    display: block;
    right: 0;
    bottom: 0;
    height: auto;
    cursor: pointer;
    max-width: 300px;
    max-height: 95px;
  }
  &:before{
    content: '';
    position: absolute;
    display: block;
    width: 340px;
    height: 95px;
    background: white;
    top: -1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: skew(25deg, 0deg);
  }
}
</style>
