import DeviceProvider from "@/providers/DeviceProvider"

let heartbeatInterval = null;

export function startHeartbeat(screenName, device) {
  if (heartbeatInterval) {
    clearInterval(heartbeatInterval);
  }
  
  sendHeartbeat(screenName, device);
  
  heartbeatInterval = setInterval(() => {
    sendHeartbeat(screenName, device);
  }, 180000);
}

export function stopHeartbeat() {
  if (heartbeatInterval) {
    clearInterval(heartbeatInterval);
    heartbeatInterval = null;
  }
}

function sendHeartbeat(screenName, device) {
   DeviceProvider.insertDevice({
     DSFUNCAO: screenName,
     NRDISPOSITIVOMYQ: device,
     DSRESOLUCAO: `${screen.width}x${screen.height}`,
   });
}
