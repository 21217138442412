<template>
  <div class="reservation-form">
    <myquest-header>
      <template #left-action>
        <div class="back-button" @click="$emit('canceled')">
          <i class="fas fa-arrow-left"></i>
        </div>
      </template>
    </myquest-header>
    <h2 class="instructions-text">
      {{ translateView('Excelente escolha, informe seus dados abaixo e confirme') }}.
    </h2>
    <form class="form-data" ref="reservationForm">
      <div class="field-info disabled">
        <label>{{ translateView('Data do Cardápio') }}</label>
        <span class="info">{{ formattedDate }}</span>
      </div>
      <div class="field" :class="[ disabledConsumptionDate ? ['field-info', 'disabled'] : 'required']">
          <label>{{ translateView('Data do Consumo') }}</label>
          <input
            required
            v-if="!disabledConsumptionDate"
            id="consumption-date-input"
            v-model="dateConsumption"
            :min="minDateConsumption"
            :max="maxDateConsumption"
            class="date-input"
            type="date"
          />
          <span 
            class="info"
            v-if="disabledConsumptionDate"
          >{{ formattedDateConsumption }}</span>
      </div>
      <div class="field required">
        <label for="time-input">{{ translateView('Hora do Consumo') }}</label>
        <input
          required
          id="time-input"
          v-model="time"
          type="time"
        />
      </div>
      <div class="field required" style="grid-column-start: span 2;" v-if="digitalReservation">
        <label for="cpf-input" :disabled="!digitalReservation">{{ translateView('CPF') }}</label>
        <input autocomplete="off" required id="cpf-input" v-model="cpf" style="width: 250px;"
          v-if="usePtBr" @input="checkUserIdNumber" />
        <input autocomplete="off" required id="cpf-input" v-model="cpf" style="width: 250px;"
          v-else @blur="checkUserIdNumber" />
      </div>
      <div class="field" style="grid-column-start: span 2;" v-if="digitalReservation && usePtBr">
        <span class="cpfText" v-if="!cpfValido && cpf !== ''" style="color: red;">{{ translateView('CPF inválido') }}</span>
      </div>
      <div class="field required">
        <label for="name-input">{{ translateView('Nome') }}</label>
        <input autocomplete="off" required id="name-input" v-model="name" />
      </div>
      <div class="field">
        <label for="department-input">{{ translateView('Setor') }}</label>
        <input autocomplete="off" id="department-input" v-model="department" />
      </div>
      <div class="field field-bigger" style="grid-column-start: span 2;">
        <label for="email-input"> {{ translateView('Informe seu email para receber detalhes da reserva (Opcional)') }}</label>
        <input autocomplete="off" id="email-input" v-model="email" />
      </div>
    </form>

    <div class="action-container">
      <button @click="$emit('canceled')"><span>{{ translateView('Cancelar') }}</span></button>
      <button @click="sendFormData"><span>{{ translateView('Reservar') }}</span></button>
    </div>
    
    <modal
      name="confirm-modal"
      :adaptive="true"
      class="confirm-modal"
      width="100%"
      height="auto"
    >
      <div class="confirm-modal-container">
        <p class="instructions-text">{{ translateView('CPF não encontrado. Deseja se cadastrar?') }}</p>
        <button class="default-button" @click="$modal.hide('confirm-modal')"><span>{{ translateView('Não') }}</span></button>
        <button class="default-button" @click="confirmRegistration"><span>{{ translateView('Sim') }}</span></button>
      </div>
    </modal>
  <modal 
    name="register-modal"
    :adaptive="true"
    class="register-modal"
    width="100%"
    height="auto"
    :clickToClose="false"
  >
    <h2 class="instructions-text">{{ translateView('Insira seus dados para realizar o cadastro') }}.</h2>
    <form class="form-data" style="margin: 0;" ref="registerForm">
      <div class="field field-bigger required" style="grid-column-start: span 2;">
        <label for="email">{{ translateView('Email') }}:</label>
        <input type="email" id="email" required v-model="email" @input="checkUserEmail"/>
      </div>

      <div class="field">
        <div class="half-width field required">
          <label for="name">{{ translateView('Nome') }}:</label>
          <input type="text" id="name" v-model="name" style="width: 250px;"/>
        </div>
        <div class="half-width field required">
          <label for="cpf" class="disabled">{{ translateView('CPF') }}:</label>
          <input type="text" id="cpf" class="disabled" required disabled v-model="cpf" style="width: 250px;" @input="validarCPF"/>
        </div>
        <div class="field" style="grid-column-start: span 2;" v-if="digitalReservation && usePtBr">
          <span class="cpfText" v-if="!cpfValido && cpf !== ''" style="color: red;">{{ translateView('CPF inválido') }}</span>
        </div>
      </div>

      <div class="field">
        <div class="half-width field required">
          <label for="lastname">{{ translateView('Sobrenome') }}:</label>
          <input type="text" id="lastname" required v-model="lastname" style="width: 250px;"/>
        </div>
        <div class="half-width">
          <label for="department">{{ translateView('Setor') }}:</label>
          <input type="text" id="department" v-model="department" style="width: 250px;"/>
        </div>
      </div>

      <div class="field" style="grid-column-start: span 2;">
        <div class="checkbox-container field required">
          <input type="checkbox" id="terms" required v-model="agreedToTerms" />
          <label for="terms">
            {{ translateView('Concordo com os') }}
            <button class="termsBtt" type="button" @click="openModal"><a target="_blank">{{ translateView('termos de serviço') }}</a></button>
          </label>
        </div>
      </div>
      <!-- Botões de Cancelar e Registrar -->
      <div class="buttons-container" style="grid-column-start: span 2;">
        <button class="default-button" @click="cancelRegistration">
          <span>{{ translateView('Cancelar') }}</span>
        </button>
        <button class="default-button" @click="registerNewUser" :disabled="!agreedToTerms">
          <span>{{ translateView('Registrar') }}</span>
        </button>
      </div>
    </form>
  </modal>
  <modal
    name="register-success-message"
    class="register-success-message"
    :height="'100%'"
    :width="'100%'"
    :transition="'none'"
  >
    <div style="font-size: 40px; padding-top: 25%;">
      {{ translateView('Usuário cadastrado com sucesso, prossiga com sua reserva') }}.
    </div>
  </modal>
  <modal
      name="terms-message"
      class="terms-message"
      :transition="'none'"
  >
    <button class="close-button" @click="closeModal">Fechar</button>
    <iframe class="privacyPdf" :src="privacyPolicyLink" width="100%" height="100%"></iframe>
  </modal>
  </div>
</template>

<script>
import Vue from "vue"
import moment from "moment"
import util from "@/libs/util"
import Header from "@/components/shared/Header"
import ReservationHelper from "@/helper/ReservationHelper"
import requestModule from '@/libs/requestModule'
import ReservationService from "@/service/ReservationService"
import VModal from "vue-js-modal"
// import { mapActions } from 'vuex';

Vue.use(VModal)

export default {
  name: "reservation-form",
  components: {
    "myquest-header": Header
  },
  props: {
    initialDate: {
      type: String,
      default: moment().format("YYYY-MM-DD")
    },
    initialTime: {
      type: String,
      default: moment().format("HH:mm")
    },
    minTime: {
      type: String,
      default: "00:00"
    },
    maxTime: {
      type: String,
      default: "23:59"
    },
    securityLimitDay: {
      type: String,
      default: ""
    },
    securityLimitHour: {
      type: String,
      default: ""
    },
    startTimePrep: {
      type: String,
      default: ""
    },
  },
  created() {
    const DATE_FORMAT = 'YYYY-MM-DD';
    
    this.time = this.initialTime;
    this.date = this.initialDate;
    this.dateConsumption = this.initialDate;
    this.disabledConsumptionDate = true;
    this.minDateConsumption = this.initialDate;
    this.maxDateConsumption = this.initialDate;
    const MENU_MORE_ONE_DAY = moment(this.initialDate, DATE_FORMAT).add(1, 'days').format(DATE_FORMAT);
    const {
        START_PREP,
        START_SERV,
        END_SERV
    } = ReservationHelper.getServiceInstances(this.startTimePrep, this.minTime, this.maxTime);
    const START_SERVICE = moment(`${this.initialDate} ${START_SERV.format('HH:mm:ss')}`);
    const END_SERVICE = moment(`${MENU_MORE_ONE_DAY} ${END_SERV.format('HH:mm:ss')}`);
    const SECONDS_LIMIT = ReservationHelper.getSecondsByLimitDateTime(this.securityLimitDay, this.securityLimitHour);
    const NOW = moment();
    const DATE_TO_COMPARE = SECONDS_LIMIT ? START_SERVICE.subtract(SECONDS_LIMIT, 'seconds') : END_SERVICE;

    if( START_SERV > END_SERV ) {
        if( NOW < DATE_TO_COMPARE ) {
            this.maxDateConsumption = END_SERVICE.format(DATE_FORMAT);
            this.disabledConsumptionDate = false;
        }
    } else if(START_PREP > START_SERV && this.minTime) {
        this.dateConsumption = MENU_MORE_ONE_DAY;
        this.minDateConsumption = MENU_MORE_ONE_DAY;
        this.maxDateConsumption = MENU_MORE_ONE_DAY;
        this.disabledConsumptionDate = true;
    }else if(!this.minTime) {
        if( SECONDS_LIMIT ) {
          this.dateConsumption = this.initialDate;
          this.disabledConsumptionDate = true;
          
        }
    }
    
    this.formattedDateConsumption = moment(this.dateConsumption, DATE_FORMAT).format('DD/MM/YYYY');

  },
  data() {
    return {
      name: null,
      department: null,
      email: null,
      cpf: '',
      cpfValido: false,
      date: null,
      time: null,
      lastname: '',
      NRUSUARIOAPP: null,
      nrorg: util.getLocalVar('userData'),
      userData: [],
      usePtBr: util.getLocalVar('lang') === 'pt-br' ? true : false,
      newUser: null,
      timeout: null,
      dateConsumption: null,
      minDateConsumption: null,
      maxDateConsumption: null,
      serviceInTwoDays: false,
      disabledConsumptionDate: false,
      agreedToTerms: false,
      digitalReservation: util.getLocalVar('digitalReservation')
    }
  },
  methods: {
    validarCPF() {
      if (util.getLocalVar('lang') !== 'pt-br') {
        this.cpfValido = true;
        return;
      }
      this.cpf = this.cpf.replace(/\D/g, '');
      const cpf = this.cpf.replace(/[^\d]/g, '');
      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        this.cpfValido = false;
        return;
      }
      let soma = 0;
      let resto;
      for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;
      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }
      if (resto !== parseInt(cpf.substring(9, 10))) {
        this.cpfValido = false;
        return;
      }
      soma = 0;
      for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;
      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }
      if (resto !== parseInt(cpf.substring(10, 11))) {
        this.cpfValido = false;
        return;
      }
      this.cpfValido = true;
    },
    buildMessageParameters(msg, params = {}){
        return Object.keys(params).reduce( (newMsg, key) => {
            return newMsg.replaceAll(`__${key}__`, params[key]);
        }, msg);
    },
    buildMessage(msgKey, msgParams){
        let mappedMessages = {
            SERVICE_NOT_AVAILABLE: "Serviço disponível apenas entre __START__ e __END__",
            EXPIRED_TIME_RESERVATION: "Serviço disponível para reserva até __END_RESERVATION__",
            RESERVATION_NOT_AVAILABLE: "Serviço disponível para reservar apartir de __START_RESERVATION__",
            RESERVATION_PAST: "Não é possível realizar uma reserva anterior a hora atual"
        };
        return this.buildMessageParameters(mappedMessages[msgKey], msgParams);
    },
    validateHour(){
      let { isValid, msg = '', msgParams } = ReservationHelper.isValidConsumptionDateTime(
          this.dateConsumption,
          this.time,
          this.date,
          {DAYS: this.securityLimitDay, HOURS: this.securityLimitHour},
          this.startTimePrep,
          {START: this.minTime, END: this.maxTime},
          null,
          false
      );
      if( !isValid ) {
          msg = this.buildMessage(msg, msgParams);
      }
      document.getElementById('time-input').setCustomValidity( msg );
    },
    checkUserIdNumber() {
      this.validarCPF();
      if (this.cpf !== '' && this.cpfValido === true) {
        ReservationService.checkUserIdNumber(this.cpf).then((req) => {
          if (req.dataset.userData.length === 0) {
            this.$modal.show('confirm-modal');
            this.name = '';
            this.lastname = '',
            this.department = '';
            this.email = '';
            this.NRUSUARIOAPP = '';
          } else {
            this.userData = req.dataset.userData[0];
            this.NRUSUARIOAPP = this.userData.NRUSUARIOAPP;
            this.name = this.userData.DSPRIMEIRONOME;
            this.department = this.userData.DSSETOR;
            this.email = this.userData.DSEMAILUSUARIOAPP;
          }
        });
      }
    },
    checkUserEmail() {
      const delay = 1000;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        ReservationService.checkUserEmail(this.email).then((req) => {
          if (req.dataset.userData.length === 0) {
            this.newUser = true;
            return;
          } else {
            this.userData = req.dataset.userData[0];
            this.newUser = false;
            this.NRUSUARIOAPP = this.userData.NRUSUARIOAPP;
            this.name = this.userData.DSPRIMEIRONOME;
            this.department = this.userData.DSSETOR;
            this.cpf = this.userData.CDREGISTROPESSOA ? this.userData.CDREGISTROPESSOA : this.cpf; 
            this.lastname = this.userData.DSULTIMONOME;
          }
        })
      }, delay);
    },
    sendFormData() {
      this.validateHour();
      const isFormValid = this.$refs.reservationForm.reportValidity();
      !this.digitalReservation ? this.cpfValido = true : this.cpfValido;
      if (isFormValid && this.cpfValido) {
        this.$emit("confirmed", {
          name: this.name,
          department: this.department,
          cpf: this.cpf,
          email: this.email,
          nrUsuarioApp: this.NRUSUARIOAPP,
          reservationDate: this.date,
          reservationTime: this.time
        })
      }
    },
    translateView: function(word) {
      return util.translate(word);
    },
    // ...mapActions(['checkUserIdNumber']),
    confirmRegistration() {
      this.$modal.hide('confirm-modal');
      this.$modal.show('register-modal');
    },
    cancelRegistration(event) {
      event.preventDefault();
      this.$modal.hide('register-modal');
    },
    registerNewUser(event) {
      event.preventDefault();
      const isFormValid = this.$refs.registerForm.reportValidity();
      if (isFormValid && this.cpfValido) {
        const sendObject = {
          PRIMEIRONOME: this.name,
          ULTIMONOME: this.lastname,
          CPF: this.cpf,
          DSSETOR: this.department,
          DSEMAIL: this.email,
          NRUSUARIOAPP: this.NRUSUARIOAPP,
          NRORG: this.nrorg.NRORG,
          newUser: this.newUser
        }
        requestModule.postRequest('registerNewUser', sendObject).then(() => {
          this.checkUserIdNumber();
          this.$modal.hide('register-modal');
          this.showSuccessRegisterMessage();
        });
      }
    },
    openModal() {
      this.$modal.show("terms-message")
    },
    closeModal() {
      this.$modal.hide("terms-message")
    },
    showSuccessRegisterMessage() {
      this.$modal.show("register-success-message")
      setTimeout(() => this.$modal.hide("register-success-message"), 3000)
    },
  },
  computed: {
    formattedDate() {
      return moment(this.date).format("DD/MM/YYYY")
    },
    privacyPolicyLink() {
      const userData = JSON.parse(localStorage.userData);
      return userData ? userData.privacyPolicyLink + "#toolbar=0&navpanes=0" : null;
    },
  }
}
</script>

<style scoped lang="less">
.instructions-text {
  // background-color: rgba(222, 217, 217, 0.33);
  max-width: 80%;
  margin: 0 auto;
  padding: 15px;
  border-radius: 5px;
  font-size: 2.2rem;
  color: black; 
}

.disabled {
  opacity: 0.7;
}

.form-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 20vw;
  font-size: 1.39rem;
  gap: 1rem;
  .field {
    label {
      display: block;
    }

    .checkbox-container {
      display: flex; /* Usando Flexbox */
      align-items: center; /* Alinha os itens verticalmente */
      justify-content:center;
    }
  
    input[type="checkbox"] {
      width: 3rem;
    }

    input {
      width: 200px;
      min-height: 30px;
      height: 40px;
      font-size: 2.1rem;
      border: 1px #333 solid;
      border-radius: 5px;
      text-align: center;
      font-family: Avenir, Helvetica, Arial, sans-serif !important;

      &.disabled {
        color:grey;
      }
    }

    input.date-input {
      font-size: 1.8rem;
    }

    &.required > label {
      position: relative;
    }
    
    &.required > label::after {
      content: "*";
      position: absolute;
      top: 0;
      margin-left: 5px;
      color: rgb(216, 59, 59);
      font-size: 1.7rem;
      font-weight: bold;
    }
  }
  
  .field-bigger {
    input {
      width: 430px;
    }
    #email {
      width:580px;
    }
  }

  .field-info {
    label {
      display: block;
    }

    &.disabled {
      color: grey;
    }
    &.disabled:nth-child(1) {
      grid-column-start: span 2;
    }

    .info {
      display: block;
      padding: 7px 2px;
      border: 1px solid;
      width: 200px;
      margin: auto;
      border-radius: 5px;
      user-select: none;
      text-align: center;
    }
  }
}

.default-button {
  height: 70px;
  background: var(--primary-color);
  border-radius: 5px;
  color: white;
  padding: 0 10px;
  width: 200px;
  border: none;
  margin: 10px;
  font-size: 1.55rem;
  span {
    color: var(--text-color);
  }
}

.confirm-modal-container {
  margin-bottom:1rem;
}


.action-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 20px;

  button {
    height: 70px;
    background: var(--primary-color);
    border-radius: 5px;
    color: white;
    padding: 0 10px;
    width: 200px;
    border: none;
    margin: 10px;
    font-size: 1.55rem;
    span {
      color: var(--text-color);
    }
  }
}

.cpfText {
    font-size: 2rem;
}
.termsBtt{
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
}
.termsBtt:focus{
  outline: none;
}
.close-button {
  width: 100%;
  font-size: 24px;
  padding: 20px;
  cursor: pointer;
  background-color: #f44336;
  color: white;
  border: none;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

</style>
