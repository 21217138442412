import requestModule from "@/libs/requestModule"

export default {
  /**
   * Do the login request passing all login
   * data as parameter and returning dataset that
   * contains loginData.
   *
   * @param {Object} loginData
   *
   * @returns {Promise}
   */
  login(loginData) {
    return requestModule.postRequest("login", loginData).then((response) => {
      if (response.dataset) {
        return {
          token: response.dataset.token[0],
          userData: response.dataset.userData
        }
      }
    })
  },

  testRequest() {
    return requestModule.postRequest("testConnection")
  }
}
