<template>
  <div class="home-root">
    <myquest-header>
      <template #left-action>
        <div class="back-button" style="display: none;">
          <i class="fas fa-cog"></i>
        </div>
      </template>
    </myquest-header>
    <div class="content-container">
      <h2>{{ translateView('GESTÃO DE RESERVAS') }}</h2>
      <div class="button-group">
        <button type="button" class="searchButtons" @click="openModalReservation">
          {{ translateView('Localizar Reserva(s)') }}<svg-icon class="icon" type="mdi" :path="magnifyIcon"></svg-icon>
        </button>
      </div>
    </div>
    <div class="reservation-grid-container">
      <table v-if="sortedGridItems.length" class="reservation-table">
        <thead>
          <tr>
            <th @click="sortBy('DTCARDAPIO')">{{ translateView('Data') }}</th>
            <th @click="sortBy('HRCONSUMO')">{{ translateView('Horário') }}</th>
            <th @click="sortBy('NMUSUARIO')">{{ translateView('Nome') }}</th>
            <th @click="sortBy('NMSERVICO')">{{ translateView('Serviço') }}</th>
            <th @click="sortBy('TIPORESERVA')">{{ translateView('Tipo') }}</th>
            <th @click="sortBy('IDSTATUSRESERVA')">{{ translateView('Status') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sortedGridItems" :key="item.NRRESERVA" @click="rowClick(item)" :style="{ backgroundColor: '#f0f0f0' }">
            <td :style="isDifferentDate(item.DTCARDAPIO) ? { color: 'red' } :  { color: 'black'}">{{ item.DTCARDAPIO.substring(0, 10) }}</td>
            <td :style="{ color: 'black'}">{{ formatTime(item.HRCONSUMO) }}</td>
            <td :style="{ color: 'black'}">{{ item.NMUSUARIO }}</td>
            <td :style="{ color: 'black'}">{{ item.NMSERVICO }}</td>
            <td :style="{ color: 'black'}">{{ item.TIPORESERVA }}</td>
            <td :style="{ color: 'black'}">{{ item.IDSTATUSRESERVA }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ translateView('Nenhum dado encontrado') }}.</p>
    </div>
    <modal
      name="reservation-form"
      class="reservation-form"
      :adaptive="true"
      width="90%"
      height="auto"
    >
      <h2 class="modal-title">{{ translateView('Localizar Reserva(s)') }}</h2>
      <div class="modal-content-container">
        <form class="getReservationsForm" ref="getReservationsForm">
          <div class="input-row">
            <div class="input-group">
              <label for="status">{{ translateView('Status') }}</label>
              <select id="status" class="field-select" v-model="formData.status">
                <option value="TODOS">{{ translateView('Todos') }}</option>
                <option value="RETIRADO">{{ translateView('Retirado') }}</option>
                <option value="AGENDADO">{{ translateView('Agendados') }}</option>
                <option value="LIBERADO">{{ translateView('Liberados') }}</option>
              </select>
            </div>
            <div class="input-group">
              <label for="cpf">{{ translateView('CPF') }}</label>
              <input type="number" id="cpf" name="cpf" v-model="formData.cpf" autocomplete="off" @input="validarCPF">
              <div class="cpfTextContainer" v-if="usePtBr">
                <span class="cpfText" v-if="!cpfValido && formData.cpf !== ''" style="color: red;">{{ translateView('CPF inválido') }}</span>
              </div>
            </div>
            <div class="input-group">
              <label for="dt_cardapio">{{ translateView('Data do Cardápio') }}</label>
              <input id="dt_cardapio" class="date-input" type="date" v-model="formData.dt_cardapio"/>
            </div>
          </div>
        </form>
        <div class="button-group">
          <button type="button" class="searchButtons" @click="closeModal('reservation-form')">{{ translateView('Fechar') }}</button>
          <button type="button" class="searchButtons confirmed" @click="getReservations">{{ translateView('Localizar') }}</button>
        </div>
      </div>
    </modal>
    <modal
      name="reservation-details"
      class="reservation-details"
      :adaptive="true"
      width="90%"
      height="auto"
    >
      <h2 class="modal-title">{{ translateView('Detalhes da Reserva') }}</h2>
      <div class="modal-content-container">
        <div class="field">
          <div class="label-container">
            <span><b>{{ translateView('Nome') }}:</b> {{ selectedService.NMUSUARIO }}</span>
          </div>
          <div class="label-container">
            <span><b>{{ translateView('Setor') }}:</b> {{ selectedService.DSCONSUMO }}</span>
          </div>
        </div>
        <div class="field">
          <div class="label-container">
            <span><b>{{ translateView('Data de consumo') }}:</b> {{ formatDate(selectedService.DTCARDAPIO) }}</span>
          </div>
          <div class="label-container">
            <label><b>{{ translateView('Status') }}:</b> {{ selectedService.IDSTATUSRESERVA }}</label>
          </div>
        </div>
        <div class="field" v-if="selectedService.TIPORESERVA == 'SERVICOS'">
          <div class="label-container">
            <span><b>{{ translateView('Serviço Reservado') }}:</b> {{ selectedService.NMSERVICO }} </span>
          </div>
          <div class="label-container">
            <span><b>{{ translateView('Tipo de Reserva') }}:</b> {{ selectedService.TIPORESERVA }}</span>
          </div>
        </div>
        <div class="field" v-else>
          <div class="label-container">
            <label><b>{{ translateView('Pratos Reservados') }}:</b></label>
            <div v-for="(plate, index) in plates" :key="index + '-' + plate">
              <span class="form-control no-border">{{ plate }}</span>
            </div>
          </div>
          <div class="label-container">
            <span><b>{{ translateView('Tipo de Reserva') }}:</b> {{ selectedService.TIPORESERVA }}</span>
          </div>
        </div>
        <div class="button-group">
          <button type="button" class="searchButtons" @click="closeModal('reservation-details')">{{ translateView('Fechar') }}</button>
          <button type="button" class="searchButtons confirmed" @click="reservationCheckout('cancelar')">{{ translateView('Cancelar Reserva') }}</button>
          <button type="button" class="searchButtons confirmed" @click="reservationCheckout('retirar')">{{ translateView('Retirar Reserva') }}</button>
        </div>
      </div>
    </modal>
    <modal
      name="reservation-checkout-success-message"
      class="reservation-checkout-success-message"
      :height="'100%'"
      :width="'100%'"
      :transition="'none'"
    >
      <div style="font-size: 40px; padding-top: 25%;">
        {{ translateView('Reserva retirada com sucesso') }}!
      </div>
    </modal>
    <modal
      name="reservation-checkout-cancell-message"
      class="reservation-checkout-cancell-message"
      :height="'100%'"
      :width="'100%'"
      :transition="'none'"
    >
      <div style="font-size: 40px; padding-top: 25%;">
        {{ translateView('Reserva cancelada com sucesso') }}!
      </div>
    </modal>
  </div>
</template>

<script>
import MyQuestBackground from "@/assets/lgbackground.jpg"
import moment from "moment"
import Header from "@/components/shared/Header"
import ReservationManagementService from "@/service/ReservationManagementService"
import util from "@/libs/util"
import { mapState } from "vuex"
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMagnifyScan } from '@mdi/js';


export default {
  name: 'ReservationManagementService',
  components: {
    "myquest-header": Header,
    SvgIcon,
  },
  data() {
    return {
      MyQuestBackground,
      magnifyIcon: mdiMagnifyScan,
      cpfValido: false,
      usePtBr: util.getLocalVar('lang') === 'pt-br' ? true : false,
      gridItems: [],
      sortedGridItems: [],
      plates: null,
      error: '',
      selectedService: {tipoReserva: null, DTCARDAPIO: null },
      formData: { cpf: '', dt_cardapio: this.getCurrentDate(), status:"TODOS"},
      sortKey: '',
      sortAsc: true,
    }
  },
  props: {
    currentDate: {
      type: String,
      default: moment().format("DD/MM/YYYY")
    }
  },
  computed: {
    ...mapState({
      userData: state => state.userData,
      selectedCDFILIAL: ({ config }) => config.branchData.CDFILIAL,
      selectedSection: ({ config }) => config.sectionData,      
    }),
  },
  methods: {
    validarCPF() {
      if (util.getLocalVar('lang') !== 'pt-br') {
        this.cpfValido = true;
        return;
      }
      this.formData.cpf = this.formData.cpf.replace(/\D/g, '');
      const cpf = this.formData.cpf.replace(/[^\d]/g, '');
      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        this.cpfValido = false;
        return;
      }
      let soma = 0;
      let resto;
      for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;
      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }
      if (resto !== parseInt(cpf.substring(9, 10))) {
        this.cpfValido = false;
        return;
      }
      soma = 0;
      for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;
      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }
      if (resto !== parseInt(cpf.substring(10, 11))) {
        this.cpfValido = false;
        return;
      }
      this.cpfValido = true;
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
      this.sortGridItems();
    },
    sortGridItems() {
      if (!this.sortKey) {
        this.sortedGridItems = this.gridItems;
        return;
      }
      this.sortedGridItems = this.gridItems.slice().sort((a, b) => {
        let result = 0;
        if (a[this.sortKey] < b[this.sortKey]) {
          result = -1;
        } else if (a[this.sortKey] > b[this.sortKey]) {
          result = 1;
        }
        return this.sortAsc ? result : -result;
      });
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getReservations() {
      if ((this.cpfValido == false && this.formData.cpf == '')||this.cpfValido == true){
        const rawDate = this.formData.dt_cardapio;
        const [year, month, day] = rawDate.split('-');
        const formattedDate = `${day}/${month}/${year}`;
        const sendObject = {
          nrorg: this.userData.NRORG,
          cpf: this.formData.cpf,
          dt_cardapio: formattedDate,
          status: this.formData.status,
          cdfilial: this.selectedCDFILIAL,
          nrlocalpesq: this.selectedSection.NRLOCALPESQ
        };
        
        ReservationManagementService.getReservations(sendObject).then((req) => {
          this.$modal.hide('reservation-form');
          this.gridItems = req.dataset.userReservations;
          this.sortGridItems();
        });
      }
    },
    formatTime(time) {
      if (time !== null && time.length === 4) {
        const hours = time.substring(0, 2);
        const minutes = time.substring(2, 4);
        return `${hours}:${minutes}`;
      }
      return time;
    },
    formatDate(dateTime) {
      if (!dateTime) return '';
      return dateTime.split(' ')[0];
    },
    rowClick(item) {
      this.$modal.show('reservation-details');
      const sendObject = {
        TIPORESERVA: item.TIPORESERVA,
        DTCARDAPIO: item.DTCARDAPIO,
        CDSERVFILI: item.CDSERVFILI,
        CDFILIAL: item.CDFILIAL,
        HRCONSUMO: item.HRCONSUMO,
        CDREGISTROPESSOA: item.CDREGISTROPESSOA,
        NMSERVICO: item.NMSERVICO,
        NRRESERVA: item.NRRESERVA,
      }
      ReservationManagementService.getReservationData(sendObject).then((req) => {
        const data = req.dataset.reservationData;
        if (data[0].TIPORESERVA === 'PRATOS') {
          this.selectedService = {...item, ...data[0]};
          this.selectedService.NRAVALIACAOPRATO = data.map((item) => item.NRAVALIACAOPRATO);
          this.plates = data.map((item) => {
            return item.NMPRATO;
          });
        } else {
          this.selectedService = {...item, ...data[0]};
          this.selectedService.NRAVALIACAOPRATO = [''];
        }
      });
    },
    isDifferentDate(date) {
      return date.substring(0, 10) !== this.currentDate;
    },
    reservationCheckout(action) {
      const dsaction = action ==='retirar'? 'RETIRADO' : 'AGENDADO';
      const sendObject = {
        ...this.selectedService,
        STATUS: dsaction
      };
      ReservationManagementService.reservationCheckout(sendObject).then(() => {
        this.$modal.hide('reservation-details');
        if(dsaction == 'RETIRADO'){
          this.$modal.show('reservation-checkout-success-message');
          setTimeout(() => this.$modal.hide('reservation-checkout-success-message'), 3000)
        }else{
          this.$modal.show('reservation-checkout-cancell-message');
          setTimeout(() => this.$modal.hide('reservation-checkout-cancell-message'), 3000)
        }
        this.clearData();
        this.getReservations();
      });
    },
    openModalReservation() {
      this.$modal.show('reservation-form');
    },
    closeModal(modal) {
      this.$modal.hide(modal);
    },
    translateView: function(word) {
      return util.translate(word);
    },
    clearData() {
      this.gridItems = [];
    }    
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.clearData();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.clearData();
    next();
  } 
};
</script>

<style lang="less" scoped>

.home-root {
  height: 100%;
  position: relative;
  overflow-y: auto;
    &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/icons/fundo-tecfood-teknisa.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
  }

  .content-container, .reservation-form {
    overflow-y: hidden;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    width: 100%;
    padding-bottom: 1rem;
    h2 {
      max-width: 80%;
      margin: 0 auto;
      padding: 10px;
      font-size: 2.2rem;
      color: var(--text-color);
    }
    .input-row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .input-group {
      margin-bottom: 20px;
    }
    .input-group label {
      display: block;
      font-size: 1.7rem;
      margin-bottom: 5px;
    }
    .input-group input, .field-select{
      width: 40vw;
      min-height: 30px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      border: 1px solid #333;
      font-size: 2.1rem;
      margin: 5px;
    }
    .button-group {
      display: flex; 
      justify-content: space-between;
    }
    
    .searchButtons {
      flex: 1;
      padding: .5rem;
      border: .2rem solid var(--primary-color);
      border-radius: 5px;
      background-color: var(--primary-color);
      color: var(--text-color);
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
      font-size: 1.8rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content:center;
      .icon {
        width: 1.8rem;
        height: 1.8rem; 
        margin-left: 5px;
      }
    }
    .cpfTextContainer {
      margin-top: 10px;
      .cpfText {
        font-size: 2rem;
        color: red;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #ccc;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    color: var(--text-color);
    border-radius: 5px;
    overflow: hidden; 
  }
  th, td {
    border: none;
    padding: 1rem .1rem;
    text-align: center;
    color: var(--text-color);
  }
  th {
    padding: .7rem .1rem;
    background-color: var(--primary-color);
    border-bottom: 1px solid var(--text-color);
  }
  tbody tr {
    border-bottom: 1px solid var(--text-color);
  }
  tbody tr:last-child {
    border-bottom: none; 
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  @media (orientation: landscape) {
    .searchButtons {
      margin: 0 5rem;
    }
    th {
      font-size: 1.4rem;
    }
    td {
      font-size: 1.3rem;
    }
  }
  
  @media (orientation: portrait) {
    .searchButtons {
      margin: 0 1rem;
    }
    th {
      font-size: 1.3rem;
    }
    td {
      font-size: 1rem;
    }
  }
  .no-border {
    border: none;
    outline: none;
    padding: 5px;
  }
  .modal-content, .form-control {
    font-size: 1.3rem;
  }
  .modal-title {
    color: var(--text-color);
    text-align: center;
    font-size: 1.8rem;
  }
  .modal-body {
    display: flex;
    justify-content: flex-start;
  }
  .reservation-details, .reservation-form {
    .modal-content-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    
    .field {
      display: flex;
      gap: 10px;
      margin-left: 10px;
      font-size: 1.3rem;
    }
    
    .label-container {
      flex: 1; 
      text-align: left; 
      word-wrap: break-word; 
    }
    
    .field span,
    .field label {
      display: block; 
    }
    .button-group {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 20px;
    }
    .searchButtons {
      flex: 1;
      padding: .5rem;
      border: .2rem solid var(--primary-color);
      border-radius: 5px;
      background-color: var(--primary-color);
      color: var(--text-color);
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
      font-size: 1.8rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content:center;
      margin-bottom: 1rem;
    }
  }
}
</style>