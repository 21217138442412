<template>
  <div
    class="login-root"
    :style="
      `background: url('${MyQuestBackground}') center center no-repeat; background-size: cover;`
    "
  >
    <span :style="`display: none;`">APPLICATION_VERSION</span>
    <div class="form-wrapper">
      <img
        :src="MyQuestLogoUrl"
        alt="Logo MyQuest"
        class="myquest-logo-image"
      />
      <div class="invalid-password-email" v-show="invalidLogin">
        {{ translateView('Email ou senha inválidos') }}
      </div>
      <form ref="loginForm" @submit="onSubmit">
        <div class="field">
          <input
            :placeholder="translateView('E-Mail')"
            id="login-email-field"
            v-model="userKey"
            required
          />
        </div>
        <div class="field">
          <!-- autocomplete="new-password" used for preventing password autocomplete -->
          <input
            type="password"
            autocomplete="new-password"
            :placeholder="translateView('Senha')"
            id="login-password-field"
            v-model="password"
            required
          />
        </div>
        <div class="field">
          <button type="submit">{{ translateView('Continuar') }}</button>
        </div>
        <div class="container-flag">
          <img :src="flagBr" name="pt-br" class="flag-selected" @click="selectFlag($event)"/>
          <img :src="flagEs" name="es-es" class="flag" @click="selectFlag($event)"/>
          <img :src="flagUSA" name="en-us" class="flag" @click="selectFlag($event)"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import MyQuestLogoUrl from "@/assets/logo.svg"
import MyQuestBackground from "@/assets/lgbackground.jpg"
import LoginService from "@/service/LoginService"
import util from "@/libs/util"
import { mapMutations } from "vuex"

export default {
  name: "LoginScreen",
  data() {
    return {
      userKey: null,
      password: null,
      invalidLogin: false,
      MyQuestLogoUrl,
      MyQuestBackground,
      flagBr: require('../assets/img/icons/brazil.jpg'),
      flagEs: require('../assets/img/icons/spain.jpg'),
      flagUSA: require('../assets/img/icons/usa.png')
    }
  },
  methods: {
    ...mapMutations(["setUserData"]),
    validateForm() {
      return this.$refs.loginForm.reportValidity()
    },
    onSubmit(event){
      event.preventDefault()
      this.login()
    },
    async login() {
      const isFormValid = this.validateForm()
      if (isFormValid) {
        try {
          const userData = await LoginService.doLogin(this.userKey, this.password)
          this.setUserData(userData)
          this.$router.replace("/config")
        } catch (_) {
          this.invalidLogin = true
        }
      }
    },
    clearPassword() {
      this.password = null
    },
    removeSelectedFlag() {
      document.getElementsByClassName('flag-selected')[0].classList.add("flag")
      document.getElementsByClassName('flag-selected')[0].classList.remove("flag-selected")
    },
    selectFlag(e) {
      this.removeSelectedFlag()
      e.target.classList.add("flag-selected")
      e.target.classList.remove("flag")
      util.setLocalVar("lang", e.target.name)
      this.$forceUpdate()
    },
    translateView: function(word) {
      return util.translate(word);
    }
  },
  activated() {
    this.clearPassword()
  },
  mounted() {
    util.setLocalVar("lang", "pt-br")
    this.$forceUpdate()
  }
}
</script>

<style lang="less" scoped>
@primColor: #2d68c4;
.login-root {
  // width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .invalid-password-email {
    color: red;
  }

  .form-wrapper {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.9);
    //border: 1px solid black;
    border-radius: 10px;
    padding: 30px;
    width: 50vw;
    max-width: 400px;

    .field {
      margin: 20px;
      width: calc(100% - 40px);

      input {
        width: calc(100% - 40px);
        height: 30px;
        margin: 5px;
        background: transparent;
        border: none;
        border-bottom: solid 1px;
        &::-webkit-input-placeholder {
          /* Edge */
          color: black;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: black;
        }

        &::placeholder {
          color: black;
        }
      }

      button {
        width: calc(100% - 0px);
        height: 30px;
        background: rgba(70, 130, 180, 0.3);
        border-radius: 5px;
        color: white;
        cursor: pointer;
        border: none;
        background: @primColor;
      }
    }
  }

  .side-image {
    width: 50vw;
  }

  .container-flag {
    text-align: right;

    .flag {
      height: 25px;
      border-radius: 5px;
      margin: 0px 5px;
      width: 35px;
    }

    .flag-selected {
      border-radius: 5px;
      margin: 0px 5px;
      width: 35px;
      height: 25px;
      filter: drop-shadow(5px 5px 5px #4444dd);
      cursor: pointer;
    }

    .flag:hover {
      height: 26px;
      filter: drop-shadow(5px 5px 5px #4444dd);
      cursor: pointer;
    }
  }
}
</style>
