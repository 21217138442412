import requestModule from "@/libs/requestModule"

export default {
    getDevices(params){
        return requestModule.postRequest('getDevices', params).then(r => {
            return r?.dataset?.devices ?? []
        })
    },
    insertDevice(params){
        return requestModule.postRequest('insertDevice', params)
    }
}
