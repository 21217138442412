import util from "@/libs/util";

const defaultState = {
  CDFILIAL: null,
  CDOPERADOR: null,
  NRORG: null,
  privacyPolicyLink: null
}

const initialState = util.getLocalVar('userData') ?? {}

export default {
  state: () => ({
    ...defaultState,
    ...initialState
  }),
  mutations: {
    setUserData(state, newUserData){
      Object.assign(state, newUserData)
      util.setLocalVar('userData', newUserData)
    }
  }
}
