import requestModule from "@/libs/requestModule"

export default {
  getPlates({ NRORG, CDFILIAL, DTCARDAPIO, CDSERVFILI }) {
    return requestModule
      .postRequest("getPlates", { NRORG, CDFILIAL, DTCARDAPIO, CDSERVFILI })
      .then(r => {
        return r?.dataset?.prato ?? []
      })
  },

  getNutritionalInfo(filterData) {
    return requestModule
    .postRequest("getNutritionalInfo", {
        CDFILIAL: filterData.CDFILIAL,
        CDPRATO: filterData.CDPRATO,
        CDSERVFILI: filterData.CDSERVFILI,
        IDTIPOSERV: filterData.IDTIPOSERV,
        NRORG: filterData.NRORG
      })
      .then(r => {
        return r?.dataset?.nutrientes ?? []
      })
  },

  ratePlate(ratingData){
    return requestModule.postRequest("ratePlate", ratingData)
  },
  reservePlates(plateData) {
    const { plates } = plateData
    return requestModule.postRequest("insertPlatesReservation", {
      ...plateData,
      plates: plates.map(
        ({ NRCARDAPIO, NRSEQPRATCAR, CDFILAUXPRAT, CDPRATO, CDTIPOPRAT }) => ({
          NRCARDAPIO,
          NRSEQPRATCAR,
          CDFILAUXPRAT,
          CDPRATO,
          CDTIPOPRAT
        })
      )
    })
  }
}
