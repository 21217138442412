export default {
  /**
   * Return values stored into localStorage.
   *
   * @param {String} varName
   */
  getLocalVar(varName) {
    return JSON.parse(localStorage.getItem(varName))
  },

  /**
   * Store values into localStorage.
   *
   * @param {String} varName
   * @param {*} value
   */
  setLocalVar(varName, value) {
    localStorage.setItem(varName, JSON.stringify(value))
  },

  mixInLocalVar(varName, value) {
    if (!this.isEmpty(value)) {
      const currentValue = this.getLocalVar(varName) ?? {}
      const newValue = { ...currentValue, ...value }
      this.setLocalVar(varName, newValue)
    }
  },

  isEmpty(value) {
    return (
      value === null || value === undefined || Object.keys(value).length === 0
    )
  },

  getLastElement(arr) {
    return arr[arr.length - 1]
  },

  cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  translate(word) {
    let lang = JSON.parse(localStorage.getItem('lang')) || 'pt-br'

    if (lang == 'es-es') {
      const en = require("../assets/words/es_es");
      return en[word] != undefined ? en[word] : word;
    } else if(lang == 'en-us') {
      const en = require("../assets/words/en_us");
      return en[word] != undefined ? en[word] : word;
    }

    return word;
  }
  
}
