<template>
  <div class="services-view-root">
    <myquest-header />
    <div class="inner-header">
      <div>
        <h3>{{ translateView('Serviços') }}</h3>
      </div>
      <input
        class="date-input"
        type="date"
        :value="selectedDate"
        @change="onDateChanged"
        required
      />
    </div>
    <div class="services-list">
      <div
        v-for="(service, i) in serviceList"
        :key="i"
        class="service-card"
        @click="goToNextView(service)"
      >
        <div class="image-container">
          <img
            :src="getServiceImageUrl(service.IMGSERVICO)"
            @error="$event.target.src = placeHolderImage"
            :alt="service.NMSERVICO"
          />
        </div>
        <div class="service-details">
          <span>{{ service.NMSERVICO }}</span>
          <span v-text=" (service.HRINICIO || '00:00') + ' - ' + (service.HRFINAL || '23:59')"></span>
        </div>
      </div>
    </div>
    <div class="no-data-message" v-if="services.length === 0">
      <h2>{{ translateView('Não existem serviços disponíveis para o dia filtrado') }}</h2>
    </div>
  </div>
</template>

<script>
import util from "@/libs/util"

import ServiceProvider from "@/providers/ServiceProvider"
import Header from "@/components/shared/Header"
import placeHolderImage from "@/assets/placeholder-image.svg"
import { mapState, mapMutations, mapGetters } from "vuex"

export default {
  name: "services",
  created() {
    this.loadServices()
  },
  activated() {
    this.origin = this.$route?.params?.origin ?? this.origin
  },
  components: {
    "myquest-header": Header
  },
  data() {
    return {
      services: [],
      placeHolderImage,
      origin: null
    }
  },
  props: {
    // origin: {
    //   type: String,
    //   validator: v => ["reserve", "view", "rate"].includes(v)
    // }
  },
  watch: {
    selectedDate() {
      this.loadServices()
    },
    selectedSection: {
      handler() {
        this.loadServices()
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      selectedDate: ({ reservation }) => reservation.selectedDate,
      selectedCDFILIAL: ({ config }) => config.branchData.CDFILIAL,
      selectedSection: ({ config }) => config.sectionData,
      userNRORG: ({ userData }) => userData.NRORG,
      sectionLocal: ({ config }) => config.sectionData.NRLOCALPESQ ?? null
    }),
    ...mapGetters(["formattedSelectedDate"]),
    serviceList() {
      if (this.origin === "reserve") {
        return this.services.filter(service => {
          return service.IDRESERVASERVMYMENU === "S" || service.RESERVA_PRATO === "S"
        })
      } else {
        return this.services
      }
    }
  },
  methods: {
    ...mapMutations(["selectDate", "selectService"]),
    onDateChanged({ target }) {
      const newDate = target.value
      this.selectDate(newDate)
    },
    async loadServices() {
      this.services = await ServiceProvider.getServices({
        NRORG: this.userNRORG,
        CDFILIAL: this.selectedCDFILIAL,
        DTCARDAPIO: this.formattedSelectedDate,
        NRLOCALPESQ: this.sectionLocal,
        TELAO: 'N',
        EXIBEPROD: 'N'
      })
    },
    getServiceImageUrl(rawUrl) {
      if (!util.isEmpty(rawUrl)) {
        return `${util.getLocalVar('fileServiceFilesPath')?.url}/${rawUrl}`
      } else {
        return placeHolderImage
      }
    },
    goToNextView(serviceData) {
      this.selectService(serviceData)
      // const path = this.origin === "view" ? "/plates" : "/servicesDetail"
      const name = this.origin === "view" || this.origin === "reserve" ? "plates" : "service-detail"
      this.$router.push({ 
        name,
        params: { origin: this.origin}
      })
    },
    translateView: function(word) {
      return util.translate(word);
    }
  }
}
</script>

<style scoped>
.inner-header {
  display: grid;
  grid-template-columns: 70% 40%;
  width: 80%;
  margin: 15px auto 0 auto;
  vertical-align: middle;
  font-size: 2rem;
}

.inner-header > .date-input {
  align-self: center;
  height: 50px;
  font-size: 2rem;
  border: none;
  border-right: 10px solid transparent;
}

input[type="date" i] {
  color: black;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.services-list {
  width: 90%;
  margin: 0 auto;
  height: calc(100% - 265px);
  overflow-y: scroll;
}

.services-view-root >>> .service-card {
  height: 160px;
  margin-top: 15px;
  /* border: 1px solid; */
  border-radius: 6px;
  display: flex;
  text-align: left;
  cursor: pointer;
  position: relative;
}

.services-view-root >>> .service-card::before {
  content: "";
  background: rgba(211, 211, 211, 0.48);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0.5;
  border-radius: 5px;
}

.services-view-root >>> .service-card .image-container {
  position: relative;
  height: 165px;
}

.services-view-root >>> .service-card .image-container img {
  height: 165px;
  width: 190px;
  object-fit: cover;
  border-radius: 5px;
  background: white;
}

/* .services-view-root >>> .service-card:nth-child(odd) {
  flex-direction: row-reverse;
} */

/* .services-view-root >>> .service-card .service-details {
  flex: 1;
  padding: 15px 15px;
  position: relative;
} */

.services-view-root >>> .service-card .service-details {
  padding: 5px;
  font-size: 1.15rem;
  overflow: hidden;
  position: relative;
}

.services-view-root >>> .service-card .service-details span {
  display: block;
  /*white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
  text-align: center;
}
</style>

<style lang="less" scoped>
.services-view-root {
  height: 100vh;

  .service-card {
    height: 250px;
    margin: 30px 15px 0 15px;
    border-radius: 6px;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    position: relative;
    width: 190px;
    text-align: center;
    background-color: var(--primary-color);
    color: var(--text-color);
    overflow: hidden;
    // padding: 10px;
    // box-shadow: 0 1px 2px 0 rgba(0,0,0,.15);
  }
}
</style>
