import axios from 'axios'
import util from './util'

/**
 * Build request object according to request
 * type.
 * 
 * @param {Object} dataObject 
 * @param {String} requestType 
 * 
 * @returns {Object}
 */
const buildRequestObject = function (dataObject, requestType, requestObjectName) {
    const requestObject = {
        requestType,
        $token: util.getLocalVar('myquest_token'),
    };

    requestObject[requestObjectName || requestType.toLowerCase()] = dataObject;

    return requestObject;
}

export default {
    baseUriPrefix: process.env.VUE_APP_BASE_URI_PREFIX || document.location.hostname,
    baseUri: process.env.VUE_APP_BASE_URI,

    postRequest: function (url, dataObject = {}, requestType = "Row",  requestObjectName, timeout = 1200000,) {
        if(url[0] != '/')
            url = '/' + url;

        const prodURL = this.baseUri + url;
        const devURL = this.baseUriPrefix + '/' + this.baseUri + url;
        const urlObj = process.env.NODE_ENV == 'production' ? prodURL: devURL;
        return axios({
                method: 'post',
                url: urlObj,
                data: buildRequestObject(dataObject, requestType, requestObjectName),
                timeout
            })
            .then(successResponse => successResponse.data)
            .catch(axiosResponse => {
                if (axiosResponse.response.data.error.includes('FORBIDDEN_ACCESS')) {
                    console.error('Invalid Token!');
                    throw 'FORBIDDEN_ACCESS';
                } else {
                    throw axiosResponse.response.data;
                }
            });
    }
}