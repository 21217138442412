import requestModule from "@/libs/requestModule"

const getFilterRquestFromObject = (data = {}) => {
  return Object.entries(data).map(([key, val]) => ({
    name: key,
    operator: "=",
    value: val
  }))
}

const getRgbFromTColor = (TColor) => {
  const r = TColor & 0xff
  const g = (TColor >> 8) & 0xff
  const b = (TColor >> 16) & 0xff
  return `(${r},${g},${b})`
}

export default {
  getCustomizationConfig(appConfigData) {
    const requestData = getFilterRquestFromObject(appConfigData)

    return requestModule
      .postRequest("/crud/appconfig", requestData, "FilterData", "filter")
      .then((response) => {
        const [appconfig] = response?.dataset?.appconfig
        if (appconfig) {
          const parsedColor = getRgbFromTColor(appconfig.DSCORPRIMARIA)
          return {
            logoUrl: appconfig.URLLOGO,
            primaryColor: parsedColor,
            idReservaDigital: appconfig.IDRESERVADIGITAL,
            dsNomeExibicao: appconfig.DSNOMEEXIBICAO
          }
        } else {
          return {
            logoUrl: null,
            primaryColor: null,
            idReservaDigital: null,
            dsNomeExibicao: null
          }
        }
      })
  }
}
