<template>
  <div class="plate-detail-root">
    <myquest-header>
      <template #left-action>
        <div class="back-button" @click="$emit('close')">
          <i class="fas fa-arrow-left"></i>
        </div>
      </template>
    </myquest-header>
    <div class="title-contianer">
      <h3>{{ translateView('Prato') }}</h3>
    </div>
    <div class="plate-details">
      <div class="plate-info-container">
        <div class="plate-image-container">
          <img
            :src="getPlateImageUrl(plateData.NRFOTOPRATOBLOB,plateData.URL_FOTOPRATO)"
            :alt="plateData.NMPRATO"
            @error="$event.target.src = placeHolderImage"
          />
        </div>
        <div class="plate-info">
          <div>{{ translateView('Nome') }}: {{ plateData.NMPRATO }}</div>
          <div 
            v-if="plateData.QTMEDICASE > 0">
            {{ translateView('Kcal') }}: {{ plateData.CALORIAS_KCAL_ROUND + ' - ' +  plateData.QTMEDICASE + ' ' + plateData.NMMEDICASE}}
          </div>
          <div v-else>
            {{ translateView('Kcal') }}: {{ plateData.CALORIAS_KCAL_ROUND }}
          </div>            
        </div>
      </div>

      <div class="nutritional-table-wrapper">
        <table class="nutritional-table">
          <thead>
            <tr>
              <th colspan="2">{{ translateView('Nutrientes') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(nutrient, i) in nutrients" :key="i">
              <td>
                {{ nutrient.NMNUTRIENTE }}
              </td>
              <td>{{ nutrient.QTNUTRIENTE }} {{ nutrient.SGUNIDADE }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <rating-component
        :ratingSettings="ratingSettings"
        class="rating-container"
      />
    </div>
  </div>
</template>

<script>
import util from "@/libs/util"
import placeHolderImage from "@/assets/placeholder-image.svg"

import Header from "@/components/shared/Header"
import RatingComponent from "@/components/shared/ratingComponent"

import ReservationService from "@/service/ReservationService"
import PlatesProvider from "@/providers/PlatesProvider"
import { mapState, mapGetters } from "vuex"

export default {
  name: "PlateInfoPopUp",
  props: {
    plateData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    "myquest-header": Header,
    "rating-component": RatingComponent
  },
  computed: {
    ...mapState({
      serviceData: ({ reservation }) => reservation.serviceData,
      selectedBranchCode: ({ config }) => config.branchData.CDFILIAL,
      selectedSectionCode: ({ config }) => config.sectionData.NRLOCALPESQ,
      userNRORG: ({ userData }) => userData.NRORG
    }),
    ...mapGetters(["formattedSelectedDate"]),
    ratingSettings() {
      return {
        filter: [
          {
            name: "CDFILIAL",
            value: this.selectedBranchCode,
            operator: "="
          },
          {
            name: "NRORG",
            value: this.userNRORG,
            operator: "="
          },
          {
            name: "CDSERVFILI",
            value: this.serviceData.CDSERVFILI,
            operator: "="
          },
          {
            name: "CDPRATO",
            value: this.plateData.CDPRATO,
            operator: "="
          },
          {
            name: "CDFILAUXPRAT",
            value: this.plateData.CDFILAUXPRAT,
            operator: "="
          }
        ],
        dataSourceUrl: "/crud/avaliacao_prato",
        dataSourceName: "avaliacao_prato",
        rating: {
          rateValueField: "VRAVALIACAOPRATO",
          commentDescField: "DSAVALIACAOPRATO",
          rateId: "NRAVALIACAOPRATO",
          userNameField: "NMUSUARIO"
        },
        onRate: this.onRate
      }
    },
  },
  data() {
    return {
      nutrients: [],
      placeHolderImage
    }
  },
  async created() {
    const filterData = {
      CDSERVFILI: this.serviceData.CDSERVFILI,
      IDTIPOSERV: this.serviceData.IDTIPOSERV,
      CDFILIAL: this.selectedBranchCode,
      NRORG: this.userNRORG,
      CDPRATO: this.plateData.CDPRATO
    }
    this.nutrients = await PlatesProvider.getNutritionalInfo(filterData)
  },
  methods: {
    getPlateImageUrl(id,foto_mobile) {
      if (!util.isEmpty(id)) {
        const urlPrefix =
          process.env.NODE_ENV === "development"
            ? process.env.VUE_APP_BASE_URI_PREFIX + "/"
            : ""
        const url = `${urlPrefix}${process.env.VUE_APP_BASE_URI}/plateImage?id=${id}&nrorg=${this.userNRORG}`;
        let url_mobile = `${util.getLocalVar('fileServiceFilesPath').url+foto_mobile}`;
        
        let url_foto;
        
        if(foto_mobile){
          
          url_foto = url_mobile;
          
        }else{
          url_foto = url;
        }
       
        
        return url_foto
      } else {
        return this.placeHolderImage
      }
    },

    onRate(ratingValue) {
      const ratingData = {
        rateValue: ratingValue,
        DTCARDAPIO: this.formattedSelectedDate,
        ...this.serviceData,
        CDPRATO: this.plateData.CDPRATO,
        CDTIPOPRAT: this.plateData.CDTIPOPRAT,
        NRSEQPRATCAR: this.plateData.NRSEQPRATCAR,
        CDFILAUXPRAT: this.plateData.CDFILAUXPRAT,
        NRORG: this.userNRORG,
        CDFILIAL: this.selectedBranchCode,
        NRLOCALPESQ: this.selectedSectionCode
      }

      return ReservationService.ratePlate(ratingData)
    },

    translateView: function(word) {
      return util.translate(word);
    }
  }
}
</script>

<style lang="less" scoped>
.plate-detail-root {
  height: 100vh;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .title-contianer {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 2rem;

    > * {
      margin: auto;
    }
  }

  .plate-details {
    width: 80%;
    height: calc(100% - 235px);
    overflow-y: scroll;
    margin: auto;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .plate-info-container {
      display: flex;

      .plate-image-container {
        img {
          height: 160px;
          width: 160px;
          object-fit: cover;
          border-top-left-radius: 10px;
        }
      }

      .plate-info {
        padding: 15px 5px;
        font-size: 1.4rem;
      }
    }

    .nutritional-table-wrapper {
      display: block;
      overflow-y: scroll;
      min-height: 0;
      width: 100%;

      .nutritional-table{
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse ;

        tr:nth-child(odd){
          td{
            background-color: #dfe5e6;
          }
        }
      }
    }

    .rating-container {
      width: 100%;
      overflow-y: scroll;
      flex-basis: 40%;
      flex-shrink: 0;
      flex-grow: 1;
    }
  }
}
</style>
