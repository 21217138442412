<template>
  <div class="home-root">
    <myquest-header>
      <template #left-action>
        <div class="back-button" style="display: none;">
          <i class="fas fa-cog"></i>
        </div>
      </template>
    </myquest-header>
    <div class="content-container">
      <survey class="survey-area" />
      <div class="button-container">
        <button
          v-if="reservationButtonVisible"
          @click="goToDatePickerView('reserve')"
        >
          {{ translateView('Fazer Reserva') }}
        </button>
        <button
          v-if="goToMenuButtonsVisible"
          @click="goToDatePickerView('view')"
        >
          {{ translateView('Ver Cardapio') }}
        </button>
        <button
          v-if="goToMenuButtonsVisible"
          @click="goToDatePickerView('rate')"
        >
          {{ translateView('Avaliar Pratos') }}
        </button>
        <button @click="goToCustomerServiceView">{{ translateView('SAC') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Survey from "@/components/survey"
import util from "@/libs/util"
import Header from "@/components/shared/Header"
import { getHomeScreenConfig } from "@/providers/HomeScreenConfigProvider"
import { startHeartbeat, stopHeartbeat } from  "@/libs/heartbeat"
import { mapState } from "vuex"

export default {
  name: "home",
  components: {
    survey: Survey,
    "myquest-header": Header
  },
  data() {
    return {
      homeScreenConfigData: null
    }
  },
  async created() {
    this.homeScreenConfigData = await getHomeScreenConfig({
      CDFILIAL: this.selectedBranch,
      NRORG: this.NRORG
    })
  },
  activated(){
    this.updateHeartBeat();
  },
  mounted(){
    this.updateHeartBeat();
  },
  deactivated() {
    stopHeartbeat();
  },
  computed: {
    ...mapState({
      selectedBranch: ({ config }) => config.branchData.CDFILIAL,
      NRORG: ({ userData }) => userData.NRORG,
      selectedDevice: ({ config }) => config.deviceData // Adiciona o selectedDevice ao mapState
    }),
    reservationButtonVisible() {
      return this?.homeScreenConfigData?.[0].PERMITE_RESERVA === "S"
    },
    goToMenuButtonsVisible() {
      return this?.homeScreenConfigData?.[0].PERMITE_VER_CARDAPIO === "S"
    }
  },
  methods: {
    updateHeartBeat(){
      if(this.selectedDevice.NRDISPOSITIVOMYQ) startHeartbeat(this.translateView('Pesquisa e Cardápio'), this.selectedDevice.NRDISPOSITIVOMYQ);
    },
    goToDatePickerView(origin) {
      this.$router.push({
        name: "datePickerView",
        params: { cameFromHome: true, origin }
      })
    },
    goToCustomerServiceView() {
      this.$router.push("/sac")
    },
    goToConfigView() {
      this.$router.push("/login")
    },
    translateView(word) {
      return util.translate(word);
    }
  }
};
</script>

<style lang="less" scoped>
.home-root {
  height: 100%;
  display: flex;
  flex-flow: column;

  .content-container {
    overflow-y: hidden;
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    width: 100%;

    .survey-area {
      display: flex;
      height: 100%;
      width: 100%;
      flex-flow: column;
      padding: 20px 0px;
    }

    .button-container {
      position: fixed;
      bottom: 40px;
      //background: #f5f5f5;
      height: 70px;
      // width: 100%;
      width: calc(100vw - 60px);
      display: flex;
      flex-flow: row;
      justify-content: space-around;

      button {
        width: 22%;
        height: 90px;
        margin: auto;
        background: var(--primary-color);
        color: var(--text-color);
        border: none;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 1.8em;
        font-weight: bold;
      }
    }
  }
}
</style>
