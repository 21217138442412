<template>
  <div class="cs-view-root">
    <myquest-header />
    <div class="cs-tabs-header">
      <div
        class="cs-tab-label"
        @click="setActiveTab('sendCSMessage')"
        :class="{ active: activeTab === 'sendCSMessage' }"
      >
        {{ translateView('Enviar SAC') }}
      </div>
      <div
        class="cs-tab-label"
        @click="setActiveTab('CSMessagesList')"
        :class="{ active: activeTab === 'CSMessagesList' }"
      >
        {{ translateView('Acompanhar SAC') }}
      </div>
    </div>
    <div class="tab-body" v-if="activeTab === 'sendCSMessage'">
      <form class="cs-form" @submit="onCustomerServiceMessageSubmit">
        <div class="field-group">
          <label class="field flex1">
            <span> {{ translateView('Nome') }} </span>
            <input v-model="CSFormData.name" type="text" required />
          </label>
          <label class="field flex1">
            <span> {{ translateView('Assunto') }} </span>
            <select v-model="CSFormData.subject" required>
              <option value="SUGESTAO">{{ translateView('Sugestão') }}</option>
              <option value="DUVIDA">{{ translateView('Dúvida') }}</option>
              <option value="RECLAMACAO">{{ translateView('Reclamação') }}</option>
              <option value="ELOGIO">{{ translateView('Elogio') }}</option>
            </select>
          </label>
        </div>
        <div class="field textarea-holder">
          
            <span>{{ translateView('Mensagem') }}</span>
            <textarea v-model="CSFormData.message" required></textarea>
          
        </div>
        <button>{{ translateView('Enviar') }}</button>
      </form>
    </div>
    <div class="tab-body" v-if="activeTab === 'CSMessagesList'">
      <ul class="cs-list">
        <li
          v-for="(message, i) in CSMessages"
          :key="i"
          class="cs-list-item"
          @click="showDetailsModal(message)"
        >
          <div class="topic-grid-area">
            {{ translateView('Assunto') }}: {{ getMessageType(message.IDFALECONOSCOAPP) }}
          </div>
          <div>{{ translateView('Nome') }}: {{message.DSEMAILCLIENTE}}</div>
          <div class="text-align-right">{{ translateView('Data') }}: {{ message.DTINCLUSAO }}</div>
        </li>
      </ul>
    </div>

    <modal
      name="message-sent-modal"
      :height="'100%'"
      :width="'100%'"
      :transition="'none'"
    >
      <div class="thanks-view" style="font-size: 40px; padding-top: 25%;">
        {{ translateView('Feedback enviado com sucesso') }}
      </div>
    </modal>
  </div>
</template>

<script>
import MyQuestHeader from "@/components/shared/Header"

import CustomerServiceMessage from "./CustumerServiceMessage"

import CSProvider from "@/providers/CSProvider"

import util from "@/libs/util"
import moment from "moment"
import { mapState } from "vuex"

export default {
  name: "CSView",
  components: {
    "myquest-header": MyQuestHeader
  },
  async activated() {
    this.loadCSMessages()
  },
  data() {
    return {
      isButtonDisabled: false,
      activeTab: "sendCSMessage",
      CSFormData: {
        name: null,
        subject: null,
        message: null
      },
      CSMessages: []
    }
  },
  computed: {
    ...mapState({
      selectedCDFILIAL: ({ config }) => config.branchData.CDFILIAL,
      selectedSection: ({ config }) => config.sectionData.NRLOCALPESQ,
      userNRORG: ({ userData }) => userData.NRORG
    })
  },
  methods: {
    setActiveTab(tabName) {
      const existingTabNames = ["sendCSMessage", "CSMessagesList"]
      if (existingTabNames.includes(tabName)) {
        this.activeTab = tabName
      }
    },
    
    async onCustomerServiceMessageSubmit(event) {
      event.preventDefault()
      if(this.isButtonDisabled == true) {
        return
      }
      this.isButtonDisabled = true
      const currentDate = moment().format("DD/MM/YYYY")

      const CSMessageData = {
        CDFILIAL: this.selectedCDFILIAL,
        NRLOCALPESQ: this.selectedSection,
        NRORG: this.userNRORG,
        IDFALECONOSCOAPP: this.CSFormData.subject,
        DSFALECONOSCOAPP: this.CSFormData.message,
        DSEMAILCLIENTE: this.CSFormData.name,
        currentDate
      }

      await CSProvider.insertMessage(CSMessageData)
      this.isButtonDisabled = false
      this.showMessageSentSuccessModal()
      this.clearDataMessage()
      this.loadCSMessages()
    },
    async loadCSMessages() {
      const filter = {
        NRORG: this.userNRORG,
        CDFILIAL: this.selectedCDFILIAL,
        NRLOCALPESQ: this.selectedSection
      }
      const CSMessages = await CSProvider.getCSMessages(filter)
      this.CSMessages = CSMessages
    },
    showDetailsModal(CSMessageData) {
      this.$modal.show(
        CustomerServiceMessage,
        {
          answer: CSMessageData.DSRESPOSTAAPP,
          answeredOn: CSMessageData.DTULTATU,
          subject: CSMessageData.IDFALECONOSCOAPP,
          userMessage: CSMessageData.DSFALECONOSCOAPP,
          sentOn: CSMessageData.DTINCLUSAO
        },
        {
          height: "100%",
          width: "100%"
        }
      )
    },
    showMessageSentSuccessModal() {
      this.$modal.show("message-sent-modal")
      this.setActiveTab("CSMessagesList")
      setTimeout(() => {
        this.$modal.hide("message-sent-modal")
      }, 2500)
    },
    getMessageType(messageCode) {
      const messageTypesMap = {
        ELOGIO: this.translateView("Elogio"),
        RECLAMACAO: this.translateView("Reclamação"),
        SUGESTAO: this.translateView("Sugestão"),
        DUVIDA: this.translateView("Dúvida")
      }

      const messageTypeString = messageTypesMap[messageCode]
      return messageTypeString
    },
    translateView: function(word) {
      return util.translate(word);
    },
    clearDataMessage() {
      this.CSFormData.name = null;
      this.CSFormData.subject = null;
      this.CSFormData.message = null;
    }
  }
}
</script>

<style lang="less" scoped>
.disabled {
  pointer-events: none; /* Desabilita os eventos de clique */
  opacity: 0.6; /* Aplica uma aparência de botão desativado */
}
.cs-view-root {
  height: calc(100%);
  display: flex;
  flex-direction: column;
  .tab-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  .cs-tabs-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .cs-tab-label {
      position: relative;
      font-size: 1.5rem;
      cursor: pointer;
      user-select: none;
      display: inline-block;
      padding: 20px;
      flex: 1;
      text-transform: uppercase;
      font-weight: 600;
      background: #d3d3d363;

      &.active {
        background: white;
        cursor: auto;
        color: var(--primary-color);
        &::before {
          content: "";
          height: 3px;
          background-color: var(--primary-color);
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
        &:after {
          content: '';
          position: absolute;
          right: 0;
          width: 1px;
          bottom: 30%;
          background-color: var(--primary-color);
          top: 30%;
        }
        &:last-of-type{
          &:after {
              content: '';
              right: inherit;
              left: 0;
          } 
        }
      }
    }
  }

  .cs-form {
    //width: 65%;
    margin: auto;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    .field-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: auto;
      gap: 20px;
    }
    .textarea-holder{
      width: 100%;
      flex-grow: 1;
      display: flex !important;
      flex-direction: column;
    }
    .field {
      display: block;
      margin-top: 20px;

      span {
        display: block;
        font-size: 1.5em;
        margin-bottom: 15px;
      }

      textarea,
      input,
      select {
        appearance: none;
        border-radius: 3px;
        border: 1px solid grey;
        background-color: white;
        padding: 5px;
        width: 100%;
        box-sizing: border-box;
        height: 55px;
        font-size: 1.5em;
      }

      textarea {
        resize: none;
        font-family: inherit;
        flex-grow: 1;
        width: 100%;
      }
      &.flex1 {
        flex: 1;
      }
    }

    button {
      margin-top: 10px;
      height: 70px;
      width: 300px;
      background: var(--primary-color);
      border-radius: 5px;
      color: var(--text-color);
      padding: 0 10px;
      font-size: 1.5em;
      border: none;
    }
  }

  .cs-list {
    list-style-type: none;
    padding-inline-start: 0;
    width: 80%;
    margin: auto;
    margin-top: 10px;
    height: calc(100vh - 205px);
    overflow-y: scroll;
    padding-bottom: 10px;
    font-size: 1.25rem;

    .cs-list-item {
      height: 55px;
      margin-top: 8px;
      border-radius: 6px;
      padding: 5px 10px;

      text-align: left;
      display: grid;
      grid-template-areas:
        "topic topic"
        ". .";
      align-items: center;

      box-shadow: 1px 1px 2px 0px grey;

      .text-align-right {
        text-align: right;
      }

      .topic-grid-area {
        grid-area: topic;
      }
    }
  }
}
</style>
