import LoginProvider from "@/providers/LoginProvider"
import Util from "@/libs/util"

/**
 * Log user and catch errors
 */
function doLogin(accessKey, password) {
  const loginData = {
    password,
    accessKey
  }

  return LoginProvider.login(loginData).then(loginData => {
    if (loginData && loginData.userData) {
      
      if (loginData.token && loginData.token) {
        Util.setLocalVar("myquest_token", loginData.token)
      }
      Util.setLocalVar("fileServiceFilesPath", {url: loginData?.userData?.fileServiceFilesPath})


      return loginData.userData
    } else {
      return Promise.reject()
    }
  })
}

/**
 * Log out user. Exclude user data from local
 * storage and remove token from localStorage.
 */
function doLogout() {
  localStorage.removeItem("myquest_token")
  localStorage.removeItem("userData")
}

function isLogged() {
  return LoginProvider.testRequest()
}

export default { doLogin, doLogout, isLogged }
