import requestModule from "@/libs/requestModule"

const getFilterRquestFromObject = (data = {}) => {
  return Object.entries(data).map(([key, val]) => ({
    name: key,
    operator: "=",
    value: val
  }))
}

export default {
  getSections(userInfo) {
    const requestData = getFilterRquestFromObject(userInfo)

    return requestModule
      .postRequest(
        "/crud/local_filialOper",
        requestData,
        "FilterData",
        "filter"
      )
      .then(response => {
        return response?.dataset?.local_filialOper ?? []
      })
  }
}
