import moment from "moment"

const ReservationHelper = {
    getInstanceDate(dateStr, hourStr = '00:00:00', format = 'YYYY-MM-DD HH:mm:ss'){
        let {groups: { HOUR, MINUTES, SECONDS }} = this.getPatternTime().exec(hourStr) || {groups: {}};
        return moment(`${dateStr} ${HOUR}:${MINUTES}:${SECONDS || '00'}`, format);
    },
    getInstanceTime(timeStr = '00:00:00', format = 'HH:mm:ss'){
        return moment(timeStr, format);
    },
    getPatternTime(){
        let PATTERN_TIME = new RegExp('^(?<HOUR>\\d{2}):?(?<MINUTES>\\d{2}):?(?<SECONDS>\\d{2})?$', 'g');
        return PATTERN_TIME;
    },
    getSecondsByLimitDateTime( days, hours ){
        try {
            let securityDays = 0;
            let securityHours = 0;
            let securityMinutes = 0;
            if( days && parseInt(days)) {
                securityDays = parseInt(days);
            } 
            if( hours ) {
                const {groups: { HOUR: LIMIT_HOUR, MINUTES: LIMIT_MINUTES }} = this.getPatternTime().exec(hours) || {groups: {}};
                securityHours = parseInt(LIMIT_HOUR);
                securityMinutes = parseInt(LIMIT_MINUTES);
            }
            const HOURS_IN_ONE_DAY = 24;
            const MINUTES_IN_ONE_HOUR = 60
            const SECONDS_IN_ONE_MINUTE = 60;
            const daysToSeconds = days => days * HOURS_IN_ONE_DAY * MINUTES_IN_ONE_HOUR * SECONDS_IN_ONE_MINUTE;
            const hoursToSeconds = hours => hours * MINUTES_IN_ONE_HOUR * SECONDS_IN_ONE_MINUTE;
            const minutesToSeconds = minutes => minutes * SECONDS_IN_ONE_MINUTE;
            let seconds = daysToSeconds(securityDays) + hoursToSeconds(securityHours) + minutesToSeconds(securityMinutes);
            return seconds;
        } catch( error ){
            console.error("INVALID_SECURITY_LIMIT", error.message);
        }
        return 0;
    },
    getServiceInstances( startPrepServ, startService, endService ){
        startPrepServ = (startPrepServ || '00:00') + ':00';
        startService = (startService || '00:00') + ':00';
        endService = (endService ? endService + ':00' : '23:59:59');
        return ({
            START_PREP : this.getInstanceTime(startPrepServ),
            START_SERV : this.getInstanceTime(startService),
            END_SERV   : this.getInstanceTime(endService)
        });
    },
    /**
     * 
     * @param {String} menuDate YYYY-MM-DD
     * @param {String} startService HH:mm
     * @param {String} endService HH:mm 
     */
    getRangeService( menuDate, startPrepServ, startService, endService ){
        const TIME_FORMAT = 'HH:mm:ss';
        const {
            START_PREP,
            START_SERV,
            END_SERV
        } = this.getServiceInstances(startPrepServ, startService, endService);
        let startServiceMoment = this.getInstanceDate(`${menuDate} ${START_SERV.format(TIME_FORMAT)}`);
        let endServiceMoment = this.getInstanceDate(`${menuDate} ${END_SERV.format(TIME_FORMAT)}`);
        const SERV_AVAILABLE_TWO_DAYS = START_SERV > END_SERV;
        const PREPARED_TWO_DAYS = (START_PREP > START_SERV) && !!startService;

        if( SERV_AVAILABLE_TWO_DAYS ) {
            endServiceMoment.add(1, 'days');
        } else if( PREPARED_TWO_DAYS ) {
            startServiceMoment.add(1, 'days');
            endServiceMoment.add(1, 'days');
        }
        return ({
            START_SERVICE: startServiceMoment,
            END_SERVICE: endServiceMoment
        });
    },
    analizeServiceTime( startPrepServ, startService, endService ){
        let handledStartPrepServ = startPrepServ + ':00';
        let handledStartService = (startService || '00:00') + ':00';
        let handledEndService = (endService ? endService + ':00' : '23:59:59');
        const {
            START_PREP, 
            START_SERV, 
            END_SERV
        } = this.getServiceInstances(handledStartPrepServ, handledStartService, handledEndService);
        const SERV_IN_TWO_DAYS = ( ( START_PREP > START_SERV ) || ( START_SERV > END_SERV ) ); //&& !!startService;
        return ({
            START_PREP, 
            START_SERV, 
            END_SERV,
            SERV_IN_TWO_DAYS
        });
    },
    dateToStr(dateInstanceMoment, format = 'YYYY-MM-DD'){
        return dateInstanceMoment.format(format); 
    },
    isAllowedReservation( menuDate, securityDays, securityHours, startPrepServ, startService, endService, now = null, onlyValidationResult = true ){
        const TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
        if( !now ) now = moment();
        const {
            SERV_IN_TWO_DAYS,
            START_SERV, 
            END_SERV,
        } = this.analizeServiceTime( startPrepServ, startService, endService );
        const SECONDS_LIMIT = this.getSecondsByLimitDateTime(securityDays, securityHours);
        const START_SERVICE = moment(`${menuDate} ${START_SERV.format('HH:mm:ss')}`, TIMESTAMP_FORMAT);
        let endServiceMoment = moment(`${menuDate} ${END_SERV.format('HH:mm:ss')}`, TIMESTAMP_FORMAT);
        let endReservationTime = SECONDS_LIMIT ? START_SERVICE.clone().subtract(SECONDS_LIMIT, 'seconds') : endServiceMoment;
        const SERVED_IN_TWO_DAYS = START_SERV > END_SERV;
        if( !startService ) {
            START_SERVICE.add(1, 'days');
            endServiceMoment.add(1, 'days');
        }
        if( SERV_IN_TWO_DAYS ) {
            if( SECONDS_LIMIT && !SERVED_IN_TWO_DAYS) {
                endReservationTime.add(1, 'days');
            } else if(!SECONDS_LIMIT){
                endReservationTime = endServiceMoment.clone().add(1, 'days');
            } 
        }
        let isValid = now <= endReservationTime;
        if( SECONDS_LIMIT && !startService ) {
            isValid = now.clone().add(SECONDS_LIMIT, 'seconds') <= endServiceMoment;
            if(!isValid) {
                endReservationTime = endServiceMoment.clone().subtract(SECONDS_LIMIT);
            }
        }
        return onlyValidationResult ? isValid : { isValid, endReservationTime };
    },
    /**
     * @param {String} consumptionDate YYYY-MM-DD
     * @param {String} consumptionHour HH:mm
     * @param {String} menuDate YYYY-MM-DD
     * @param {Object} securityLimit {DAYS, HOURS}
     * @param {String} startPrepServ HH:mm
     * @param {Object} rangeService {START, END} - HH:mm
     */
    isValidConsumptionDateTime( consumptionDate, consumptionHour, menuDate, securityLimit, startPrepServ, rangeService, now = null, onlyValidationResult = true ){
        if ( !now ) now = moment();
        const { DAYS, HOURS } = securityLimit;
        const SECONDS_LIMIT = this.getSecondsByLimitDateTime(DAYS, HOURS);
        const { START, END } = rangeService;
        const CONSUMPTION = this.getInstanceDate(consumptionDate, consumptionHour);
        const {
            START_SERVICE,
            END_SERVICE
        } = this.getRangeService( menuDate, startPrepServ, START, END);
        let isValid = true;
        let msg = '';
        let msgParams = {};
        const IS_RESERVATION_PAST = now > CONSUMPTION;
        if( IS_RESERVATION_PAST ) {
            isValid = false;
            msg = 'RESERVATION_PAST';
        } else {
            const IS_VALID_RESERVATION = CONSUMPTION >= START_SERVICE && CONSUMPTION <= END_SERVICE;
            if ( SECONDS_LIMIT ) {
                const IS_VALID_SECURITY_LIMIT = START 
                                                ? now <= START_SERVICE.clone().subtract(SECONDS_LIMIT, 'seconds') 
                                                : CONSUMPTION.diff(now, 'seconds') >= SECONDS_LIMIT;
                isValid = IS_VALID_SECURITY_LIMIT && IS_VALID_RESERVATION;
                if( !IS_VALID_SECURITY_LIMIT ) {
                    if( START ) {
                        msg = "EXPIRED_TIME_RESERVATION";
                        msgParams.END_RESERVATION = START_SERVICE.clone().subtract(SECONDS_LIMIT, 'seconds').format('DD/MM/YYYY HH:mm');
                    } else {
                        msg = "RESERVATION_NOT_AVAILABLE";
                        msgParams.START_RESERVATION = now.clone().add(SECONDS_LIMIT, 'seconds').format('DD/MM/YYYY HH:mm');
                    }
                } else if( !IS_VALID_RESERVATION) {
                    msg = "SERVICE_NOT_AVAILABLE";
                    msgParams.START = START_SERVICE.format('DD/MM/YYYY HH:mm');
                    msgParams.END = END_SERVICE.format('DD/MM/YYYY HH:mm');
                }
            } else {
                isValid = IS_VALID_RESERVATION && now <= END_SERVICE;
                if( !isValid ) {
                    msg = "SERVICE_NOT_AVAILABLE";
                    msgParams.START = START_SERVICE.format('DD/MM/YYYY HH:mm');
                    msgParams.END = END_SERVICE.format('DD/MM/YYYY HH:mm');
                }
            }
        }
        return onlyValidationResult ? isValid : {isValid, msg, msgParams};
    },
};

export default ReservationHelper;