<template>
  <div v-if="survey.length > 0" class="survey-root">
    <component
      :questionTitle="currentQuestion.TXQUESTAO"
      :is="currentQuestionType"
      :options="currentQuestion.answers"
      :isAnswerRequired="currentQuestion.IDQUESTAOOBRIGA === 'S'"
      :hideBackButton="true"
      :layout="currentQuestion.LAYOUT"
      @questionAnswered="onQuestionAnswered"
      v-if="isSurveyAvailable"
    />
    <div v-else>
      <div>Sem pesquisas por hoje ...</div>
    </div>

    <modal
      name="thanks-modal"
      :height="'100%'"
      :width="'100%'"
      :transition="'none'"
    >
      <div class="thanks-view" style="font-size: 40px; padding-top: 25%;">
        Obrigado pela sua participação.
      </div>
    </modal>
  </div>
  <informative v-else/>
</template>

<script>
import util from "@/libs/util"
import moment from "moment"

import MultipleChoice from "@/components/survey/questions/MultipleChoice"
import SingleChoice from "@/components/survey/questions/SingleChoice"
import FreeText from "@/components/survey/questions/FreeText"
import Informative from "@/components/informative"

import SurveyProvider from "@/providers/SurveyProvider"
import { mapState } from "vuex"

export default {
  name: "survey",
  created() {
    if (this.sectionCode !== this.currentLoadedSectionCode) {
      this.loadSurveyData()
    }
    this.currentLoadedSectionCode = this.sectionCode
    const oneMinute = 60 * 1000
    this.poolingIntervalID = setInterval(this.loadSurveyData, oneMinute * 5)
  },
  beforeDestroy() {
    clearInterval(this.poolingIntervalID)
  },
  components: {
    "multiple-choice": MultipleChoice,
    "single-choice": SingleChoice,
    "free-text": FreeText,
    informative: Informative
  },
  watch: {
    sectionCode: {
      handler(newValue) {
        if (!this._inactive) {
          this.loadSurveyData()
          this.currentLoadedSectionCode = newValue
        }
      }
    }
  },
  data() {
    return {
      answered: [],
      survey: [],
      currentQuestionIndex: 0,
      childQuestions: [],
      isShowingChildren: false,
      currentLoadedSectionCode: undefined,
      poolingIntervalID: null
    }
  },
  computed: {
    ...mapState({
      appConfig: state => state.config,
      organizationCode: ({ userData }) => userData.NRORG,
      branchCode: ({ config }) => config.branchData.CDFILIAL,
      sectionCode: ({ config }) => config.sectionData.NRLOCALPESQ ?? null
    }),
    isSurveyAvailable() {
      return this?.survey?.length > 0
    },
    currentQuestionType() {
      let questionType
      const { IDTIPOQUESTAO, IDOPCAORESPOSTA } = this.currentQuestion
      if (IDTIPOQUESTAO === "ABERTA_TEXTO") {
        questionType = "free-text"
      } else {
        questionType =
          IDOPCAORESPOSTA === "UNICA" ? "single-choice" : "multiple-choice"
      }
      
      return questionType
    },
    sortedQuestions() {
      return [...this.survey].sort(
        (a, b) => a.NRORDEMQUESTAO - b.NRORDEMQUESTAO
      )
    },
    currentQuestion() {
      if (this.isShowingChildren && this.childQuestions.length) {
        return util.getLastElement(this.childQuestions)
      } else {
        return this.sortedQuestions[this.currentQuestionIndex] ?? {}
      }
    },
    surveyData() {
      const [firstQuestion] = this.survey
      if (firstQuestion) {
        const { NRPROGRAMACAOPESQ, NRQUESTIONARIO } = firstQuestion
        return { NRPROGRAMACAOPESQ, NRQUESTIONARIO }
      } else {
        return null
      }
    }
  },
  methods: {
    resetState() {
      this.answered = []
      this.currentQuestionIndex = 0
      this.isShowingChildren = false
    },
    async sendAnswer() {
      this.$modal.show("thanks-modal")
      setTimeout(() => {
        this.$modal.hide("thanks-modal")
      }, 5000)

      const answers = util.cloneObject(this.answered)
      await SurveyProvider.insertSurvey({
        answers,
        surveyData: this.surveyData,
        filteredData: {
          NRORG: this.organizationCode,
          CDFILIAL: this.branchCode,
          NRLOCALPESQ: this.sectionCode
        }
      })
    },
    async onQuestionAnswered(answer) {
      const lastAnswer = util.getLastElement(this.answered) ?? {}
      const NRRESPOSTAANTERIOR = lastAnswer.NRPROXIMAQUESTAO ? lastAnswer.NRRESPOSTA : null
      const { NRQUESTAO } = this.currentQuestion
      const answeredOn = moment().format("DD/MM/YYYY HH:mm:ss")
      this.answered = [
        ...this.answered,
        { ...answer, NRRESPOSTAANTERIOR, NRQUESTAO, answeredOn }
      ]

      if (answer.NRPROXIMAQUESTAO) {
        const nextQuestion = await SurveyProvider.getNextQuestion({
          NRPROXIMAQUESTAO: answer.NRPROXIMAQUESTAO
        })
        this.childQuestions = [...this.childQuestions, nextQuestion]
        this.isShowingChildren = true
      } else {
        this.currentQuestionIndex++
        this.isShowingChildren = false
      }

      if (util.isEmpty(this.currentQuestion)) {
        this.sendAnswer()
        this.resetState()
      }
    },
    async loadSurveyData() {
      this.survey = await SurveyProvider.getSurveyData({
        NRORG: this.organizationCode,
        CDFILIAL: this.branchCode,
        NRLOCALPESQ: this.sectionCode
      })
    }
  }
}
</script>

<style scoped>
.survey-root {
  /* height: 100%; */
}
</style>
