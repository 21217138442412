<template>
  <div class="date-picker-view-root">
    <myquest-header />
    <div class="date-picker-view">
      <h1 class="instructions-text">
        {{ translateView('Em qual data deseja ' + instructionsText) }}?
      </h1>
      <div class="date-picker-container">
        <div class="date-picker-control" @click="decreaseDate">◀</div>
        <div class="date-viewer">{{ formatedDate }}</div>
        <div class="date-picker-control" @click="increaseDate">▶</div>
      </div>
      <button @click="goToServiceView">
        {{ translateView('Continuar') }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import Header from "@/components/shared/Header"
import { mapMutations, mapState } from "vuex"
import util from "@/libs/util"

export default {
  name: "DatePickerView",
  components: {
    "myquest-header": Header
  },
  activated() {
    const { cameFromHome, origin } = this.$route.params
    if (cameFromHome) {
      const today = moment().format("YYYY-MM-DD")
      this.selectDate(today)
      this.origin = origin
    }
  },
  data() {
    return {
      origin: null
    }
  },
  computed: {
    ...mapState({
      formatedDate({ reservation }) {
        return moment(reservation.selectedDate).format("DD/MM")
      },
      selectedDate: ({ reservation }) => reservation.selectedDate
    }),
    instructionsText() {
      if (this.origin === "reserve") {
        return "reservar o serviço"
      } else if (this.origin === "rate") {
        return "avaliar o serviço"
      } else if (this.origin === "view") {
        return "ver o cardápio"
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapMutations(["selectDate"]),
    decreaseDate() {
      const newDate = moment(this.selectedDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD")
      this.selectDate(newDate)
    },
    increaseDate() {
      const newDate = moment(this.selectedDate)
        .add(1, "day")
        .format("YYYY-MM-DD")
        if (this.origin === "rate" && moment().format("YYYY-MM-DD") < newDate) return;
        this.selectDate(newDate)
    },
    goToServiceView() {
      this.$router.push({
        name: "serviceListView",
        params: { origin: this.origin}
      })
    },
    translateView: function(word) {
      return util.translate(word);
    }
  }
}
</script>

<style lang="less" scoped>
.date-picker-view-root {
  // height: 100vh;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  .date-picker-view {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 0;

    .instructions-text {
      //background-color: rgba(222, 217, 217, 0.33);
      max-width: 80%;
      margin: 0 auto;
      filter: drop-shadow(0 0 1px black);
      padding: 15px;
      border-radius: 5px;
      font-size: 4.5rem;
      color: var(--text-color);
      background: var(--primary-color);
    }

    .date-picker-container {
      font-size: 7rem;
      display: flex;
      width: 100%;
      justify-content: center;
      align-content: center;
      user-select: none;

      .date-picker-control {
        color: var(--primary-color);
        padding: 0 20px;
        cursor: pointer;
        filter: drop-shadow(0px 0px 1px black);
      }

      .date-viewer {
        font-size: 8rem;
        font-weight: bold;
        color: var(--text-color);
        filter: drop-shadow(0px 0px 1px black);
        background: var(--primary-color);
      }
    }

    button {
      height: 70px;
      background: var(--primary-color);
      color: var(--text-color);
      border: none;
      border-radius: 5px;
      padding: 0 10px;
      margin: 0 40px;
      font-size: 3.30rem;
    }
  }
}
</style>
