<template>
  <div class="config-root" :style="`background: url('${MyQuestBackground}') center center no-repeat; background-size: cover;`">
    <myquest-header>
      <template #left-action>
        <div class="back-button" style="display: none;">
          <i class="fas fa-cog"></i>
        </div>
      </template>
    </myquest-header>
    <form ref="configForm" class="config-form">
      <div class="field">
        <label for="config-branch-field">{{ translateView('Unidade') }}:</label>
        <vue-select
          :options="branchOptions"
          :value="branch"
          :getOptionLabel="
            o => {
              return `${o.CDFILIAL} - ${o.NMFILIAL}`
            }
          "
          @input="selectBranch"
          class="field-input"
        >
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!branch"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </vue-select>
      </div>
      <div class="field" v-if="branchHasSections">
        <label for="config-section-field">{{ translateView('Local') }}:</label>
        <vue-select
          :options="sectionOptions"
          :value="section"
          :getOptionLabel="
            o => {
              return `${o.NRLOCALPESQ} - ${o.NMLOCALPESQ}`
            }
          "
          @input="selectSection"
          class="field-input"
        >
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!section"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </vue-select>
      </div>
      <div class="field" v-if="sectionsHasDevices">
        <label for="config-devices-field">{{ translateView('Dispositivos') }}:</label>
        <vue-select
          :options="deviceOptions"
          :value="devices"
          :getOptionLabel="
            o => {
              return `${o.NRDISPOSITIVOMYQ} - ${o.NMDISPOSITIVOMYQ}`
            }
          "
          @input="selectDevice"
          class="field-input"
        >
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!devices"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </vue-select>
      </div>
      <div class="options-container">
        <div class="radio-container" v-if="checkValueDigitalReservation">
          <label class="radio-switch">
            <input type="radio" v-model="selectedOption" value="selfService" @change="handleOptionChange">
            <span class="radio-slider round"></span>
          </label>
          <span class="span">{{ translateView('Autoatendimento') }}</span>
        </div>
        <div class="radio-container" v-if="checkValueDigitalReservation">
          <label class="radio-switch">
            <input type="radio" v-model="selectedOption" value="reservationManagement" @change="handleOptionChange">
            <span class="radio-slider round"></span>
          </label>
          <span class="span">{{ translateView('Gestão de Reservas') }}</span>
        </div>
      </div>
      <div class="options-container">
        <div class="radio-container">
          <label class="radio-switch">
            <input type="radio" v-model="selectedOption" value="menuSearch" @change="handleOptionChange">
            <span class="radio-slider round"></span>
          </label>
          <span class="span">{{ translateView('Pesquisa e Cardápio') }}</span>
        </div>
        <div class="radio-container">
          <label class="radio-switch">
            <input type="radio" v-model="selectedOption" value="customizedHtml" @change="handleOptionChange">
            <span class="radio-slider round"></span>
          </label>
          <span class="span">{{ translateView('Exibir cardápio em TV') }}</span>
        </div>
      </div>        
      <div class="options-container" style="border-top: 1px solid #d3d3d3;">
        <div class="checkbox-container" v-if="customizedHtmlOptionsVisible">
          <label class="switch">
            <input type="checkbox" v-model="dishDetailCheck" @change="changeInfoVisibility">
            <span class="slider round"></span>
          </label>
          <span class="span">{{ translateView('Exibir detalhes do prato') }}</span>
        </div>
        <div class="checkbox-container" v-if="customizedHtmlOptionsVisible">
          <label class="switch">
            <input type="checkbox" v-model="retailProducts" @change="changeRetailVisibility">
            <span class="slider round"></span>
          </label>
          <span class="span">{{ translateView('Exibir produtos de varejo') }}</span>
        </div>
      </div>
      <div class="button">
        <button type="button" @click="onContinueClicked" :disabled="httpRequestInProgess">{{ translateView('Continuar') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import MyQuestBackground from "@/assets/lgbackground.jpg"
import VueSelect from "vue-select"
import "vue-select/dist/vue-select.css"

import util from "@/libs/util"

import Header from "@/components/shared/Header"
import BranchProvider from "@/providers/BranchProvider"
import SectionProvider from "@/providers/SectionProvider"
import DeviceProvider from "@/providers/DeviceProvider"
import CustomizationConfigProvider from "@/providers/CustomizationConfigProvider"
import { mapState, mapActions } from "vuex"
import ServiceProvider from "@/providers/ServiceProvider"

export default {
  name: "config",
  async created() {
    await this.loadBranchData()
    this.loadSectionData(this?.branch?.CDFILIAL ?? null)
    this.loadDeviceData()
  },
  components: {
    "vue-select": VueSelect,
    "myquest-header": Header
  },
  computed: {
    ...mapState({
      branch({ config }) {
        const hasSelectedBranch = config.branchData?.CDFILIAL ?? false
        return hasSelectedBranch ? config.branchData : null
      },
      section({ config }) {
        const hasSelectedSection = config.sectionData?.NRLOCALPESQ ?? false
        return hasSelectedSection ? config.sectionData : null
      },
      devices({ config }) {
        const hasSelectedDevices = config.deviceData?.NRDISPOSITIVOMYQ ?? false
        return hasSelectedDevices ? config.deviceData : null
      },
      userData: state => state.userData
    }),
    branchHasSections() {
      return this.section?.NRLOCALPESQ || this.sectionOptions.length > 0
    },
    sectionsHasDevices() {
      return this.devices?.NRDISPOSITIVOMYQ || this.deviceOptions.length > 0
    },
    isFormValid() {
      const hasBranchSelected = !util.isEmpty(this.branch?.CDFILIAL)
      const hasSectionSelected = !util.isEmpty(this.section?.NRLOCALPESQ)
      const hasDeviceSelected = !util.isEmpty(this.devices?.NRDISPOSITIVOMYQ)
      
      if (this.branchHasSections) {
        if (this.sectionsHasDevices){
          return hasBranchSelected && hasSectionSelected && hasDeviceSelected
        }else{
          return hasBranchSelected && hasSectionSelected
        }
      } else {
        return hasBranchSelected
      }
    }
  },
  data() {
    return {
      MyQuestBackground,
      branchOptions: [],
      sectionOptions: [],
      deviceOptions: [],
      httpRequestInProgess: false,
      checkValueDigitalReservation: false,
      dishDetailCheck: util.getLocalVar('dishDetails'),
      retailProducts: util.getLocalVar('retailProducts'),
      selectedOption: null,
      customizedHtmlOptionsVisible: false
    }
  },
  watch: {
    userData() {
      this.loadBranchData()
    },

    branch() {
      this.clearSection()
      this.sectionOptions = []
      this.loadSectionData()
      this.clearDevice();
      if(this.isFormValid){
        this.loadCustomizationConfig()
      }
    },
    
    section(){
      this.deviceOptions = []
      this.clearDevice()
      this.loadDeviceData()
      if(this.isFormValid){
        this.loadCustomizationConfig()
      }
    },
    devices(){
    }
  },
  activated() {
    this.clearSection()
  },
  methods: {
    ...mapActions([
      "selectBranch",
      "selectSection",
      "selectDevice",
      "selectCustomizationData",
      "clearSection",
      "clearDevice",
    ]),
    async loadBranchData() {
      this.httpRequestInProgess = true
      const { NRORG, CDOPERADOR } = this.userData
      this.branchOptions = await BranchProvider.getBranches({
        NRORG,
        CDOPERADOR
      })
      this.httpRequestInProgess = false
    },
    async loadSectionData() {
      this.httpRequestInProgess = true
      const CDFILIAL = this?.branch?.CDFILIAL ?? null
      const { NRORG } = this.userData
      this.sectionOptions = await SectionProvider.getSections({
        NRORG,
        CDFILIAL
      })
      this.httpRequestInProgess = false
    },
    async loadDeviceData() {
      this.httpRequestInProgess = true;
      try {
        this.deviceOptions = await DeviceProvider.getDevices({
          NRLOCALPESQ: this.section?.NRLOCALPESQ ?? null
        });
      } finally {
        this.httpRequestInProgess = false;
      }
    },
    async loadCustomizationConfig() {
      this.httpRequestInProgess = true
      const { NRORG } = this.userData
      const locationConfig = {
        NRORG,
        CDFILIAL: this.branch.CDFILIAL,
        NRLOCALPESQ: this.section?.NRLOCALPESQ ?? null
      }
      const customizationData = await CustomizationConfigProvider.getCustomizationConfig(
        locationConfig
      )
      util.setLocalVar('digitalReservation', customizationData.idReservaDigital === 'S');
      this.checkValueDigitalReservation = customizationData.idReservaDigital === 'S';
      this.selectedOption = this.fetchDefaultSelectedOption();
      this.selectCustomizationData(customizationData)
      this.httpRequestInProgess = false
    },
    onContinueClicked() {
      if (this.isFormValid) {
        const DADOS = {
          CDFILIAL: this.branch.CDFILIAL,
          NRORG: this.userData.NRORG,
          CDOPERADOR: this.userData.CDOPERADOR,
          NRLOCALPESQ: this.section?.NRLOCALPESQ ?? null,
          // NRDISPOSITIVOMYQ: this.devices?.NRDISPOSITIVOMYQ ?? null
        };
        ServiceProvider.setDadosColetados(DADOS);

        switch (this.selectedOption) {
          case 'customizedHtml':
            this.$router.push("/customizedHtml");
            break;
          case 'menuSearch':
            this.$router.push("/menuSearch");
            break;
          case 'selfService':
            this.$router.push("/selfService");
            break;
          case 'reservationManagement':
            this.$router.push("/reservationManagement");
            break;
          default:
            this.$router.push("/menuSearch");
        }
      } else {
        this.$refs.configForm.reportValidity();
      }
    },
    changeClassAPP() {
      if(this.selectedOption === 'customizedHtml'){
        document.getElementById("app").classList.add("customHtml");
        util.setLocalVar("classApp",true);
      }else{ 
        document.getElementById("app").classList.remove("customHtml");
        util.setLocalVar("classApp", false);
      }
    },
    changeInfoVisibility() {
      if(this.dishDetailCheck){
        document.getElementById("app").classList.add("dishDetails");
        util.setLocalVar("dishDetails",true);
      } else { 
        document.getElementById("app").classList.remove("dishDetails");
        util.setLocalVar("dishDetails", false);
      }      
    },
    changeRetailVisibility() {
      if(this.retailProducts){
        util.setLocalVar("retailProducts", 'S');
      } else { 
        util.setLocalVar("retailProducts", 'N');
      }      
    },
    translateView: function(word) {
      return util.translate(word);
    },
    fetchDefaultSelectedOption() {
      const optionCache = util.getLocalVar('selectedOption');
  
      if (this.checkValueDigitalReservation) {
        return optionCache || 'menuSearch';
      } else {
        return optionCache === 'customizedHtml' ? 'customizedHtml' : 'menuSearch';
      }
    },
    handleOptionChange(event) {
      if (event) {
        this.selectedOption = event.target.value;
      }
      if(this.selectedOption === 'customizedHtml'){
        this.changeClassAPP();
        this.customizedHtmlOptionsVisible = true;
      }else{
        this.changeClassAPP();
        this.customizedHtmlOptionsVisible = false;
      }
      util.setLocalVar('selectedOption',this.selectedOption);
    }    
  }
}
</script>

<style lang="less" scoped>
  .config-root{
    display: flex;
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    flex-flow: column;

    .config-form {
      display: flex;
      flex-flow: column;
      padding: 50px;
      align-content: center;
      justify-content: center;
      width: 500px;
      margin: auto;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .30);
      background: rgba(255, 255, 255,  .9);
      border-radius: 10px;

      .field{
        margin-bottom: 30px;
        text-align: left;
      }

      button{
        width: calc( 100% - 0px);
        height: 30px;
        background: rgba(70, 130, 180, .3);
        border-radius: 5px;
        cursor: pointer;
        border: none;
        background: var(--primary-color);
        color: var(--text-color);
      }
    }

    .options-container {
      display: flex;
      justify-content: space-between;
    }
    
    .checkbox-container {
      text-align: left;
      margin: 10px 0 10px;

      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        vertical-align: middle;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked + .slider {
        background-color: #2196F3;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }

      .span {
        margin-left: 5px;
        vertical-align: middle;
        position: relative;
        display: inline-block;
      }
    }
    
    .radio-container {
      text-align: left;
      margin: 10px 0 10px;
    }
    
    .radio-switch {
      position: relative;
      display: inline-block;
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }
    
    .radio-switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .radio-slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border: 2px solid #ccc;
      border-radius: 50%;
      transition: .4s;
    }
    
    .radio-slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 50%;
      transition: .4s;
    }
    
    input:checked + .radio-slider {
      background-color: #2196F3;
      border-color: #2196F3;
    }
    
    input:checked + .radio-slider:before {
      background-color: #2196F3;
    }


    .span {
      margin-left: 5px;
      vertical-align: middle;
      position: relative;
      display: inline-block;
    }    
  }
</style>
