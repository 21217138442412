import requestModule from '@/libs/requestModule'


export default {

    getServices({NRORG, CDFILIAL, DTCARDAPIO, NRLOCALPESQ, TELAO, EXIBEPROD}){
        return requestModule.postRequest('getServices', {NRORG, CDFILIAL, DTCARDAPIO, NRLOCALPESQ, TELAO, EXIBEPROD}).then(r => {
            return r?.dataset?.servico ?? []
        })
    },
    insertServiceReservation(reservationData){
        return requestModule.postRequest('insertServiceReservation', reservationData) 
    },
    rateService(serviceData){
        return requestModule.postRequest('rateService', serviceData) 
    },
    setDadosColetados(dadosLogin){
        requestModule.postRequest('setDadosColetados', dadosLogin)
    },
    checkUserIdNumber(userData){
        return requestModule.postRequest('checkUserIdNumber', userData)
    },
    checkUserEmail(userData){
        return requestModule.postRequest('checkUserEmail', userData)
    },
    registerNewUser(userData){
        requestModule.postRequest('registerNewUser', userData)
    }

}