import requestModule from "@/libs/requestModule"

export default {
  insertMessage(messageData) {
    return requestModule.postRequest("/insertFaleConoscoMessage", messageData)
  },

  getCSMessages(filterData) {
    return requestModule
      .postRequest("/getFaleConoscoMessages", filterData)
      .then(response => response?.dataset?.mensagemSAC ?? [])
  }
}
