<template>
  <div class="customer-service-message-root">
    <myquest-header>
      <template #left-action>
        <div class="back-button" @click="$emit('close')">
          <i class="fas fa-arrow-left"></i>
        </div>
      </template>
    </myquest-header>
    <h3 class="customer-service-message-subject">{{messageType}}</h3>
    <div class="customer-service-message-body">
      <div class="message user-message">
        <div class="body">
          {{ userMessage }}
        </div>
        <div class="meta">{{ translateView('Data') }}: {{ sentOn }}</div>
      </div>
      <div v-if="messageWasAnswered" class="message customer-service-answer">
        <div class="body">
          {{ answer }}
        </div>
        <div class="meta">{{ translateView('Data') }}: {{ answeredOn }}</div>
      </div>
      <div v-else class="waiting-answer-text">
        {{ translateView('Aguardando Resposta') }}...
      </div>
    </div>
  </div>
</template>

<script>
import MyQuestHeader from "@/components/shared/Header"
import util from "@/libs/util"

export default {
  name: "CustomerServiceMessage",
  components: {
    "myquest-header": MyQuestHeader
  },
  props: {
    answeredOn: {
      type: String
    },
    sentOn: {
      type: String
    },
    userMessage: {
      type: String
    },
    answer: {
      type: String
    },
    subject: {
      type: String
    }
  },
  computed: {
    messageWasAnswered() {
      return this?.answer?.length > 0
    },
    messageType() {
      const messageTypesMap = {
        "ELOGIO": this.translateView("Elogio"),
        "RECLAMACAO": this.translateView("Reclamação"),
        "SUGESTAO": this.translateView("Sugestão"),
        "DUVIDA": this.translateView("Dúvida")
      }

      const messageTypeString = messageTypesMap[this.subject]
      return messageTypeString
    }
  },
  methods: {
    translateView: function(word) {
      return util.translate(word);
    }
  }
}
</script>

<style lang="less" scoped>
.customer-service-message-root {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;

  .customer-service-message-subject{
    margin-bottom: 0;
    margin-top: 30px;
    margin-left: 20px;
  }

  .customer-service-message-body {
    margin: 0 20px;
    padding: 10px;

    .message {
      margin-top: 15px;
      color: black;
      background-color: #ececec;
      padding: 10px;
      border-radius: 5px;


      .body {
        padding: 5px;
        padding-top: 0;
      }
      .meta{
        margin-top: 5px;
      }

      &.user-message {
        margin-left: 50px;
      }

      &.customer-service-answer {
        margin-right: 50px;
      }

      &:first-child{
        margin-top: 0px;
      }
    }

    .waiting-answer-text{
      margin-top: 15px;
      text-align: center;
    }
  }
}
</style>
