<template>
    <div class="range-container">
        <span id="rangeValue">{{ valueRanged }}</span>
        <input 
            class="range" 
            type="range" 
            name="ranged"
            v-model="valueRanged"
            min="0" 
            max="10"
            step="1.0"
            @change="rangeSlide()"
        />
    </div>
</template>

<script>
export default {
  name: "range-text",
  props: {
    layout: {
      type: String
    }
  },
  mounted() {
    this.$emit('changeValueAnswer', 0);
  },
  data() {
    return {
      valueRanged: 0
    }
  },
  methods: {
    rangeSlide: function() {
        this.$emit('changeValueAnswer', this.valueRanged);
    }
  }
}
</script>

<style lang="less" scoped>
.range-container {
    margin: 20vh 0 10vh;
}

#rangeValue {
  position: relative;
  display: block;
  text-align: center;
  font-size: 6em;
  color: #999;
  font-weight: 400;
}

.range {
    width: 480px;
    height: 50px;
    -webkit-appearance: none;
    background: #111;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: inset 0 0 5px #000000;
}
.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: var(--text-color);
    cursor: pointer;
    border: 4px solid var(--text-color);
    box-shadow: -407px 0 0 400px var(--primary-color);
}

</style>