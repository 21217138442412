import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

import reservation from "@/store/reservation"
import config from "@/store/appSettings"
import userData from "@/store/userData"

const storeOptions = {
  modules: { reservation, config, userData }
}

const store = new Vuex.Store(storeOptions)

export default store