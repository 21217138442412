import { render, staticRenderFns } from "./reservationManagement.vue?vue&type=template&id=10f2bf30&scoped=true"
import script from "./reservationManagement.vue?vue&type=script&lang=js"
export * from "./reservationManagement.vue?vue&type=script&lang=js"
import style0 from "./reservationManagement.vue?vue&type=style&index=0&id=10f2bf30&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10f2bf30",
  null
  
)

export default component.exports