import requestModule from '@/libs/requestModule'


export default {
  getReservations(cpf) {
    return requestModule.postRequest('getReservationsById', cpf)
  },
  getReservationData(reservationParams) {
    return requestModule.postRequest('getReservationData', reservationParams);
  },
  reservationCheckout(reservationData) {
    return requestModule.postRequest('reservationCheckout', reservationData)
  }
}