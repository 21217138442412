import util from "@/libs/util"

const initialState = util.getLocalVar("config") ?? {}
const defaultState = {
  branchData: {
    CDFILIAL: null,
    NMFILIAL: null
  },
  sectionData: {
    NRLOCALPESQ: null,
    NMLOCALPESQ: null
  },
  deviceData: {
    NRDISPOSITIVOMYQ: null,
    NMDISPOSITIVOMYQ: null
  },
  customizationData: {
    logoUrl: null,
    primaryColor: null,
    dsNomeExibicao: null
  }
}

export default {
  state: () => ({
    ...defaultState,
    ...initialState
  }),
  mutations: {
    setBranch(state, { CDFILIAL, NMFILIAL }) {
      state.branchData = { CDFILIAL, NMFILIAL }
    },
    setSection(state, { NRLOCALPESQ, NMLOCALPESQ }) {
      state.sectionData = { NRLOCALPESQ, NMLOCALPESQ }
    },
    setDevice(state, { NRDISPOSITIVOMYQ, NMDISPOSITIVOMYQ }) {
      state.deviceData = { NRDISPOSITIVOMYQ, NMDISPOSITIVOMYQ }
    },
    setCustomizationData(state, { logoUrl, primaryColor, dsNomeExibicao }) {
      state.customizationData = { logoUrl, primaryColor, dsNomeExibicao }
    }
  },
  actions: {
    selectBranch({ commit }, selectedBranch) {
      const newBranch = selectedBranch ?? defaultState.branchData
      const { CDFILIAL, NMFILIAL } = newBranch
      util.mixInLocalVar("config", { branchData: { CDFILIAL, NMFILIAL } })
      commit("setBranch", newBranch)
    },
    selectSection({ commit }, selectedSection) {
      const newSection = selectedSection ?? defaultState.sectionData
      const { NRLOCALPESQ, NMLOCALPESQ } = newSection
      util.mixInLocalVar("config", {
        sectionData: { NRLOCALPESQ, NMLOCALPESQ }
      })
      commit("setSection", newSection)
    },
    selectDevice({ commit }, selectedDevice) {
      const newDevice = selectedDevice ?? defaultState.deviceData
      const { NRDISPOSITIVOMYQ, NMDISPOSITIVOMYQ } = newDevice
      util.mixInLocalVar("config", {
        deviceData: { NRDISPOSITIVOMYQ, NMDISPOSITIVOMYQ }
      })
      commit("setDevice", newDevice)
    },
    clearSection({dispatch}){
      dispatch('selectSection', null)
    },
    clearDevice({dispatch}){
      dispatch('selectDevice', null)
    },
    selectCustomizationData({ commit }, newCustomizationData) {
      const customizationData =
        newCustomizationData ?? defaultState.customizationData
      const { logoUrl, primaryColor, dsNomeExibicao } = customizationData
      util.mixInLocalVar("config", {
        customizationData: { logoUrl, primaryColor, dsNomeExibicao }
      })
      commit("setCustomizationData", newCustomizationData)
    }
  },
  getters:{
    textColor({customizationData}){
      const {primaryColor} = customizationData
      const darkTextColor = "rgb(44, 62, 80)"
      const lightTextColor = "white"
      if(primaryColor){
        const commaSeparatedPrimaryColor = primaryColor.replace(/[()]/g, '')
        const [red, green, blue] = commaSeparatedPrimaryColor.split(",")
        // calculate using the YIQ color space:
        const yiq = (((red*299) + (green*587) +(blue*114)) / 1000);
        return yiq >= 128 ? darkTextColor : lightTextColor
      } else {
        return darkTextColor;
      }
    }
  }
}
