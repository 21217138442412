import moment from "moment"
import ServiceProvider from "@/providers/ServiceProvider"
import PlatesProvider from "@/providers/PlatesProvider"

export default {
  getDateWithTimeOffset({
    baseDateObject = moment(),
    HRLIMSEGPEDIDO,
    NRLIMSEGPEDIDO
  }) {
    const hourLimit = HRLIMSEGPEDIDO.slice(0, 2)
    const minuteLimit = HRLIMSEGPEDIDO.slice(2, 4)
    const availableBeginningTime = baseDateObject
      .clone()
      .add(NRLIMSEGPEDIDO, "days")
      .add(hourLimit, "hours")
      .add(minuteLimit, "minutes")
    return availableBeginningTime
  },
  subtractTimeOffset({
    baseDateObject = moment(),
    HRLIMSEGPEDIDO,
    NRLIMSEGPEDIDO
  }) {
    const hourLimit = HRLIMSEGPEDIDO.slice(0, 2)
    const minuteLimit = HRLIMSEGPEDIDO.slice(2, 4)
    const availableBeginningTime = baseDateObject
      .clone()
      .subtract(NRLIMSEGPEDIDO, "days")
      .subtract(hourLimit, "hours")
      .subtract(minuteLimit, "minutes")
    return availableBeginningTime
  },
  isInTimeRange(subject, beginingTimeString, endTimeString) {
    const timeToCompare = moment(subject, "HH:mm:ss")
    const beginingTime = beginingTimeString
      ? moment(beginingTimeString, "HH:mm")
      : moment("00:00:00", "HH:mm:ss")
    const endTime = endTimeString
      ? moment(endTimeString, "HH:mm")
      : moment("23:59:59", "HH:mm:SS")
    if(beginingTime > endTime ) { // service in different days Ex.: 23:30 - 01:00 
        return timeToCompare >= beginingTime || timeToCompare <= endTime;
    }
    return timeToCompare >= beginingTime && timeToCompare <= endTime
  },
  isInReservationPeriod({
    reservationDate,
    reservationTime,
    HRINICIOPREPARO,
    HRINICIO,
    HRFINAL,
    HRLIMSEGPEDIDO,
    NRLIMSEGPEDIDO
  }) {
    const errorMessage = this.getValidReservationDate({
      reservationDate,
      reservationTime,
      HRINICIOPREPARO,
      HRINICIO,
      HRFINAL,
      HRLIMSEGPEDIDO,
      NRLIMSEGPEDIDO
    })

    return errorMessage === null
  },
  getValidReservationDate({
    reservationDate,
    reservationTime,
    HRINICIOPREPARO,
    HRINICIO,
    HRFINAL,
    HRLIMSEGPEDIDO,
    NRLIMSEGPEDIDO
  }) {
    if (HRINICIO !== null || HRFINAL !== null) {
      const isInRange = this.isInTimeRange(reservationTime, HRINICIO, HRFINAL)
      if (!isInRange) {
        return `O horário de atendimento para este serviço é compreendido entre  ${HRINICIO ??
          "00:00"} e ${HRFINAL ??
          "23:59"}. Realize um pedido dentro deste intervalo.`
      }
    }

    const selectedDate = moment(`${reservationDate} ${reservationTime}`)
    const currentTimeWithSecurityLimit = this.getDateWithTimeOffset({
      HRLIMSEGPEDIDO,
      NRLIMSEGPEDIDO
    })

    const hourLimit = HRLIMSEGPEDIDO ? HRLIMSEGPEDIDO.slice(0, 2) + ':' + HRLIMSEGPEDIDO.slice(2, 4) : '0'

    if (HRINICIO === null && currentTimeWithSecurityLimit > selectedDate) {
      const formattedDate = currentTimeWithSecurityLimit.format('DD/MM/YYYY HH:mm')
      return `O pedido deve ser realizado com ${NRLIMSEGPEDIDO ??
        0} dias e ${hourLimit} horas de antecedência.
        É possível realizar pedidos para uma data/hora a partir de: ${formattedDate}.`
    }

    let selectedDateWithServiceBeginingTime = selectedDate.clone()
    if (HRINICIO) {
      const [beginingTimeHour, beginingTimeMinute] = HRINICIO.split(":")
      selectedDateWithServiceBeginingTime = selectedDate.clone().set({
        minute: beginingTimeMinute,
        hour: beginingTimeHour
      })
    }
    
  

    const selectedDateWithNegativeOffset = this.subtractTimeOffset({
      baseDateObject: selectedDateWithServiceBeginingTime,
      NRLIMSEGPEDIDO,
      HRLIMSEGPEDIDO
    })
    
    if(HRINICIOPREPARO > HRINICIO){
        selectedDateWithNegativeOffset.add(1, 'days');
    }

    if (selectedDateWithNegativeOffset < moment()) {
      return `Horário limite para pedido deste serviço expirou! O pedido deve ser realizado com ${NRLIMSEGPEDIDO ??
        0} 
      dias e ${hourLimit} horas de antecedência ${(HRINICIO &&
        "ao início deste serviço!") ||
        ""}`
    }

    return null
  },
  reserveService(serviceData, reservationData) {
    const isInReservationPeriod = this.isInReservationPeriod({
      ...serviceData,
      ...reservationData
    })
    if (isInReservationPeriod) {
      const { reservationDate, reservationTime } = reservationData
      const selectedDate = moment(`${reservationDate} ${reservationTime}`)
      const reservationDetails = {
        NRORG: serviceData.NRORG,
        CDFILIAL: serviceData.CDFILIAL,
        NRLOCALPESQ: serviceData.NRLOCALPESQ,
        CDSERVFILI: serviceData.CDSERVFILI,
        NRCARDAPIO: serviceData.NRCARDAPIO,
        DTCARDAPIO: selectedDate.format("DD/MM/YYYY"),
        NMUSUARIO: reservationData.name,
        DSCONSUMO: reservationData.department,
        CDREGISTROPESSOA: reservationData.cpf,
        NRUSUARIOAPP: reservationData.nrUsuarioApp,
        DSEMAILUSUARIOAPP: reservationData.email,
        HRCONSUMO: selectedDate.format("HHmm"),
        NMSERVICO: serviceData.NMSERVICO
      }
      return ServiceProvider.insertServiceReservation(reservationDetails)
    } else {
      const reservationPeriodError = this.getValidReservationDate({
        ...serviceData,
        ...reservationData
      })

      return Promise.reject(reservationPeriodError)
    }
  },

  reservePlates(serviceData, reservationData, platesToReserve) {
    const isInReservationPeriod = this.isInReservationPeriod({
      ...serviceData,
      ...reservationData
    })
    if (isInReservationPeriod) {
      const { reservationDate, reservationTime } = reservationData
      const selectedDate = moment(`${reservationDate} ${reservationTime}`)
      const reservationDetails = {
        NRORG: serviceData.NRORG,
        CDFILIAL: serviceData.CDFILIAL,
        NRLOCALPESQ: serviceData.NRLOCALPESQ,
        CDSERVFILI: serviceData.CDSERVFILI,
        NRCARDAPIO: serviceData.NRCARDAPIO,
        DTCARDAPIO: selectedDate.format("DD/MM/YYYY"),
        NMUSUARIO: reservationData.name,
        DSCONSUMO: reservationData.department,
        HRCONSUMO: selectedDate.format("HHmm"),
        NRUSUARIOAPP: reservationData.nrUsuarioApp,
        CDREGISTROPESSOA: reservationData.cpf,
        plates: platesToReserve
      }
      return PlatesProvider.reservePlates(reservationDetails)
    } else {
      const reservationPeriodError = this.getValidReservationDate({
        ...serviceData,
        ...reservationData
      })

      return Promise.reject(reservationPeriodError)
    }
  },
  
  async checkUserIdNumber(userId) {
    try {
      const sendObject = {
        CPF: userId
      };
      const userIdCheck = await ServiceProvider.checkUserIdNumber(sendObject);
      return userIdCheck;
    } catch (error) {
      console.error('Erro:', error);
      throw error; 
    }
  },
  
  registerNewUser(userData) {
    try {
      const sendObject = {
        DSEMAIL: userData.DSEMAIL,
        ULTIMONOME: userData.DSULTIMONOME,
        CPF: userData.CPF,
        DSSETOR:userData.DSSETOR,
        PRIMEIRONOME: userData.DSPRIMEIRONOME,
        NRUSUARIOAPP: userData.NRUSUARIOAPP,
        NRORG: userData.NRORG,
        newUser: userData.newUser
      };

      ServiceProvider.registerNewUser(sendObject);
    } catch (error) {
      console.error('Erro:', error);
      throw error;
    }
  },

  async checkUserEmail(userEmail) {
    try {
      const sendObject = {
        DSEMAIL: userEmail
      };
      const userEmailCheck = await ServiceProvider.checkUserEmail(sendObject);
      return userEmailCheck;
    } catch (error) {
      console.error('Erro:', error);
      throw error; 
    }
  },

  //TODO:
  //show message after rate.
  rateService(rateObject) {
    ServiceProvider.rateService(rateObject)
  },

  ratePlate(ratingData) {
    return PlatesProvider.ratePlate(ratingData)
  }
}
