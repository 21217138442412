import SelfServiceProvider from "@/providers/SelfServiceProvider"


export default {
  getReservations(cpf) {
    return SelfServiceProvider.getReservations(cpf);
  },
  getReservationData(reservationParams) {
    return SelfServiceProvider.getReservationData(reservationParams);
  },
  reservationCheckout(reservationData) {
    return SelfServiceProvider.reservationCheckout(reservationData);
  }
}