import Vue from "vue"
import VueRouter from "vue-router"

import util from "@/libs/util"
import Login from "@/views/login"
import Config from "@/views/config"
import Home from "@/views/home"
import Services from "@/views/services"
import ServicesDetail from "@/views/servicesDetail"
import DatePickerView from "@/views/DatePickerView"
import Plates from "@/views/plates"
import CustomerServiceView from "@/views/CustomerServiceView"
import CustomizedHTML from "@/views/CustomizedHTML"
import SelfService from "@/views/selfService"
import ReservationManagement from "@/views/reservationManagement"

Vue.use(VueRouter)

// eslint-disable-next-line no-unused-vars
function mapParamsToProps(route) {
  const props = route.params ?? {}
  return { ...props }
}

const routes = [
  { path: "/", redirect: "/menuSearch" },
  { path: "*", redirect: "/menuSearch" },
  { path: "/menuSearch", component: Home },
  { path: "/login", component: Login },
  { path: "/config", component: Config },
  { path: "/datePicker", name: "datePickerView", component: DatePickerView },
  { path: "/services", name: "serviceListView", component: Services },
  { path: "/servicesDetail", name: 'service-detail', component: ServicesDetail },
  { path: "/plates", name: 'plates', component: Plates },
  { path: "/sac", component: CustomerServiceView },
  { path: "/customizedHtml", name: 'customizedHtml', component: CustomizedHTML },
  { path: "/selfService", name: 'selfService', component: SelfService },
  { path: "/reservationManagement", name: 'reservationManagement', component: ReservationManagement },
]

const loginGuard = (to, _from, next) => {
  const publicRoutes = ["/login"]
  let authToken = util.getLocalVar("myquest_token")
  if (!/.+\..+\..+/.test(authToken)) {
    authToken = ""
  }

  const isTokenValid = typeof authToken === "string" && authToken.length > 0
  if (publicRoutes.includes(to.path) || isTokenValid) {
    const isLogged = isTokenValid
    const hasConfig = !util.isEmpty(util.getLocalVar("config"))
    if (isLogged && !hasConfig && to.path !== "/config") {
      next("/config")
    } else {
      next()
    }
  } else {
    localStorage.removeItem("myquest_token")
    localStorage.removeItem("userData")
    next("/login")
  }
}

function createRouter() {
  const router = new VueRouter({
    mode: "history",
    routes
  })
  router.beforeEach(loginGuard)
  return router
}

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  router.options.routes = [...routes]
}

export default router
