<template>
    <div class="icons-container" >
      <span v-if="layout === 'EMOJI'">
        <img v-for="(icon, key) in icons" 
            :key="key" 
            :src="icon.url" 
            :name="icon.name"
            class="icon" 
            :class="{'active': icon.active == true}" 
            @click="onIconClicked(icon, icons)" 
        />
      </span>
      <span v-else class="star-container">
        <div v-for="(icon, key) in icons"
          :key="key"
          @click="onRating(icon, icons)"
          class="star"
          :class="{'active': icon.active == true}"
        >
          <i  
            :name="icon.name"
            class="fas fa-star" 
          />
        </div>
      </span>
    </div>
</template>

<script>
export default {
  name: "icons-text",
  props: {
    layout: {
      type: String
    }
  },
  data() {
    return {
      icons: [
          {
            name: 'first-icon',
            url: require('../../../assets/img/icons/sad.png'),
            value: 1,
            active: false
          },
          {
            name: 'second-icon',
            url: require('../../../assets/img/icons/verysad.png'),
            value: 2,
            active: false
          },
          {
            name: 'third-icon',
            url: require('../../../assets/img/icons/neutral.png'),
            value: 3,
            active: false
          },
          {
            name: 'fourth-icon',
            url: require('../../../assets/img/icons/happy.png'),
            value: 4,
            active: false
          },
          {
            name: 'fifth-icon',
            url: require('../../../assets/img/icons/veryhappy.png'),
            value: 5,
            active: false
          }
      ]  
    }
  },
  mounted() {
    this.$emit('changeValueAnswer', 0);
  },
  methods: {
    onIconClicked: function(icon, icons) {
      icons.forEach(arrIcon => {
        arrIcon.active = false;
      });
      icon.active = true;

      this.$emit('changeValueAnswer', icon.value);
    },
    onRating: function(icon, icons) {
      icons.forEach((arrIcon, key) => {
        arrIcon.active = false;
        if (key < icon.value) {
          arrIcon.active = true;
        }
      });
      
      this.$emit('changeValueAnswer', icon.value);
    }
  }
}
</script>

<style lang="less" scoped>
.icons-container {
    margin: 20vh 0 10vh;

    .icon {
        opacity: 0.5;
        height: 115px;
        
        &.active {
            opacity: 1;
            transform: rotate(15deg);
        }
    }

    .star {
      font-size: 7.188rem;
      display: inline-block;

      &.active {
        color: #fcba03;
      }
    }
}

</style>