import requestModule from '@/libs/requestModule'


export default {
  //mudat nome das func para management
  getReservations(params) {
    return requestModule.postRequest('getManagementReservationsById', params)
  },
  getReservationData(reservationParams) {
    return requestModule.postRequest('getManagementReservationData', reservationParams);
  },
  reservationCheckout(reservationData) {
    return requestModule.postRequest('managementReservationCheckout', reservationData)
  }
}