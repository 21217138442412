import requestModule from "@/libs/requestModule"

function getFilterRquestFromObject(data = {}){
  return Object.entries(data).map(([key, val]) => ({
    name: key,
    operator: "=",
    value: val
  }))
}
function getHomeScreenConfig(homeScreenConfigFilterData) {
  const requestData = getFilterRquestFromObject(homeScreenConfigFilterData)

  return requestModule
    .postRequest(
      "/crud/home_screen_config",
      requestData,
      "FilterData",
      "filter"
    )
    .then(response => {
      return response?.dataset?.home_screen_config ?? {}
    })
}

export {
  getHomeScreenConfig
}
