<template>
  <div class="rating-component">
    <div class="ratable">
        <!--<div v-if="ratingSettings.onlyRead === true" class="last-rating-stars">
            <span v-for="key in Object.keys(stars)" :key="key" :class="{'selected':Math.round(ratingSettings.readOnlyRate) >= key}" class="star"><i class="fas fa-star"/></span>
        </div>-->
        <div v-if="ratingSettings.onlyRead !== true" class="rating-stars">
            <span 
            v-for="key in Object.keys(stars)" :key="key"
            @mouseover="ratingClassHover(key)" @mouseleave="clearRatingClass('hover')" @click="addRating(key)"
            :class="stars[key]" class="star"><i class="fas fa-star"/></span>
        </div>
        <div v-if="ratingSettings.onlyRead !== true" class="field">
            <button type="submit" @click="rate()">{{ translateView('Confirmar') }}</button>
        </div>
    </div>
    <div class="last-ratings">
        <div class="title">{{ translateView('Últimas Opiniões') }}</div>
        <div class="ratings">
            <div class="past-rating" v-for="comment in comments" :key="comment[ratingSettings.rating.rateId]">
                <span class="rating-desc">
                    {{comment[ratingSettings.rating.commentDescField]}}
                </span>
                <div class="last-rating-stars">
                    <span v-for="key in Object.keys(stars)" :key="key" :class="{'selected':comment[ratingSettings.rating.rateValueField] >= key}" class="star"><i class="fas fa-star"/></span>
                </div>
                <span class="rating-user">
                    {{comment[ratingSettings.rating.userNameField]}}
                </span>
            </div>
        </div>
    </div>
    <modal
      name="reservation-success-message"
      :height="'100%'"
      :width="'100%'"
      :transition="'none'"
    >
      <div style="font-size: 40px; padding-top: 25%; text-align:center">
        {{ translateView('Avaliação salva com sucesso') }}.
      </div>
    </modal>
  </div>
</template>

<script>
/**
 * Rating component use ratingSettings prop
 * to get parameters from the correct origin.
 * Example of ratingSettings object:
 * 
 * ratingSettings:{
 *   filter: [
 *     {name: "CDFILIAL", value: userData?.CDFILIAL, operator: '='}      //DataSourceFilter
 *   ],
 *   dataSourceUrl: '/crud/avaliacao_servico',  //DataSource URL
 *   dataSourceName: 'avaliacao_servico'        //DataSource Name
 *   rating: {
 *       rateValueField: VRAVALIACAOSERVICO
 *       commentDescField: DSAVALIACAOSERVICO
 *       rateId: NRAVALIACAOSERVICO
 *   }
 * }
 *
 * You can either define a responseCallBack property, as a function,
 * to treat information correctly.
 */

import requestModule from "@/libs/requestModule"
import util from "@/libs/util"

export default {
    name: "rating-component",

    /**
     * Component props include only ratingSettings,
     * described above.
     * @type {Object}
     */
    props: {
        ratingSettings:{
            type: Object,
            default: () => Object()
        }
    },

    /**
     * When activated, define a callback to treat past comments;
     * a function to handle when user rate a plate, and make
     * the request to get the comments data.
     */
    created() {
        if(this.ratingSettings.responseCallback){
            this.responseCallback = this.ratingSettings.responseCallback;
        } else {
            this.responseCallback = response => {
                this.comments = response.dataset[this.ratingSettings.dataSourceName];
            }
        }

        if(this.ratingSettings.onRate){
            this.onRate = this.ratingSettings.onRate;
        }

        requestModule.postRequest(this.ratingSettings.dataSourceUrl, this.ratingSettings.filter, "FilterData", "filter").then(this.responseCallback);
    },


    /**
     * @var {Object} stars - handle which stars are selected, hovered or clean.
     * @var {Array} comments - store past comments.
     * @var {Function} responseCallback - callback to handle comment data.
     * @var {Function} onRate - Function to handle when rate star is clicked.
     * 
     * @return {Object} Vue data
     */
    data() {
        return {
            isButtonDisabled: true,
            stars: {'1':[], '2':[], '3':[], '4':[], '5':[]},
            comments: [],
            responseCallback: function(){},
            onRate: function(){},
            rating: 0
        }
    },

    watch: {
        ratingSettings: {
            handler(){
                this.loadRating()
            },
            deep: true     
        }   
    },
    /**
     * Vue methods
     * @type {Object}
     */
    methods: {
        /**
         * On hover, push 'hover' class on stars to
         * style all stars before, and the hovered star.
         * 
         * @param  {Number} starCount show which star is hovered.
         */
        ratingClassHover(starCount){
            for (var i = 1; i <= starCount; i++) {
                if(this.stars[i].indexOf('hover') === -1){
                    this.stars[i].push('hover');
                }
            }
        },

        /**
         * Clear a class in all stars.
         * 
         * @param  {String} className class to remove from stars.
         */
        clearRatingClass(className){
            for(let str in this.stars){
                let hoverIdx = this.stars[str].indexOf(className);
                if(hoverIdx !== -1){
                    this.stars[str].splice(hoverIdx,1);
                }
            }
        },

        /**
         * Push 'selected' class to stars.
         * 
         * @param  {Number} starCount show which star show be
         *                            changed.
         */
        ratingClassSelected(starCount){
            this.clearRatingClass('selected');

            for (var i = 1; i <= starCount; i++) {
                if(this.stars[i].indexOf('selected') === -1){
                    this.stars[i].push('selected');
                }
            }
        },

        /**
         * Put 'selected' class on stars and call
         * 'onRate' from parent component.
         * 
         * @param  {Number} starCount How many stars are rated.
         */
        rate(){
            if (this.rating !== 0 && this.isButtonDisabled == false) {
            //  this.ratingClassSelected(this.rating);
                this.onRate(this.rating).then(() => {
                    this.loadRating();
                    this.$modal.show("reservation-success-message");
                    setTimeout(() => this.$modal.hide("reservation-success-message"), 3000);
                    this.clearRatingClass('selected');
                    this.clearRatingClass('hover');
                    this.rating = 0
                    this.$forceUpdate();
                    this.enableButtonConfirm(false);
                });
                
                this.isButtonDisabled = true;
            }
        },

        loadRating(){
            requestModule.postRequest(this.ratingSettings.dataSourceUrl, this.ratingSettings.filter, "FilterData", "filter").then((data) => {
                this.responseCallback(data);
            });
        },
        
        enableButtonConfirm(enable){
            const styleButtonConfirmar = document.querySelector('.ratable');
            
            if(enable == false){
                styleButtonConfirmar.style.setProperty('--color-button-confirm', '#F0F0F0');
                styleButtonConfirmar.style.setProperty('--cursor', 'default');   
            }
            
            if(enable == true){
                styleButtonConfirmar.style.setProperty('--color-button-confirm', 'gray');
                styleButtonConfirmar.style.setProperty('--cursor', 'pointer');
                
            }
        },

        addRating(key) {
            if(this.rating == key){
                this.isButtonDisabled = true;
                this.clearRatingClass('selected');
                this.rating = 0;
                this.enableButtonConfirm(false);
                return;
            }
            this.isButtonDisabled = false;
            this.rating = key;
            this.ratingClassSelected(this.rating);
            this.enableButtonConfirm(true);
        },

        translateView: function(word) {
            return util.translate(word);
        }
    }
}
</script>

<style scoped lang="less">
    .ratable .last-rating-stars{
       /* position: relative;
        display: block;
        margin: auto;
        margin-bottom: 15px;
        text-align: left;
        font-size: 2rem;*/
        display: flex;
        margin: 15px 0;
        font-size: 2.45rem;
    }
    .ratable {
        --color-button-confirm: #F0F0F0;
        --cursor: default ;
        display: flex;
        .field {
            margin: 20px;
            // width: calc(100% - 40px);
            button {
                //width: calc(100% - 0px);
                height: 50px;
                width: 150px;
                background: var(--color-button-confirm);
                border-radius: 5px;
                color: white;
                cursor: var(--cursor);
                border: none;
                font-size: 1.5rem;
            }
        }
    }

    .rating-component{
        //padding: 20px;
        max-height: 55vh;

        .rating-stars{
            display: flex;
            margin: 15px 0;
            font-size: 2.45rem;
        }
        .star {
            margin: auto;
        }
        .star.hover{
            svg.fa-star{
                color: #966f02;
            }
        }

        .star.selected{
            svg.fa-star{
                color: #fcba03;
            }
        }

        .last-ratings{
            border: 1px solid rgba(0,0,0,0.3);
            border-radius: 5px;
            margin: 5px 0;
            max-height: 40vh;
            overflow: hidden;

            .title{
                text-align: left;
                padding: 10px 20px;
                font-size: 1.4rem;
                font-weight: bold;
                box-shadow: 1px -5px 14px 6px lightgrey;
            }

            .ratings {
                overflow: scroll;
                max-height: calc( 40vh - 50px );
                overflow-x: hidden;
                font-size: 1.3rem;
                .past-rating{
                    margin: 20px;
                    display: grid;
                    grid-template: 
                        "header header"
                        "footer-left footer-right"
                        / 90px calc(100% - 60px);
                    border-bottom: 1px solid rgba(0,0,0,.2);
                    padding-bottom: 15px;
                    & > *{
                        justify-self: start;
                    }

                    .rating-desc{
                        grid-area: header;
                    }

                    .last-rating-stars{
                        grid-area: footer-left;
                    }

                    .rating-user{
                        grid-area: footer-right;
                        text-align: left;
                        position: relative;
                        display: table;
                        justify-self: center;
                        width: 100%;
                        margin: auto;
                        padding-left: 15px;
                    }
                    &:last-of-type{
                        border: none;
                    }
                }

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1; 
                  border-radius: 10px;
                }
                 
                &::-webkit-scrollbar-thumb {
                  background: #888; 
                  border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #555; 
                }
            }
        }
    }
</style>