<template>
    <div class="informative-container">
        <div v-for="(info, key) in informative" :key="key" class="informative"> 
            <span class="informative-title">{{ info.DSTITULO }}</span>
            <div class="image-container">
                <img 
                    class="informative-image"
                    :src="info.URLIMAGEM" 
                    :name="info.DSTITULO"
                />
                <div v-html="info.TXCONTEUDO"></div>
            </div>
        </div>
    </div>
</template>

<script>
import InformativeProvider from "@/providers/InformativeProvider"

import { mapState } from "vuex"

export default {
    name: "informative",
    created() {
        document.addEventListener('click', this.resetTimeout, {passive: true});
        document.addEventListener('touchmove', this.resetTimeout, {passive: true});
        document.addEventListener('touchstart', this.resetTimeout, {passive: true});
        document.addEventListener('keypress', this.resetTimeout, {passive: true});
        document.addEventListener('keydown', this.resetTimeout, {passive: true});
        document.addEventListener('keyup', this.resetTimeout, {passive: true});
    },
    mounted() {
        this.loadInformativeData();
    },
    data() {
        return {
            informative: [],
            countInterval: 0,
            slider: false,
            indexInfo: 0
        }
    },
    computed: {
        ...mapState({
            appConfig: state => state.config,
            organizationCode: ({ userData }) => userData.NRORG,
            branchCode: ({ config }) => config.branchData.CDFILIAL,
            sectionCode: ({ config }) => config.sectionData.NRLOCALPESQ ?? null
        })
    },
    methods: {
        async loadInformativeData() {
            this.informative = await InformativeProvider.getInforData({
                NRORG: this.organizationCode,
                CDFILIAL: this.branchCode,
                NRLOCALPESQ: this.sectionCode
            });
            this.startSlider();
        },
        startSlider() {
            clearInterval(this.slider);
            let [ $sliderContainer ] = document.getElementsByClassName('informative-container');
            if ($sliderContainer) {
                $sliderContainer.addEventListener('scroll', this.scrollListener);
                this.slider = setInterval(() => {
                    let [ $first_informative ] = document.getElementsByClassName('informative');
                    if (this.indexInfo >= this.informative.length) {
                        $sliderContainer.scrollLeft = 0;
                        this.indexInfo = 0;
                    } else {
                        if($first_informative){
                            $sliderContainer.scrollLeft = this.indexInfo * $first_informative.offsetWidth;
                        }
                    }
                    if($first_informative){
                        $first_informative.scrollTop = 0;
                    }
                    this.indexInfo++;
                }, 10000);
            }
        },
        resetTimeout() {
            if(this.informative.length > 0) this.startSlider();
            else clearInterval(this.slider);
        },
        getIndexCurrentInformative(){
            const [ $informative_container ] = document.getElementsByClassName('informative-container');
            const $informatives = $informative_container.children;
            const index_current_info = Array.from($informatives).findIndex( $informative => {
                return $informative_container.getBoundingClientRect().left - $informative.getBoundingClientRect().left == 0;
            });
            return index_current_info;
        },
        scrollListener(e) {
           if (e.target.scrollLeft <= 0) this.indexInfo = 0;
           else this.indexInfo = this.getIndexCurrentInformative();
        }
    }
}
</script>

<style lang="less" scoped>
.informative-container {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    flex-flow: unset!important;

    .image-container {
        white-space: normal;
    }
    .informative {
        scroll-snap-align: start;
        position: relative;
        min-width: calc(100vw - 60px);
        // height: 100vh;
        overflow: auto;
    }

    .informative-image {
        max-width: calc(100vw - 60px);
    }

    .informative-title {
        font-size: 3rem;
        position: sticky;
        background: white;
        top: 0;
    }
}

</style>