<template>
  <div class="background-wrapper">
    <div class="header" 
        @mousedown="start"
        @mouseleave="stop"
        @mouseup="stop"
        @touchstart="start"
        @touchend="stop"
        @touchcancel="stop">
      <div class="logo"><img  v-if="this.logoUrl" :src="this.logoUrl" alt='Logo' /></div>
      <div class="unit-name">{{ nmRestaurante }}</div>
    </div>
    <div class="carousel-wrapper">
      <carousel :autoplay="true" :autoplay-timeout="10000" :perPage="1" :loop="true" :paginationEnabled="false" :autoplayHoverPause="false" :mouse-drag="false" class="full-carousel">
        <slide v-for="(item, index) in items" :key="index" class="full-slide">
          <template v-if="item.tipo === 'servico'">
            <ul class="prato-list">
              <li v-for="(prato, pratoIndex) in item.servico.pratos" :key="prato.id" class="prato-item">
                <div class="prato-content">
                  <template v-if="pratoIndex === 0 || prato.NMTIPOPRAT !== item.servico.pratos[pratoIndex - 1].NMTIPOPRAT">
                    <span class="prato-type">— {{ prato.NMTIPOPRAT }}</span>
                  </template>
                  <div class="prato-info-detailed" :class="{ 'prato-info': !dishDetails }">
                    <div class="prato-image-wrapper">
                      <img :src="prato.URL_FOTOPRATO ? prato.URL_FOTOPRATO : require('@/assets/img/icons/no_plate1.png')" alt="Imagem do prato" class="prato-image">
                    </div>
                      <div class="prato-details">
                        <p class="prato-name">{{ prato.NMPRATO }}</p>
                        <span v-if="dishDetails" class="description-info">{{ prato.DSPRATO }}</span>
                        <p v-if="dishDetails && prato.CALORIAS_KCAL_ROUND" class="prato-details-text">
                          <span v-if="dishDetails && prato.CDSERVFILI === null" class="monetaryUnit">{{ getCurrencySymbol() }}</span> 
                          {{ prato.CALORIAS_KCAL_ROUND }}
                          <span v-if="dishDetails && (prato.IDGLUTEN === 'S' || prato.IDLACTOSE === 'S')" class="divider"> - </span>
                          <span v-if="dishDetails && prato.IDGLUTEN === 'S'" class="gluten-info">{{ translateView(getGlutenInfo(prato.IDGLUTEN)) }}</span>
                          <span v-if="dishDetails && prato.IDLACTOSE === 'S'" class="lactose-info">{{ translateView(getLactoseInfo(prato.IDLACTOSE)) }}</span>
                        </p>
                      </div>
                  </div>
                </div>  
              </li>
            </ul>
          </template>
          <template v-else-if="item.tipo === 'informativo'">
            <div class="informative-container">
              <img :src="item.informativo.URLIMAGEM" :alt="item.informativo.DSTITULO" class="informative-image">
            </div>
          </template>
        </slide>
      </carousel>
    </div>
    <div class="watermark"></div>
    <footer class="footer">
      <p class="date">{{ currentDate }}</p>
      <p class="time">{{ currentTime }}</p>      
    </footer>    
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import util from "@/libs/util"
import ServiceProvider from "@/providers/ServiceProvider"
import InformativeProvider from "@/providers/InformativeProvider"
import { mapState } from "vuex"
import moment from "moment"
import { startHeartbeat, stopHeartbeat } from  "@/libs/heartbeat"

export default {
  name: 'customizedHtml',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      nmRestaurante: '',
      servicos: [],
      informativos: [],
      items: [],
      itensPerPage: 0, // Quantidade de pratos por slide,
      logoUrl: null,
      currentDate: '',
      currentTime: '',
      intervalLogin: false,
      cache: {
        dishDetails: util.getLocalVar("dishDetails")
      }
    };
  },
  computed: {
    dishDetails() {
      return this.cache.dishDetails;
    },
    ...mapState({
        selectedDate: ({ reservation }) => reservation.selectedDate,
        selectedCDFILIAL: ({ config }) => config.branchData.CDFILIAL,
        selectedSection: ({ config }) => config.sectionData,
        selectedDevice: ({ config }) => config.deviceData,
        userNRORG: ({ userData }) => userData.NRORG,
        fileserverCustomerLogoUrl({config}) {
            const {logoUrl} = config.customizationData
            if (!util.isEmpty(logoUrl)) {
                return `${util.getLocalVar('fileServiceFilesPath')?.url}/${logoUrl}`
            } else {
                return null
            }
        },
        nomeRestaurante({config}){
          const {dsNomeExibicao} = config.customizationData
          if(!util.isEmpty(dsNomeExibicao)){
            return dsNomeExibicao
          }else{
            return config.sectionData.NMLOCALPESQ
          }
        }
    })
  },
  activated(){
    this.updateHeartBeat();
  },
  deactivated(){
    stopHeartbeat();
  },
  mounted(){
    this.updateHeartBeat();
    // Chama a função para buscar os dados inicialmente
    this.fetchData();
    // Define um intervalo para chamar a função fetchData a cada hora
    setInterval(this.fetchData, 600000); // a cada 10 minutos atualiza a tela
    this.updateCurrentDate();
    setInterval(this.updateCurrentDate, 1000);
    this.logoUrl = this.fileserverCustomerLogoUrl;
    this.nmRestaurante = this.nomeRestaurante;
    
    switch (screen.width) {
      case 768:
        this.itensPerPage = 8
        break;
      case 1080:
        this.itensPerPage = 20
        break;
      case 1366:
        this.itensPerPage = 12
        break;
      case 1920:
        this.itensPerPage = 17
        break;
      default:
        this.itensPerPage = 10
    }
  },
  methods: {
    updateHeartBeat(){
      if(this.selectedDevice.NRDISPOSITIVOMYQ) startHeartbeat(this.translateView('Exibir cardápio em TV'), this.selectedDevice.NRDISPOSITIVOMYQ);
    },    
    async fetchData() {
      try {
        // Realiza a requisição para buscar os serviços/pratos e informativos
        const servicosResponse =  await this.loadServices();
        const informativosResponse =  await this.loadInformativeData();
        this.servicos = await this.formatarServicos(servicosResponse);
        this.informativos = informativosResponse;
        this.combineItems();
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },
    loadServices() {
      return ServiceProvider.getServices({
          NRORG: this.userNRORG,
          CDFILIAL: this.selectedCDFILIAL,
          DTCARDAPIO: moment().format('DD/MM/YYYY'),
          NRLOCALPESQ: this.selectedSection.NRLOCALPESQ,
          TELAO: 'S',
          EXIBEPROD: util.getLocalVar('retailProducts')
      });
    },
    loadInformativeData() {
      return InformativeProvider.getInforData({
          NRORG: this.userNRORG,
          CDFILIAL: this.selectedCDFILIAL,
          NRLOCALPESQ: this.selectedSection.NRLOCALPESQ
      });
    },
    async formatarServicos(servicos) {
      // Filtra os serviços diferentes pelo código do serviço
      const servicosDiferentes = servicos.reduce((acumulador, servico) => {
        // Verifica se o serviço já existe no array de serviços únicos
        const servicoExistente = acumulador.find(s => s.CDSERVFILI === servico.CDSERVFILI);
        if (!servicoExistente) {
          acumulador.push({ CDSERVFILI: servico.CDSERVFILI, NMSERVICO: servico.NMSERVICO });
        }
        return acumulador;
      }, []);
      // Adiciona os pratos a cada serviço diferente
      const fileServerurl = util.getLocalVar('fileServiceFilesPath')?.url;
      servicosDiferentes.forEach(servico => {
        servico.pratos = servicos.filter(s => s.CDSERVFILI === servico.CDSERVFILI)
        .map(s => ({ CDSERVFILI: s.CDSERVFILI, NMTIPOPRAT: s.AGRUPADOR, NMPRATO: s.NOME, DSPRATO: s.DESCRICAO, IDGLUTEN: s.IDGLUTEN, IDLACTOSE: s.IDLACTOSE, CALORIAS_KCAL_ROUND: s.VALOR, URL_FOTOPRATO: s.URL_FOTO ? `${fileServerurl}/${s.URL_FOTO}`: null}));
      });
      return servicosDiferentes;
    },   
    combineItems() {
      const combinedItems = [];
      for (let i = 0; i < Math.max(this.servicos.length, this.informativos.length); i++) {
        if (this.servicos[i]) {
          const pratos = this.servicos[i].pratos;
          const totalPages = Math.ceil(pratos.length / this.itensPerPage);
          for (let j = 0; j < totalPages; j++) {
            const startIndex = j * this.itensPerPage;
            const endIndex = Math.min(startIndex + this.itensPerPage, pratos.length);
            const pratosDaPagina = pratos.slice(startIndex, endIndex);
            combinedItems.push({
              tipo: 'servico',
              servico: {
                ...this.servicos[i],
                pratos: pratosDaPagina
              }
            });
          }
        }
          if (this.informativos[i]) {
            combinedItems.push({ tipo: 'informativo', informativo: this.informativos[i] });
          }
      }
      this.items = combinedItems;
    },
    updateCurrentDate() {
      const date = new Date();
      const optionsDate = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
      const optionsTime = { hour: '2-digit', minute: '2-digit' };
      this.currentDate = date.toLocaleDateString(util.getLocalVar('lang'), optionsDate);
      this.currentTime = date.toLocaleTimeString(util.getLocalVar('lang'), optionsTime);
    },
    getGlutenInfo(idGluten) {
      return idGluten === 'S' ? 'Gluten' : false;
    },
    getLactoseInfo(idGluten) {
      return idGluten === 'S' ? 'Lactose' : false;
    },
    getCurrencySymbol() {
      const language = util.getLocalVar('lang');
      switch (language) {
        case 'pt-br':
          return 'R$';
        case 'en-us':
          return '$';
        case 'es-ch':
          return 'CLP';
        default:
          return 'R$';
      }
    },
    goBack() {
      this.stop();
      this.$router.back();
    },
    start() {
      if (!this.intervalLogin) {
        this.intervalLogin = setInterval(() => {
          this.stop();
          this.$router.push("/login");
          this.$modal.hideAll();
        }, 5000)
      }
    },
    stop() {
      clearInterval(this.intervalLogin)
      this.intervalLogin = false
    },
    translateView: function(word) {
      return util.translate(word);
    }
  }
};
</script>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.background-wrapper {
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 2px solid white;
}

.logo {
  display: block;
  position: relative;
  cursor: pointer;
}

.logo img {
  position: relative;
  display: block;
  height: 50px;
  padding: 10px;
}

.unit-name {
  font-size: 3.8rem;
  text-align: center;
  flex-grow: 1;
  font-weight: 400;
  opacity: 1 !important;
  color: #2C3E50;
  font-family: 'Roboto Slab', serif;
  word-break: break-word;
  overflow-wrap: break-word;
}

.carousel-wrapper {
  height: 88vh;
  overflow: hidden;
}

.full-carousel, .VueCarousel full-carousel, .VueCarousel-wrapper, .full-slide, .VueCarousel-inner {
  height: 88vh;
  width: 100%;
}

.service-name {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.prato-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap; 
  padding: 10px;
  padding-right:3%;
  width: 33.3%;
  height: 88vh;
  flex-direction: column;  
}

.prato-item {
  width: 100%; 
  box-sizing: border-box; 
  flex-direction: column;  
}

.prato-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; 
}

.prato-info-detailed {
  display: flex;
  align-items: flex-start;
}

.prato-info {
  align-items: center;
}

.gluten-info {
  background-color: #E0FFFF;
  border-radius: 10px;
  padding: 1px 3px; 
  margin-right:4px;
}

.lactose-info {
  background-color: #D5A9DB;
  border-radius: 10px;
  padding: 1px 3px; 
}

.description-info {
  padding: 1px 3px;
  word-break: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box; 
  display: block;
}

.prato-type {
  margin-left: 20px;
  font-size: 2.1rem; 
  color: #2C3E50;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

.prato-name {
  font-size: 1.8rem;
  margin: .2rem;
  color: #333333;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 1230px) {
  .unit-name {
    font-size: 3.3rem;
  }
}
@media screen and (max-width: 1450px) {
  .prato-type {
    font-size: 1.5rem; 
  }
  .prato-name {
    font-size: 1.4rem; 
  }
}
@media screen and (max-width: 768px) {
  .unit-name {
    font-size: 2.3rem !important;
  }
  .prato-type {
    font-size: 1.8rem !important; 
  }
  .prato-name {
    font-size: 1.6rem !important; 
  }
  .prato-list {
    width:100% !important;
  }
  .header {
    padding: 0 20px !important; 
  }

}
@media screen and (max-width: 1080px) {
  .prato-list {
    width:50%;
    padding-right:3%;
  }
  .prato-type {
    font-size: 2.1rem; 
  }
  .prato-name {
    font-size: 1.8rem; 
  }
  .full-carousel, .VueCarousel full-carousel, .VueCarousel-wrapper, .full-slide, .VueCarousel-inner, .carousel-wrapper, .prato-list {
    height: 84vh;
  }

}
@media screen and (max-width: 1920px) {
  .full-carousel, .VueCarousel full-carousel, .VueCarousel-wrapper, .full-slide, .VueCarousel-inner, .carousel-wrapper, .prato-list {
    height: 87.7vh;
  }

}

@media screen and (min-width: 1366px) {
  .header {
    padding:10px 20px;
  }
  .full-carousel, .VueCarousel full-carousel, .VueCarousel-wrapper, .full-slide, .VueCarousel-inner, .carousel-wrapper, .prato-list {
    height: 85.4vh;
  }
}

@media (orientation: portrait) {
  .unit-name {
    font-size: 3.6rem;
  }
  .header {
    padding:20px; 
  }
  /*.informative-container {*/
  /*  height: auto !important;*/
  /*}*/
}

.prato-details {
}

.prato-details-text {
  margin: .2rem .3rem;
}

.prato-image-wrapper {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.prato-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.informative-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.informative-image {
  max-width: 100%;
  max-height: 100%;
}

.watermark {
  position: fixed;
  bottom: 1.5vh;
  right: 10px;
  background-image: url('../assets/img/icons/teknisa.png');
  background-repeat: no-repeat;
  width: 250px;
  height: 100px;
  opacity: 0.5;
  pointer-events: none;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  opacity: 0.7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  margin-top:20px;
}

.date {
  color: black;
  font-size: 1.8rem;
  font-weight: bold;
  flex-grow: 1;
  margin-left: 1rem;
  opacity: 1 !important;
  font-family: 'Roboto', sans-serif;
}

.time {
  color: black;
  font-size: 2.3rem;
  font-weight: bold;
  text-align: right;
  opacity: 1 !important;
  padding-right: 10px;
  font-family: 'Roboto', sans-serif;
}
</style>