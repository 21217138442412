<template>
  <div class="plates-view-root">
    <div class="fixed-elements">
      <myquest-header />
      <div class="plates-view-header">
        <div class="srv-name">
          <b>{{ serviceData.NMSERVICO }}</b>
        </div>
        <div class="field">
          <input
            id="date-filter-input"
            :value="filteredDate"
            :max="maxFilterableDate"
            :min="minFilterableDate"
            type="date"
            @keydown.prevent
            @change="onDateChanged"
          />
        </div>
        <div class="button-container">
          <button @click="validateSelectedPlates" v-if="isReservePlatesButtonVisible">
            <span>{{ translateView('Reservar Pratos') }}</span>
          </button>
          <button
            @click="showServiceReservationForm"
            v-else-if="isServiceReservable"
          >
            <span>{{ translateView('Reservar Serviço') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="plates-container">
      <h3 v-if="!isObjectEmpty(requiredPlates) && canReservePlates">
        {{ translateView('Escolha seus Pratos') }}:
      </h3>
      <div class="single-choice-container">
        <div
          class="plate-type-container"
          v-for="(plateTypeGroup, plateTypeKey) in requiredPlates"
          :key="plateTypeKey"
        >
          <div class="plate-type-title">
            <span>{{ plateTypeGroup[0].NMTIPOPRAT }}</span>
          </div>
          <div class="t">
            <div
              class="plate-display-container"
              v-for="(plate, i) in plateTypeGroup"
              :key="i"
            >
              <div
                class="selectable-plate-card"
                @click="onRequiredPlateSelected(plate)"
                :class="{ selected: isSingleChoicePlateSelected(plate) }"
              >
                <img
                  :src="getPlateImageUrl(plate.NRFOTOPRATOBLOB,plate.URL_FOTOPRATO)"
                  :alt="plate.NMPRATO"
                  @error="onImageLoadFailed"
                />
                <div class="plate-info">
                  <div class="plate-name">{{ plate.NMPRATO }}</div>
                  <div class="plate-data">
                    <div class="allergenics-container">
                      <div
                        v-if="plate.IDGLUTEN === 'S' || plate.IDLACTOSE === 'S'"
                        class="allergenics-container"
                      >
                        <span v-show="plate.IDGLUTEN === 'S'">{{ translateView('Gluten') }}</span>
                        <span v-show="plate.IDLACTOSE === 'S'">{{ translateView('Lactose') }}</span>
                      </div>
                    </div>
                    <div 
                      v-if="plate.QTMEDICASE > 0"
                      style="text-align:center">
                      {{ translateView('Kcal') }}: {{ plate.CALORIAS_KCAL_ROUND + ' - ' +  plate.QTMEDICASE + ' ' + plate.NMMEDICASE}}
                    </div>
                    <div 
                      style="text-align:center" v-else>
                      {{ translateView('Kcal') }}: {{ plate.CALORIAS_KCAL_ROUND }}
                    </div>     
                  </div>
                </div>
              </div>
              <button
                class="show-details-button"
                @click="showPlateDetails(plate)"
              >
                {{goToPlatesDetailLabel}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <h3 v-if="!isObjectEmpty(optionalPlates)">{{ translateView('Escolha os acompanhamentos opcionais') }}:</h3>
      <div class="nultiple-choice-container">
        <div
          class="plate-type-container"
          v-for="(plateTypeGroup, plateTypeKey) in optionalPlates"
          :key="plateTypeKey"
        >
          <div class="plate-type-title">
            <span>{{ plateTypeGroup[0].NMTIPOPRAT }}</span>
          </div>
          <div
            class="plate-display-container"
            v-for="(plate, i) in plateTypeGroup"
            :key="i"
          >
            <div
              class="selectable-plate-card"
              @click="onOptionalPlateSelected(plate)"
              :class="{ selected: !removedOptionalPlates[plate.CDPRATO] }"
            >
              <img
                :src="getPlateImageUrl(plate.NRFOTOPRATOBLOB,plate.URL_FOTOPRATO)"
                :alt="plate.NMPRATO"
                @error="onImageLoadFailed"
              />
              <div class="plate-info">
                <div class="plate-name">{{ plate.NMPRATO }}</div>
                <div class="plate-data">
                  <div
                    v-if="plate.IDGLUTEN === 'S' || plate.IDLACTOSE === 'S'"
                    class="allergenics-container"
                  >
                    <span v-show="plate.IDGLUTEN === 'S'">{{ translateView('Gluten') }}</span>
                    <span v-show="plate.IDLACTOSE === 'S'">{{ translateView('Lactose') }}</span>
                  </div>
                  <div 
                    v-if="plate.QTMEDICASE > 0"
                    style="text-align:center">
                    {{ translateView('Kcal') }}: {{ plate.CALORIAS_KCAL_ROUND + ' - ' +  plate.QTMEDICASE + ' ' + plate.NMMEDICASE}}
                  </div>
                  <div 
                    style="text-align:center" v-else>
                    {{ translateView('Kcal') }}: {{ plate.CALORIAS_KCAL_ROUND }}
                  </div>                  
                  </div>
              </div>
            </div>
            <button
              class="show-details-button"
              @click="showPlateDetails(plate)"
            >
              {{goToPlatesDetailLabel}}
            </button>
          </div>
        </div>
      </div>
      <h3 v-if="!isObjectEmpty(constantPlates) && requiredPlates.length >  0 && canReservePlates">
        {{ translateView('Serviço acompanhado de') }}:
      </h3>
      <div class="constant-plate-container">
        <div
          class="plate-type-container"
          v-for="(plateTypeGroup, plateTypeKey) in constantPlates"
          :key="plateTypeKey"
        >
          <div class="plate-type-title">
            <span>{{ plateTypeGroup[0].NMTIPOPRAT }}</span>
          </div>
          <div
            class="plate-display-container"
            v-for="(plate, i) in plateTypeGroup"
            :key="i"
          >
            <div class="selectable-plate-card">
              <img
                :src="getPlateImageUrl(plate.NRFOTOPRATOBLOB,plate.URL_FOTOPRATO)"
                :alt="plate.NMPRATO"
                @error="onImageLoadFailed"
              />
              <div class="plate-info">
                <div class="plate-name">{{ plate.NMPRATO }}</div>
                <div class="plate-data">
                  <div
                    v-if="plate.IDGLUTEN === 'S' || plate.IDLACTOSE === 'S'"
                    class="allergenics-container"
                  >
                    <span v-show="plate.IDGLUTEN === 'S'">{{ translateView('Gluten') }}</span>
                    <span v-show="plate.IDLACTOSE === 'S'">{{ translateView('Lactose') }}</span>
                  </div>
                  <div 
                    v-if="plate.QTMEDICASE > 0"
                    style="text-align:center">
                    {{ translateView('Kcal') }}: {{ plate.CALORIAS_KCAL_ROUND + ' - ' +  plate.QTMEDICASE + ' ' + plate.NMMEDICASE}}
                  </div>
                  <div 
                    style="text-align:center" v-else>
                    {{ translateView('Kcal') }}: {{ plate.CALORIAS_KCAL_ROUND }}
                  </div>                  
                </div>
              </div>
            </div>
            <button
              class="show-details-button"
              @click="showPlateDetails(plate)"
            >
              {{ goToPlatesDetailLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="plate-reservation-form"
      :adaptive="true"
      class="modal-details"
      width="100%"
      height="100%"
    >
      <reservation-form
        @confirmed="reservePlates"
        @canceled="closePlateReservationForm"
        :minTime="serviceData.HRINICIO"
        :maxTime="serviceData.HRFINAL"
        :securityLimitDay="serviceData.NRLIMSEGPEDIDO"
        :securityLimitHour="serviceData.HRLIMSEGPEDIDO"
        :startTimePrep="serviceData.HRINICIOPREPARO"
        :initialDate="filteredDate"
      />
    </modal>
    <modal
      name="service-reservation-form"
      :adaptive="true"
      class="modal-details"
      width="100%"
      height="100%"
    >
      <reservation-form
        @confirmed="reserveService"
        @canceled="closeServiceReservationForm"
        :minTime="serviceData.HRINICIO"
        :maxTime="serviceData.HRFINAL"
        :securityLimitDay="serviceData.NRLIMSEGPEDIDO"
        :securityLimitHour="serviceData.HRLIMSEGPEDIDO"
        :startTimePrep="serviceData.HRINICIOPREPARO"
        :initialDate="filteredDate"
      />
    </modal>
    <modal
      name="reservation-success-message"
      :height="'100%'"
      :width="'100%'"
      :transition="'none'"
    >
      <div style="font-size: 40px; padding-top: 25%;">
        {{ translateView('Reserva confirmada com sucesso') }}.
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment"
import util from "@/libs/util"

import PlatesProvider from "@/providers/PlatesProvider"

import ReservationService from "@/service/ReservationService"

import ReservationHelper from "@/helper/ReservationHelper"

import placeHolderImage from "@/assets/placeholder-image.svg"
import Header from "@/components/shared/Header"
import ReservationForm from "@/components/shared/reservationForm"
import PlateDetailPopUp from "./plateDetailPopUp"
import requestModule from '@/libs/requestModule'
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: "plates",
  deactivated() {
    this.resetSelectedPlates()
  },
  components: {
    ReservationForm,
    "myquest-header": Header
  },
  computed: {
    ...mapState({
      serviceData: ({ reservation }) => reservation.serviceData,
      serviceID: ({ reservation }) => reservation.serviceData?.CDSERVFILI,
      filteredDate: ({ reservation }) => reservation.selectedDate,
      userNRORG: ({ userData }) => userData.NRORG,
      selectedBranchCode: ({ config }) => config.branchData.CDFILIAL,
      selectedSectionCode: ({ config }) =>
        config.sectionData?.NRLOCALPESQ ?? null
    }),
    ...mapGetters(["canReservePlates", "isServiceReservable"]),
    requiredPlates() {
      if(this.canReservePlates){
        const singleChoicePlatesList = this.plates.filter(
          p => p.SESSAO_LAYOUT === "1"
        )
        const platesGroupedByKey = this.getPlatesGroupedByType(
          singleChoicePlatesList
        )
        const groupedPlatesArray = Object.values(platesGroupedByKey)
        const sortedRquiredPlates = groupedPlatesArray.sort((a, b) => {
          const firstValue = parseInt(a[0].NRORDPRATCAR)
          const secondValue = parseInt(b[0].NRSEQPRATCAR)
          return firstValue - secondValue
        })
        return sortedRquiredPlates
      } else {
        return []
      }
    },
    optionalPlates() {
      const optionalPlatesList = this.plates.filter(
        p => p.SESSAO_LAYOUT === "2"
      )
      const groupedOptionalPlates = this.getPlatesGroupedByType(
        optionalPlatesList
      )
      return groupedOptionalPlates
    },
    constantPlates() {
      const constantPlatesList = this.plates.filter(
        p => p.SESSAO_LAYOUT === "3"
      )

      const plateListToExtractGroups = this.canReservePlates ? constantPlatesList : this.plates
      const platesGroupedByKey = this.getPlatesGroupedByType(plateListToExtractGroups)
      const groupedPlatesArray = Object.values(platesGroupedByKey)
      const sortedConstantPlates = groupedPlatesArray.sort((a, b) => {
        const firstValue = parseInt(a[0].NRORDPRATCAR)
        const secondValue = parseInt(b[0].NRORDPRATCAR)
        return firstValue - secondValue
      })
      return sortedConstantPlates
    },
    maxFilterableDate(){
      const maxDate = moment("00:00:00", 'HH:mm:ss')
      maxDate.add(this.serviceData.NRDIASPOS, 'days')
      return maxDate.format('YYYY-MM-DD')
    },
    minFilterableDate(){
      const minDate = moment("00:00:00", 'HH:mm:ss')
      minDate.subtract(this.serviceData.NRDIASANT, 'days')
      return minDate.format('YYYY-MM-DD')
    },
    isReservePlatesButtonVisible(){
      return this.canReservePlates // && this.requiredPlates.length > 0
    },
    goToPlatesDetailLabel() {
      return this.origin !== 'rate' ? this.translateView("Ver Detalhes") : this.translateView("Avaliar Pratos")
    },
  },
  activated() {
    this.loadPlateData(),
    this.origin = this.$route?.params?.origin ?? this.origin
  },
  data() {
    return {
      plates: [],
      selectedRequiredPlates: {},
      removedOptionalPlates: {},
      placeHolderImage,
      origin: null,
      inReservation: false
    }
  },
  methods: {
    ...mapMutations(["selectDate"]),
    async loadPlateData() {
      const plates = await PlatesProvider.getPlates({
        NRORG: this.userNRORG,
        CDFILIAL: this.selectedBranchCode,
        CDSERVFILI: this.serviceID,
        DTCARDAPIO: moment(this.filteredDate).format("DD/MM/YYYY")
      })
      this.plates = plates
    },
    resetSelectedPlates() {
      this.selectedRequiredPlates = {}
      this.removedOptionalPlates = {}
    },
    getPlatesGroupedByType(plates) {
      const groupedPlates = plates.reduce((agg, plate) => {
        const currentPlatesInPlateType = agg[plate.CDTIPOPRAT] ?? []
        agg[plate.CDTIPOPRAT] = [...currentPlatesInPlateType, { ...plate }]
        return agg
      }, {})
      return groupedPlates
    },
    onDateChanged({ target }) {
      const newDate = target.value
      this.selectDate(newDate)
      this.resetSelectedPlates()
      this.loadPlateData()
    },
    isSingleChoicePlateSelected(plate) {
      const plateType = plate.CDTIPOPRAT
      const selectedPlateInType = this.selectedRequiredPlates[plateType]
      return selectedPlateInType?.NRSEQPRATCAR === plate.NRSEQPRATCAR
    },
    onRequiredPlateSelected(plate) {
      if (this.canReservePlates) {
        const plateType = plate.CDTIPOPRAT
        this.selectedRequiredPlates = {
          ...this.selectedRequiredPlates,
          [plateType]: plate
        }
      }
      
      console.log()
    },
    onOptionalPlateSelected(plate) {
      if (this.canReservePlates) {
        const plateKey = plate.CDPRATO
        const previousState = this.removedOptionalPlates[plateKey]
        this.removedOptionalPlates = {
          ...this.removedOptionalPlates,
          [plateKey]: !previousState
        }
      }
    },
    showDialog(title) {
      this.$modal.show("dialog", {
        title,
        buttons: [
          {
            title: "Fechar",
            handler: () => {
              this.$modal.hide("dialog")
            }
          }
        ]
      })
    },
    showSuccessReservationMessage() {
      this.$modal.show("reservation-success-message")
      setTimeout(() => this.$modal.hide("reservation-success-message"), 3000)
      setTimeout(() => this.$router.push("/menuSearch"), 3000)
    },
    isObjectEmpty(subject = {}) {
      return Object.keys(subject).length === 0
    },
    showPlateDetails(selectedPlate) {
      this.$modal.show(
        PlateDetailPopUp,
        { plateData: selectedPlate },
        { class:"modal-details", height: "auto", width: "100%" }
      )
    },
    showPlateReservationForm() {
      this.$modal.show("plate-reservation-form")
    },
    closePlateReservationForm() {
      this.$modal.hide("plate-reservation-form")
    },
    showServiceReservationForm() {
      const {
          HRINICIO,
          HRFINAL,
          NRLIMSEGPEDIDO,
          HRLIMSEGPEDIDO,
          HRINICIOPREPARO
      } = this.serviceData;
      const {isValid, endReservationTime} = ReservationHelper.isAllowedReservation(
          this.filteredDate,
          NRLIMSEGPEDIDO,
          HRLIMSEGPEDIDO,
          HRINICIOPREPARO,
          HRINICIO,
          HRFINAL,
          null,
          false
      );
      if ( isValid ) {
        this.$modal.show("service-reservation-form")
      } else {
        this.showDialog(`Serviço não está disponível para reserva deste cardápio. <br><br>Período de reserva encerrou em: <br>${endReservationTime.format('DD/MM/YYYY HH:mm')}`);
      }
    },
    closeServiceReservationForm() {
      this.$modal.hide("service-reservation-form")
    },
    getUnselectedSinglePlateTypes() {
      const selectedPlates = this.selectedRequiredPlates
      const unSelectedPlateGroups = this.requiredPlates.filter(plateGroup => {
        const firstPlate = plateGroup[0] // all plates in the group have the same plateTypeCode
        const plateTypeCode = firstPlate.CDTIPOPRAT
        return !(plateTypeCode in selectedPlates)
      })
      return unSelectedPlateGroups.map(group => group[0])
    },
    validateSelectedPlates() {
      const unselectedPlates = this.getUnselectedSinglePlateTypes() ?? []
      if (unselectedPlates.length === 0) {
        this.showPlateReservationForm()
      } else {
        const errorMessage = this.translateView('Selecione pelo menos um prato do(s) tipo(s)') + ': ' + `
          ${unselectedPlates.map(plate => plate.NMTIPOPRAT)}
          `
        this.showDialog(errorMessage)
      }
    },
    async reservePlates({
      name,
      department,
      cpf,
      email,
      reservationDate,
      reservationTime,
      nrUsuarioApp
    }) {
      if (this.inReservation == true){
        return
      }
      this.inReservation = true
      const selectedOptionalPlates = this.plates.filter(
        p => p.SESSAO_LAYOUT === "2" && !this.removedOptionalPlates[p.CDPRATO]
      )
      /*const requiredPlates = Object.values(this.requiredPlates).map(plate => {
        return { ...plate[0] }
      })*/
      const requiredPlates = Object.values(this.selectedRequiredPlates);
      try {
        const serviceData = {
          ...this.serviceData,
          NRORG: this.userNRORG,
          CDFILIAL: this.selectedBranchCode,
          NRLOCALPESQ: this.selectedSectionCode
        }
        const reservationData = {
          name,
          cpf,
          email,
          department,
          reservationDate,
          reservationTime,
          nrUsuarioApp
        }
        const plates = [...selectedOptionalPlates, ...requiredPlates];
        await ReservationService.reservePlates(
          serviceData,
          reservationData,
          plates
        ).then((req) => {
          if (reservationData.email !== '') {
            const { reservationDate, reservationTime } = reservationData;
            const selectedDate = moment(`${reservationDate} ${reservationTime}`);
            const sendObject = {
              DSEMAILUSUARIOAPP: reservationData.email,
              NMUSUARIO: reservationData.name,
              plates: plates,
              DTCARDAPIO: selectedDate.format("DD/MM/YYYY"),
              HRCONSUMO: selectedDate.format("HHmm"),
              digitalReservation: localStorage.getItem('digitalReservation'),
              NRAVALIACAOPRATO: req.dataset.data.NRAVALIACAOPRATO,
              NRORG: req.dataset.data.NRORG,
              lang: util.getLocalVar('lang'),
              logo: util.getLocalVar('logoUrl'),
              type: req.dataset.data.type,
              CDFILIAL: serviceData.CDFILIAL
            };
            requestModule.postRequest('sendReservPlatesMail', sendObject);
          }
        });
        this.inReservation = false
        this.closePlateReservationForm()
        this.showSuccessReservationMessage()
      } catch (reservationPeriodError) {
        this.showDialog(reservationPeriodError)
        this.inReservation = false
      }
    },
    getPlateImageUrl(id,foto_mobile) {
      if (!util.isEmpty(id)) {
        const urlPrefix =
          process.env.NODE_ENV === "development"
            ? process.env.VUE_APP_BASE_URI_PREFIX + "/"
            : ""
        const url = `${urlPrefix}${process.env.VUE_APP_BASE_URI}/plateImage?id=${id}&nrorg=${this.userNRORG}`
        let url_mobile = `${util.getLocalVar('fileServiceFilesPath').url+foto_mobile}`;
        
        let url_foto;
        
        if(foto_mobile){
          
          url_foto = url_mobile;
          
        }else{
          url_foto = url;
        }
       
        return url_foto
      } else {
        return this.placeHolderImage
      }
    },
    onImageLoadFailed(event) {
      const imageElement = event.target
      imageElement.src = this.placeHolderImage
    },
    async reserveService({
      reservationDate,
      reservationTime,
      name,
      cpf,
      department,
      email,
      nrUsuarioApp
    }) {
      if (this.inReservation == true){
        return
      }
      this.inReservation = true
      const serviceData = {
        ...this.serviceData,
        NRORG: this.userNRORG,
        CDFILIAL: this.selectedBranchCode,
        NRLOCALPESQ: this.selectedSectionCode
      }
      const reservationData = {
        reservationDate,
        reservationTime,
        name,
        cpf,
        department,
        email,
        nrUsuarioApp
      }

      try {
        await ReservationService.reserveService(serviceData, reservationData).then((req) => {
          if (reservationData.email !== '') {
            const { reservationDate, reservationTime } = reservationData;
            const selectedDate = moment(`${reservationDate} ${reservationTime}`);
            const sendObject = {
              DSEMAILUSUARIOAPP: reservationData.email,
              NMUSUARIO: reservationData.name,
              NMSERVICO: serviceData.NMSERVICO,
              DTCARDAPIO: selectedDate.format("DD/MM/YYYY"),
              HRCONSUMO: selectedDate.format("HHmm"),
              digitalReservation: localStorage.getItem('digitalReservation'),
              NRAVALIACAOSERVICO: req.dataset.data.NRAVALIACAOSERVICO,
              NRORG: req.dataset.data.NRORG,
              lang: util.getLocalVar('lang'),
              logo: util.getLocalVar('logoUrl'),
              type: req.dataset.data.type,
              CDFILIAL: serviceData.CDFILIAL
            };
            requestModule.postRequest('sendReservServiceMail', sendObject);
          }
        });
        this.inReservation = false
        this.closeServiceReservationForm()
        this.showSuccessReservationMessage()
      } catch (reservationPeriodError) {
        this.showDialog(reservationPeriodError)
        this.inReservation = false
      }
    },
    translateView: function(word) {
      return util.translate(word);
    }
  }
}
</script>

<style scoped lang="less">
.t,
.plate-type-container {
  margin-bottom: 15px;
}

.default-button {
  height: 35px;
  background: var(--primary-color);
  color: var(--primary-color);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0 10px;
}

.max-lines(@lines: 3; @line-height: 1.2) {
  @max-height: @line-height * @lines;
  overflow: hidden;
  text-overflow: ellipsis;
  //display: -webkit-box;
  display: contents;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @lines;
  line-height: ~"@{line-height}em";
  max-height: ~"@{max-height}em";
}

.plates-view-root {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);

  .plates-view-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    box-shadow: 1px 1px 3px gray;
    z-index: 1;
    position: relative;
    .srv-name b {
      position: relative;
      display: block;
      max-width: 180px;
      font-size: 1.4rem;
      /*overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;*/
    }

    .field {
      label {
        display: block;
      }
      input {
        min-height: 30px;
        width: 175px;
        align-self: center;
        height: 40px;
        font-size: 1.4rem;
        border-radius: 5px;
        border: 2px solid #970c00;
      }
    }

    button {
      .default-button;
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
        height: 50px;
        font-size: 1.4rem;
      }
    }
  }

  .plates-container {
    overflow-y: scroll;
    padding: 0;
  }

  .plate-display-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 345px;
    width: 255px;
    position: relative;
    user-select: none;
    margin: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: white;
    border-radius: 5px;
    //padding: 5px;

    .show-details-button {
      .default-button;
      display: block;
      height: 40px;
      font-size: 1.45rem;
      //flex-basis: 30px;
      flex-basis: 45px;
      flex-shrink: 0;
      // text-decoration: underline;
      border: none;
      border-radius: 5px;
      color: var(--text-color);
      background: var(--primary-color);
      margin: 10px;
      //background: transparent;
      //color: #171717;
    }

    .selectable-plate-card {
      cursor: pointer;
      position: relative;
      flex-grow: 1;

      img {
        width: 100%;
        object-fit: cover;
        height: 165px;
      }

      .plate-info {
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        height: 105px;
        font-size: 1.05rem;
        //width: 100%;
        padding: 5px;
        padding-top: 0;

        .plate-name {
          -webkit-line-clamp: 2;
          display: -webkit-box;
          //overflow: hidden;
          //text-overflow: ellipsis;
          //-webkit-box-orient: vertical;
        }
      }

      .allergenics-container {
        margin: 5px 0;
        span {
          background-color: #cfd8dc;
          padding: 2px;
          border-radius: 4px;
          margin-right: 5px;
        }
      }

      &.selected::after {
          content: "\f00c";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          width: 30px;
          color: green;
          padding: 5px 0;
          /* height: 26px; */
          top: 10px;
          right: 10px;
          position: absolute;
          background-color: transparent;
          border: 10px solo;
          border-radius: 50%;
          border: 5px solid green;
      }
    }
  }

  .constant-plate-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5px;
  }

  h3 {
    position: relative;
    display: block;
    text-transform: uppercase;
    background: var(--primary-color);
    padding: 25px 0;
    color: var(--text-color);
    &:first-of-type {
      margin-top: 0;
    }
  }
  .plate-type-title {
    position: relative;
    text-align: center;
    font-size: 0.8em;
    background: rgba(211, 211, 211, 0.48);
    margin: 8px 5px;
    padding: 8px;
    border-radius: 5px;
    font-size: 1.4rem;
    span {
      color: #2c3e50;
    }
  }
  .button-container {
    span {
      color: var(--text-color);
    }
  }
}
</style>